import React, { useContext, useEffect, useState } from 'react';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import moment from 'moment';
import { Button } from '../../components/forms/Buttons/Button';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ArrowsRightLeftIcon, PlusIcon } from '@heroicons/react/24/solid';
import Table from '../../components/Table/Table';
import { UserContext } from '../../utils/contexts/UserContext';
import { getTranfers } from '../../services/transferStudents/transferBetweenSchoolsService';
import CreateTransferModal from './CreateTransferModal';
import AcceptStudentModal from './AcceptStudentModal';
import { getSchools } from '../../services/catalog/catalog';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const PAGE_SIZE = 50;

export default function Transfer() {
  const { examYear } = useContext(ExamYearContext);
  const { user } = useContext(UserContext);

  const [studentList, setStudentList] = useState([]);
  const [studentToAccept, setStudentToAccept] = useState(null);
  const [transferToDelete, setTransferToDelete] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [iinToSearch, setIinToSearch] = useState('');
  const [schools, setSchools] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        id: 'index',
        accessor: (_row, i) => i + 1,
        // Cell: (props) => {
        //   return <span>{props.state.pageIndex}</span>;
        // },
      },
      {
        Header: 'ИИН',
        accessor: 'application.iin',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'Имя',
        accessor: 'application.first_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: 'Фамилия',
        accessor: 'application.last_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      // {
      //   Header: 'Дата регистрации',
      //   accessor: 'created',
      //   // Filter: SelectColumnFilter,
      //   Cell: (props) => {
      //     const custom_date = moment(props.value).format('DD.MM.yyyy');
      //     return <span>{custom_date}</span>;
      //   },
      // },
      // {
      //   Header: 'Экзаменационный центр',
      //   accessor: 'exam_center.name',
      //   // Filter: SelectColumnFilter,
      // },

      {
        Header: 'Тел номер',
        accessor: 'application.parent_phone_number',
      },
      {
        Header: 'ӘОТ',
        accessor: 'asp_status',
        Cell: (props) => (
          <>
            {props.row.original.application.asp_status === '0' && (
              <span className=''>Нет</span>
            )}
            {props.row.original.application.asp_status === '1' && (
              <span className=''>Да, не одобрено</span>
            )}
            {props.row.original.application.asp_status === '2' && (
              <span className=''>Да, одобрено</span>
            )}
          </>
        ),
      },
      {
        Header: 'Откуда',
        accessor: 'from_school.name',
        Cell: (props) => (
          <>
            <span className='rounded-md bg-red-200 p-2 font-semibold'>
              {props.value}
            </span>
          </>
        ),
      },
      {
        Header: 'Куда',
        accessor: 'to_school.name',
        Cell: (props) => (
          <>
            <span className='rounded-md bg-green-200 p-2 font-semibold'>
              {props.value}
            </span>
          </>
        ),
      },
      {
        Header: 'Статус трансфера',
        accessor: 'is_approved',
        Cell: (props) => (
          <>
            <div>
              {props.value ? (
                <span className='font-semibold text-green-600'>Завершен</span>
              ) : (
                <span className='font-semibold text-gray-600'>Не завершен</span>
              )}
            </div>
          </>
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        fixed: 'right',
        Cell: (props) => (
          <div className='flex gap-2'>
            {(user?.school?.uuid === props.row.original?.to_school?.uuid ||
              user?.roles?.some((r) => r?.code === 'SSA')) && (
              <Button
                className='max-h-8'
                disabled={
                  moment(props.row.original.created).year() !==
                    moment().year() || props.row.original?.is_approved === true
                }
                Icon={ArrowsRightLeftIcon}
                color='primary-solid'
                onClick={() => {
                  setAcceptModalOpen(true);
                  setStudentToAccept(props.row.original);
                }}
              />
            )}
            {user?.roles?.some((r) => r?.code === 'SSA') && (
              <Button
                className='max-h-8'
                color='error'
                disabled={props.row.original?.is_approved === true}
                Icon={TrashIcon}
                onClick={() => {
                  setDeleteModalOpen(true);
                  setTransferToDelete(props.row.original);
                }}
              />
            )}
          </div>
        ),
      },
    ],
    [user],
  );

  useEffect(() => {
    if (examYear?.uuid && user?.uuid) {
      setLoading(true);

      getTranfers({
        to_school: user?.school?.uuid ? user?.school?.uuid : null,
        exam_year: examYear?.uuid,
        page: 1,
        page_size: 50,
      })
        .then((res) => {
          setStudentList(res.results);
          setCount(res.count);
          setPageCount(Math.ceil(res.count / 50));
        })
        .finally(() => {
          setLoading(false);
        });

      getSchools({ page_size: 50 }).then((res) => {
        const options = res.results.map((school) => ({
          label: school.name,
          value: school.uuid,
        }));

        setSchools(options);
      });
    }
    return () => {};
  }, [examYear, user]);

  const nextPage = () => {
    setLoading(true);

    getTranfers({
      exam_year: examYear?.uuid,
      page: page + 1,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setStudentList(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / PAGE_SIZE));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v + 1);
  };

  const previousPage = () => {
    setLoading(true);

    getTranfers({
      exam_year: examYear?.uuid,
      page: page - 1,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setStudentList(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / PAGE_SIZE));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  const gotoPage = (pageNum) => {
    setLoading(true);

    getTranfers({
      exam_year: examYear?.uuid,
      page: pageNum,
      page_size: PAGE_SIZE,
    })
      .then((res) => {
        setStudentList(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / PAGE_SIZE));
        setPage(pageNum);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateList = () => {
    getTranfers({
      to_school: user?.school?.uuid ? user?.school?.uuid : null,
      exam_year: examYear?.uuid,
      page: 1,
      page_size: 50,
    })
      .then((res) => {
        setStudentList(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / 50));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className='flex flex-row'>
        {user?.roles?.some((r) => r?.code === 'SSA') && (
          <Button
            Icon={PlusIcon}
            color='primary-solid'
            text='Создать трансфер'
            onClick={() => {
              setCreateModalOpen(true);
            }}
          />
        )}
      </div>
      <CreateTransferModal
        schools={schools}
        modalOpen={createModalOpen}
        setModalOpen={setCreateModalOpen}
        updateList={updateList}
      />
      <AcceptStudentModal
        modalOpen={acceptModalOpen}
        setModalOpen={setAcceptModalOpen}
        transfer={studentToAccept}
        // setStudentToAccept={setStudentToAccept}
        updateList={updateList}
        examYear={examYear}
      />
      <DeleteConfirmationModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        updateList={updateList}
        transfer={transferToDelete}
      />
      <Table
        columns={columns}
        data={studentList}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        loading={loading}
      />
    </div>
  );
}
