import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Button } from '../../../components/forms/Buttons/Button';
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
import 'react-pdf/dist/esm/Page/AnnotationLayer';

export default function DocumentTab({ document }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (document) {
    return (
      <div className=''>
        <div className='px-4 py-4'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Свидетельство о рождении
          </h3>
        </div>
        <div className='w-full border-t text-center'>
          <img src={document} alt='doc' />
          {numPages > 1 && `Page ${pageNumber} of ${numPages}`}
          <div className='flex flex-col'>
            {numPages > 1 && (
              <div>
                <Button
                  disabled={pageNumber === 1}
                  onClick={() => {
                    setPageNumber((v) => (v = v - 1));
                  }}
                  Icon={ArrowLeftCircleIcon}
                ></Button>
                <Button
                  disabled={pageNumber === numPages}
                  onClick={() => {
                    setPageNumber((v) => (v = v + 1));
                  }}
                  Icon={ArrowRightCircleIcon}
                ></Button>
              </div>
            )}
            <Document
              file={document}
              onLoadSuccess={onDocumentLoadSuccess}
              className='flex justify-center'
            >
              <Page
                className='w-fit border-2 border-black-landing'
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                height={window.innerWidth > 768 ? 1000 : 500}
              />
            </Document>
          </div>
        </div>
      </div>
    );
  }
  return <div>no docs</div>;
}
