import bilAPI from '../api';

export const getApplications = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/application/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postApplication = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/core/application/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchApplication = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/core/application/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getApplication = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/core/application/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getApplicantsLite = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/core/applicants_lite/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const acceptApplication = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/core/application/${uuid}/set_account/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteApplication = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/core/application/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
