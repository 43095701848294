import React from 'react';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  FunnelIcon,
} from '@heroicons/react/24/solid';

export default function StatsTable({ columns, data, loading }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        initialState: { pageSize: 50 },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
    );

  if (loading) {
    return (
      <div className='text-center'>
        <SimpleLoader className='mt-8 h-8' />
      </div>
    );
  }

  // headerGroups.push({
  //   headers: [],
  // });

  return (
    <div className='rounded-md'>
      <table
        {...getTableProps()}
        className='min-w-full divide-y divide-gray-200'
      >
        <thead className={`${false && 'sticky top-0 z-10'}  bg-gray-800`}>
          {headerGroups?.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props

                <th
                  scope='col'
                  className='max-w-th p-2 text-sm text-white'
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div
                    className={`flex items-center ${
                      column?.id === 'current_year_goal'
                        ? 'justify-center'
                        : 'justify-between'
                    } gap-2 break-all`}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ChevronDownIcon className='h-4 w-4 text-gray-400' />
                        ) : (
                          <ChevronUpIcon className='h-4 w-4 text-gray-400' />
                        )
                      ) : (
                        <FunnelIcon className='h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100' />
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className='divide-y divide-gray-200 bg-white'
        >
          {loading ? (
            <tr>
              {/* {headerGroups.map(() => ( */}
              <td className='px-2 py-1'>loading...</td>
              {/* ))} */}
            </tr>
          ) : (
            page?.map((row, i) => {
              // new
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  // className={
                  //   row.original?.isIncreased === 2
                  //     ? ''
                  //     : row.original?.isIncreased === 1
                  //     ? 'bg-green-100'
                  //     : 'bg-red-100'
                  // }
                >
                  {row.cells?.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className='border px-2 py-1'>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
