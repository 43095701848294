import React, { useEffect, useState } from 'react';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import { getSocialDocuments } from '../../../services/socialDocs/socialDocs';
import { showImageOrPdf } from '../../../utils/helpers/helpers';
import { getApplication } from '../../../services/application/application';
import { Button } from '../../../components/forms/Buttons/Button';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function DocumentsModal({
  student,
  docsModalOpen,
  setDocsModalOpen,
}) {
  const [t] = useTranslation();

  const [aspDocs, setAspDocs] = useState([]);
  const [birthCertificate, setBirthCertificate] = useState(null);

  useEffect(() => {
    if (student?.uuid) {
      getApplication(student?.uuid).then((res) => {
        setBirthCertificate(res?.document);
      });
      getSocialDocuments({ application: student?.uuid }).then((res) => {
        // console.log('res', res);

        setAspDocs(res.results);
      });
      // console.log('student', student);
    }

    return () => {};
  }, [student]);

  return (
    <CustomDialog
      isOpen={docsModalOpen}
      setIsOpen={setDocsModalOpen}
      closeModal={() => {
        setDocsModalOpen(false);
      }}
      headerText={'Docs'}
    >
      <div className='flex flex-col bg-slate-200 p-2 md:flex-row'>
        <div className='w-1/4 bg-slate-200'>
          <div className='mb-4 font-bold'>Туу туралы куәлік:</div>
          {student?.uuid && showImageOrPdf(birthCertificate, 16)}
          <div>
            <Button
              className='mt-2 h-8'
              Icon={ArrowDownTrayIcon}
              onClick={() => {
                window.open(birthCertificate, '_blank').focus();
              }}
            />
          </div>
        </div>
        <div className='my-4 border border-gray-800 md:mx-4'></div>
        <div className='w-3/4'>
          <div className='mb-4 font-bold'>ӘОТ {t('welcome.docs')}:</div>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
            {aspDocs?.map((doc) => (
              <div key={doc?.uuid}>
                <div>{showImageOrPdf(doc?.document, 8)}</div>
                <div>
                  <Button
                    className='mt-2 h-8'
                    Icon={ArrowDownTrayIcon}
                    onClick={() => {
                      window.open(doc?.document, '_blank').focus();
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </CustomDialog>
  );
}
