import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { KeyIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import { Controller, useForm } from 'react-hook-form';
import {
  getExamAccessibilityByCourseId,
  patchExamAccessibility,
  postExamAccessibility,
} from '../../../services/exams/accessibilityService';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import Select from '../../../components/forms/Select/Select';
import Input from '../../../components/forms/Input/Input';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import ToggleButton from '../../../components/forms/Buttons/ToggleButton';

const ACCESSIBILITY_OPTIONS = [
  { label: 'Закрытый (Режим редактирования, супер администратор)', value: 0 },
  { label: 'Открыт для всех учеников', value: 1 },
  {
    label:
      'Открыт для специальных учеников (Требуется настройка доступа по ИИН )',
    value: 2,
  },
];

const EditAccess = ({ exam, getData }) => {
  const { addSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accessibility, setAccessibility] = useState();
  const inputRef = useRef();
  const regex = new RegExp(/^\d+$/g);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      iins: [],
      accessibility: 0,
      is_open_for_specific: false,
    },
  });
  useEffect(() => {
    if (!open && accessibility?.uuid) {
      setValue('accessibility', accessibility?.accessibility);
      if (accessibility?.accessibility === 2) {
        setValue('iins', accessibility?.iins);
        setValue('is_open_for_specific', accessibility?.is_open_for_specific);
      }
    }
  }, [open, accessibility, setValue]);

  useEffect(() => {
    if (!!exam) {
      setIsLoading(true);
      getExamAccessibilityByCourseId(exam?.uuid)
        .then((res) => {
          setAccessibility(res[0]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [exam]);

  const onSubmit = (data) => {
    const body = {};
    if (accessibility) {
      if (data.accessibility !== accessibility.accessibility)
        body.accessibility = data.accessibility;
      if (data.accessibility === 2) {
        body.iins = data.iins;
        body.is_open_for_specific = data.is_open_for_specific;
      }

      if (Object.keys(body)?.length) {
        patchExamAccessibility(accessibility.uuid, body);
      }
    } else {
      body.exam = exam.uuid;
      body.accessibility = data.accessibility;
      body.iins = data.iins;
      body.is_open_for_specific = data.is_open_for_specific;
      postExamAccessibility(body);
    }
    console.log(body);
    getData();
    setOpen(false);
  };
  return (
    <>
      <Button text={'Доступ'} Icon={KeyIcon} onClick={() => setOpen(true)} />
      <CustomDialog
        isOpen={open}
        headerText='Доступ к экзамену'
        closeModal={() => {
          setOpen(false);
        }}
      >
        {!isLoading ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex min-h-[12rem] flex-col justify-between'
          >
            <div className='flex flex-col'>
              <label>
                <span>Доступ</span>
                <span className='text-error'>*</span>
              </label>
              <Controller
                control={control}
                name='accessibility'
                rules={{ required: 'Поле "Доступ" обязательное' }}
                render={({ field }) => (
                  <Select
                    errorMsg={errors?.accessibility?.message || ''}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={ACCESSIBILITY_OPTIONS}
                  />
                )}
              />
              {watch('accessibility') === 2 && (
                <>
                  <label>ИИН</label>
                  <Controller
                    control={control}
                    name='iins'
                    render={({ field }) => (
                      <>
                        <div className='flex min-h-[8rem] w-4/5 flex-row flex-wrap items-start gap-1 rounded-lg border p-4'>
                          {field?.value?.map((iin) => {
                            return (
                              <div
                                key={iin}
                                className='group flex max-h-fit max-w-fit flex-row items-center gap-1 rounded-full border bg-slate-100 py-1 px-2 hover:animate-shake hover:bg-slate-200'
                              >
                                <span className='text-sm opacity-100 group-hover:text-error'>
                                  {iin}
                                </span>
                                <XMarkIcon
                                  className='child group-hover hidden h-3 cursor-pointer group-hover:flex group-hover:text-error'
                                  onClick={() => {
                                    field.onChange(
                                      field.value.filter((v) => v !== iin),
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <div className='mt-2 flex flex-row'>
                          <Input
                            ref={inputRef}
                            placeholder={'Введите ИИН'}
                            className='w-80 rounded-l-lg border'
                            inputClassName='rounded-r-none focus:outline-none'
                            minLength='12'
                            maxLength='12'
                          />
                          <Button
                            type='button'
                            className='rounded-l-none'
                            Icon={PlusIcon}
                            onClick={() => {
                              let iin = inputRef.current.value;

                              if (iin?.length !== 12) {
                                addSnackbar(
                                  'ИИН должен быть длинной в 12 символов',
                                  'error',
                                );
                                return;
                              }

                              if (!regex.test(iin)) {
                                addSnackbar(
                                  'ИИН должен состоять из цифр',
                                  'error',
                                );
                                return;
                              }
                              if (field.value?.includes(iin)) {
                                addSnackbar('ИИН уже есть в списке', 'error');
                                return;
                              }
                              field.onChange([...field.value, iin]);
                              inputRef.current.value = '';
                            }}
                          />
                        </div>
                        <div className='mt-2 flex flex-row items-center gap-2'>
                          <label>Открыть Доступ</label>

                          <Controller
                            control={control}
                            name='is_open_for_specific'
                            render={({ field }) => (
                              <ToggleButton
                                value={field.value}
                                onClick={() => field.onChange(!field.value)}
                              />
                            )}
                          />
                        </div>
                      </>
                    )}
                  />
                </>
              )}
            </div>
            <div className='flex flex-row justify-end'>
              <Button text='Изменить' type='submit' color='success' />
            </div>
          </form>
        ) : (
          <SimpleLoader className='h-5' />
        )}
      </CustomDialog>
    </>
  );
};

export default EditAccess;
