import bilAPI from '../api';
import classicBilAPI from '../classicApi';

export const getTranfers = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/student_school_transfer/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postTransferStudent = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/core/student_school_transfer/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchTransferStudent = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/core/student_school_transfer/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteTransfer = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/core/student_school_transfer/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const approveTransferStudent = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      classicBilAPI
        .patch(`/core/student_school_transfer/${uuid}/approve_transfer/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
