import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import faqRight from '../images/faqRight.svg';

const FAQSection = () => {
  const [t] = useTranslation();
  const [activeQuestion, setActiveQuestion] = useState(-1);
  const questions = [
    {
      title: t('welcome.faq_question_1'),
      text: t('welcome.faq_answer_1'),
    },
    {
      title: t('welcome.faq_question_2'),
      text: t('welcome.faq_answer_2'),
    },
    {
      title: t('welcome.faq_question_3'),
      text: t('welcome.faq_answer_3'),
    },
    {
      title: t('welcome.faq_question_4'),
      text: t('welcome.faq_answer_4'),
    },
    {
      title: t('welcome.faq_question_5'),
      text: t('welcome.faq_answer_5'),
    },
    {
      title: t('welcome.faq_question_6'),
      text: t('welcome.faq_answer_6'),
    },
    {
      title: t('welcome.faq_question_7'),
      text: (
        <div className='flex flex-col'>
          <span>{t('welcome.faq_answer_7_0_1')}</span>
          <span className=' flex flex-row gap-1 pt-4'>
            <span>{t('welcome.faq_answer_7_0_2')}</span>
            <span className='italic underline'>
              {t('welcome.faq_answer_7_0_3')}
            </span>

            <span className='pl-2'>{t('welcome.faq_answer_7_0_4')}</span>
          </span>
          <ul>
            <li>{t('welcome.faq_answer_7_1')}</li>
            <li>{t('welcome.faq_answer_7_2')}</li>
            <li>{t('welcome.faq_answer_7_3')}</li>
            <li>{t('welcome.faq_answer_7_4')}</li>
          </ul>
        </div>
      ),
    },
    {
      title: t('welcome.faq_question_8'),
      text: t('welcome.faq_answer_8'),
    },
    {
      title: t('welcome.faq_question_10'),
      text: t('welcome.faq_answer_10'),
    },
    {
      title: t('welcome.faq_question_11'),
      text: (
        <div>
          <span>{t('welcome.faq_answer_11')}</span>
          <ul>
            {[1, 2, 3, 4, 5, 6, 7, 8]?.map((e) => (
              <li key={e}>{` - ${t(`welcome.faq_answer_11_${e}`)}`}</li>
            ))}
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className=' flex w-screen  flex-row bg-black-landing '>
      <div className='flex w-full flex-col items-center justify-start text-white'>
        <span className=' px-5 pt-16 text-center text-4xl sm:px-0 sm:text-6xl md:text-left'>
          {t('welcome.faq_1')}
        </span>
        <div className='flex w-full flex-col items-center justify-start pb-16 text-white'>
          {questions?.map((q, i) => {
            let open = activeQuestion === i;
            return (
              <details
                key={i}
                onToggle={(e) => {
                  if (e?.target?.open) {
                    setActiveQuestion(i);
                  } else if (!e?.target?.open && open) {
                    setActiveQuestion(-1);
                  }
                }}
                open={open}
                className='mt-6 flex w-11/12 list-none items-start border-b border-secondary shadow'
              >
                <summary className='justify-left flex cursor-pointer list-none flex-row text-3xl'>
                  <p
                    className={
                      'font-sfproBOld flex flex-1 flex-row items-center justify-start p-4 text-left ' +
                      (open ? 'text-white' : 'text-secondary')
                    }
                  >
                    {q?.title}
                  </p>
                  <div className='flex w-10 flex-row items-center justify-start'>
                    {open ? (
                      <MinusIcon className='h-7 text-yellow-500' />
                    ) : (
                      <PlusIcon className='h-7 text-yellow-500' />
                    )}
                  </div>
                </summary>
                <div className='px-2 pb-3 text-xl text-secondary'>
                  {q?.text}
                </div>
              </details>
            );
          })}
        </div>
      </div>
      <div className='flex min-w-fit flex-row justify-end'>
        <img className='hidden xl:flex' src={faqRight} alt='icons' />
      </div>
    </div>
  );
};
export default FAQSection;
