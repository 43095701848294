import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { deleteQuestion } from '../../../services/exams/questionService';
import useSnackbar from '../../../utils/hooks/useSnackbar';

const Delete = ({
  questionUUID,
  questionId,
  getData,
  isDeleteMode,
  setIsDeleteMode,
}) => {
  const { addSnackbar } = useSnackbar();
  const deleteQuestionHandler = () => {
    deleteQuestion(questionUUID)?.finally(() => {
      getData();
      addSnackbar('Вопрос был успешно удален', 'success');
    });
  };
  return (
    <>
      {isDeleteMode && (
        <Button
          Icon={CheckIcon}
          className='rounded-r-none '
          color='error-solid'
          onClick={() => {
            deleteQuestionHandler();
            setIsDeleteMode(false);
          }}
        />
      )}
      <Button
        //text='Удалить'
        Icon={isDeleteMode ? XMarkIcon : TrashIcon}
        className={`rounded-l-none `}
        color={isDeleteMode ? 'primary-solid' : 'error'}
        onClick={() => {
          setIsDeleteMode((v) => !v);
        }}
      />
    </>
  );
};

export default Delete;
