import React from 'react';

const ToggleButton = ({ value, onClick, className }) => {
  const toggleClass = 'transform translate-x-5';
  return (
    <div
      className={
        'flex h-6 w-12 cursor-pointer items-center rounded-full p-1 md:h-7 md:w-12 ' +
        (value ? 'bg-blue-600 ' : 'bg-gray-500 ') +
        className
      }
      onClick={onClick}
    >
      <div
        className={
          'h-5 w-5 transform rounded-full bg-white  shadow-md duration-300 ease-in-out md:h-5 md:w-5 ' +
          (value ? toggleClass : null)
        }
      ></div>
    </div>
  );
};

export default ToggleButton;
