import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function DatePicker({
  className,
  placeholderText,
  onChange,
  value,
  selected,
  dateFormat,
  label,
  required,
  errorMsg,
  maxDate,
}) {
  return (
    <div className={className}>
      <label className='block text-sm font-medium text-gray-700'>
        {label}
        <span className={`text-error`}>{required && '*'}</span>
      </label>
      <ReactDatePicker
        className='block w-full rounded-md border-gray-200 bg-white px-3 py-2 text-sm shadow-sm focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600'
        onChange={onChange}
        placeholderText={placeholderText ?? 'Select'}
        dateFormat={dateFormat ?? 'dd/MM/yyyy'}
        showMonthDropdown
        showYearDropdown
        maxDate={maxDate}
        value={value}
        selected={selected}
        dropdownMode='select'
        adjustDateOnChange
      />
      {errorMsg && (
        <span className='mt-1 text-xs font-semibold text-error'>
          {errorMsg}
        </span>
      )}
    </div>
  );
}
