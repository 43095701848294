import React, { useEffect, useState } from 'react';
import Select from '../../components/forms/Select/Select';
import { useNavigate } from 'react-router-dom';
import {
  getResults,
  getResultsStats,
  getSchoolPassScore,
} from '../../services/results/resultsService';
import { Button } from '../../components/forms/Buttons/Button';
import {
  EyeIcon,
  IdentificationIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Input from '../../components/forms/Input/Input';
import TableWithServerOrdering from '../../components/Table/TableWithServerOrdering';

export default function Results() {
  const navigate = useNavigate();
  const [resultsData, setResultsData] = useState([]);
  const [passScoreFirstRound, setPassScoreFirstRound] = useState(null);
  const [passScoreSecondRound, setPassScoreSecondRound] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [pageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const [round, setRound] = useState(null);
  const [isAsp, setIsAsp] = useState(null);
  const [status, setStatus] = useState(null);
  const [iinToSearch, setIinToSearch] = useState('');
  const [ordering, setOrdering] = useState(null);
  const [resultsStats, setResultsStats] = useState({});

  const columns = React.useMemo(
    () => [
      // {
      //   Header: '#',
      //   id: 'index',
      //   accessor: (_row, i) => i + 1,
      // Cell: (props) => {
      //   return <span>{props.state.pageIndex}</span>;
      // },
      // },
      {
        Header: 'Мектеп',
        accessor: 'application.school.name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'ЖСН',
        accessor: 'application.iin',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'Аты',
        accessor: 'application.first_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: 'Жөні',
        accessor: 'application.last_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      {
        Header: 'Вариант',
        accessor: 'exam_variant',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <div className='text-center'>
            <span className=''>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Дұрыс',
        accessor: 'total_correct',
        accessor_for_ordering: 'total_correct',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <div className='bg-green-100 text-center'>
            <span className='font-semibold text-green-800'>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Қате',
        accessor: 'total_wrong',
        accessor_for_ordering: 'total_wrong',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <div className='bg-red-100 text-center'>
            <span className='font-semibold text-red-700'>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Ұпай',
        accessor: 'result',
        accessor_for_ordering: 'result',
        // Filter: SelectColumnFilter,
        Cell: (props) => (
          <div className='bg-blue-100 text-center'>
            <span className='font-semibold'>{props.value}</span>
          </div>
        ),
      },

      {
        Header: 'Статус',
        accessor: 'status',
        Cell: (props) => (
          <div className='text-center'>
            {props.value === 0 && (
              <span className='font-semibold text-red-600'>Өтпеді</span>
            )}
            {props.value === 1 && <span className=''>Резерв</span>}
            {props.value === 2 && (
              <span className='font-semibold text-green-600'>Өтті</span>
            )}
          </div>
        ),
      },

      {
        Header: 'ӘОТ',
        accessor: 'is_asp',
        Cell: (props) => (
          <>
            {props.value ? (
              <span className=''>Да</span>
            ) : (
              <span className=''>Нет</span>
            )}
          </>
        ),
      },
      {
        Header: 'Тел номер',
        accessor: 'application.parent_phone_number',
      },
      {
        Header: 'Пропуск',
        accessor: '',
        Cell: (props) =>
          true || props.row.original.status === 2 ? (
            <div className='text-center'>
              <Button
                Icon={EyeIcon}
                className='max-h-6'
                onClick={() => {
                  navigate(
                    `/results/${props.row.original.application.uuid}/pass`,
                    {
                      state: {
                        socialDocType: props.row.original.social_doc_type,
                      },
                    },
                  );
                }}
              />
            </div>
          ) : (
            <div></div>
          ),
      },
      {
        Header: 'Заявление',
        accessor: '',
        Cell: (props) => (
          <div className='text-center'>
            <Button
              Icon={IdentificationIcon}
              className='max-h-6'
              onClick={() => {
                navigate(
                  `/results/${props.row.original.application.uuid}/application`,
                );
              }}
            />
          </div>
        ),
      },
    ],
    [navigate],
  );

  useEffect(() => {
    setLoading(true);
    getResults({ page: 1, page_size: 50 })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / 50));
      })
      .finally(() => {
        setLoading(false);
      });

    getSchoolPassScore().then((res) => {
      if (res?.count > 0) {
        if (res.results?.find((v) => [0, 1].includes(v.round))) {
          setPassScoreFirstRound(res.results?.find((v) => v.round === 0));
          setPassScoreSecondRound(res.results?.find((v) => v.round === 1));
        } else {
          setPassScoreFirstRound(res.results?.find((v) => v.round === 2));
          setPassScoreSecondRound(res.results?.find((v) => v.round === 3));
        }
      }
    });

    getResultsStats().then((res) => {
      setResultsStats(res);
    });

    return () => {};
  }, []);

  const nextPage = () => {
    setLoading(true);

    getResults({
      round: round,
      name: iinToSearch,
      is_asp: isAsp,
      status: status,
      ordering: ordering,
      page: page + 1,
      page_size: pageSize,
    })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v + 1);
  };

  const previousPage = () => {
    setLoading(true);

    getResults({
      round: round,
      name: iinToSearch,
      is_asp: isAsp,
      status: status,
      ordering: ordering,
      page: page - 1,
      page_size: pageSize,
    })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  const gotoPage = (pageNum) => {
    setLoading(true);

    getResults({
      round: round,
      name: iinToSearch,
      is_asp: isAsp,
      status: status,
      ordering: ordering,
      page: pageNum,
      page_size: pageSize,
    })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        setPage(pageNum);
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  const handleOrdering = (newOrdering) => {
    newOrdering = `-${newOrdering}`;
    if (ordering === newOrdering) {
      newOrdering = ordering.substring(1);
    }

    setOrdering(newOrdering);

    setLoading(true);
    getResults({
      round: round,
      name: iinToSearch,
      is_asp: isAsp,
      status: status,
      ordering: newOrdering,
      page: 1,
      page_size: pageSize,
    })
      .then((res) => {
        setResultsData(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        setPage(1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='flex flex-col gap-2'>
      <div>
        <Button
          text='Отправить файл'
          onClick={() => {
            navigate('upload');
          }}
        />
      </div>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-row gap-2'>
          <Input
            type='text'
            className='w-72'
            placeholder='Поиск по ИИН, имени и фамилии'
            value={iinToSearch}
            onChange={(e, d) => {
              setIinToSearch(e.target.value);
            }}
          />
          <Button
            type='submit'
            text='Поиск'
            color='primary'
            className='h-9'
            onClick={() => {
              setLoading(true);
              getResults({
                round: round,
                name: iinToSearch,
                is_asp: isAsp,
                status: status,
                ordering: ordering,
                page: 1,
                page_size: pageSize,
              })
                .then((res) => {
                  setResultsData(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          />
          <Button
            type='button'
            Icon={XMarkIcon}
            className='h-9'
            onClick={() => {
              setLoading(true);
              getResults({
                round: round,
                is_asp: isAsp,
                status: status,
                ordering: ordering,
                page: 1,
                page_size: pageSize,
              })
                .then((res) => {
                  setResultsData(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setIinToSearch('');
                  setLoading(false);
                });
            }}
          />
        </div>
        <div className='flex flex-row justify-end gap-2'>
          <Select
            isDisabled={loading}
            className='w-48'
            value={round}
            isSearchable={false}
            options={[
              {
                label: 'Все туры',
                value: null,
              },
              ...[1, 2, 3, 4].map((v, i) => ({
                label: `${v} тур`,
                value: `${i}`,
              })),
            ]}
            // placeholder={t('announcement.select_positions')}
            onChange={(e) => {
              setLoading(true);

              getResults({
                round: e.value,
                is_asp: isAsp,
                status: status,
                ordering: ordering,
                page: 1,
                page_size: pageSize,
              })
                .then((res) => {
                  setResultsData(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                  setRound(e.value);
                });
            }}
          />
          <Select
            isDisabled={loading}
            className='w-48'
            value={isAsp}
            isSearchable={false}
            options={[
              {
                label: 'Все категории',
                value: null,
              },
              {
                label: 'ӘОТ',
                value: true,
              },
              {
                label: 'ӘОТ емес',
                value: false,
              },
            ]}
            placeholder='ӘОТ'
            onChange={(e) => {
              setLoading(true);

              getResults({
                round: round,
                name: iinToSearch,
                is_asp: e.value,
                status: status,
                ordering: ordering,
                page: 1,
                page_size: pageSize,
              })
                .then((res) => {
                  setResultsData(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                  setIsAsp(e.value);
                });
            }}
          />
          <Select
            isDisabled={loading}
            className='w-48'
            value={status}
            isSearchable={false}
            options={[
              {
                label: 'Все cтатусы',
                value: null,
              },
              {
                label: 'Өтті',
                value: 2,
              },
              {
                label: 'Өтпеді',
                value: 0,
              },
              // {
              //   label: 'Резерв',
              //   value: 1,
              // },
            ]}
            placeholder='Статус'
            onChange={(e) => {
              setLoading(true);

              getResults({
                round: round,
                name: iinToSearch,
                is_asp: isAsp,
                status: e.value,
                ordering: ordering,
                page: 1,
                page_size: pageSize,
              })
                .then((res) => {
                  setResultsData(res.results);
                  setCount(res.count);
                  setPageCount(Math.ceil(res.count / pageSize));
                  setPage(1);
                })
                .finally(() => {
                  setLoading(false);
                  setStatus(e.value);
                });
            }}
          />
          {/* <Button className='h-9' text='Экспорт' color='success' /> */}
        </div>
      </div>
      {['002', '015', '051'].includes(
        passScoreFirstRound?.school?.school_id,
      ) ? (
        <>
          <div className='flex flex-row justify-end gap-2'>
            <div>
              Проходной балл 1 тур (KAZ):{' '}
              <span className='font-semibold'>
                {passScoreFirstRound?.pass_score_kz}
              </span>
            </div>
            <div>|</div>
            <div>
              Проходной балл 1 тур (RUS):{' '}
              <span className='font-semibold'>
                {passScoreFirstRound?.pass_score_ru}
              </span>
            </div>
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <div className='font-semibold'>ӘОТ:</div>
            <div className='bg-slate-200'>
              Проходной балл 1 тур (ӘОТ) (KAZ):{' '}
              <span className='font-semibold'>
                {passScoreFirstRound?.pass_score_asp_kz}
              </span>
            </div>
            <div>|</div>
            <div className='bg-slate-200'>
              Проходной балл 1 тур (ӘОТ) (RUS):{' '}
              <span className='font-semibold'>
                {passScoreFirstRound?.pass_score_asp_ru}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className='flex flex-row justify-end gap-2'>
          <div>
            Проходной балл 1 тур:{' '}
            <span className='font-semibold'>
              {passScoreFirstRound?.pass_score}
            </span>
          </div>
          <div>|</div>
          <div>
            Проходной балл 1 тур (ӘОТ):{' '}
            <span className='font-semibold'>
              {passScoreFirstRound?.pass_score_asp}
            </span>
          </div>
        </div>
      )}
      {['002', '015', '051'].includes(
        passScoreSecondRound?.school?.school_id,
      ) ? (
        <>
          <div className='flex flex-row justify-end gap-2'>
            <div>
              Проходной балл 2 тур (KAZ):{' '}
              <span className='font-semibold'>
                {passScoreSecondRound?.pass_score_kz}
              </span>
            </div>
            <div>|</div>
            <div>
              Проходной балл 2 тур (RUS):{' '}
              <span className='font-semibold'>
                {passScoreSecondRound?.pass_score_ru}
              </span>
            </div>
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <div className='font-semibold'>ӘОТ:</div>
            <div className='bg-slate-200'>
              Проходной балл 2 тур (ӘОТ) (KAZ):{' '}
              <span className='font-semibold'>
                {passScoreSecondRound?.pass_score_asp_kz}
              </span>
            </div>
            <div>|</div>
            <div className='bg-slate-200'>
              Проходной балл 2 тур (ӘОТ) (RUS):{' '}
              <span className='font-semibold'>
                {passScoreSecondRound?.pass_score_asp_ru}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className='flex flex-row justify-end gap-2'>
          <div>
            Проходной балл 2 тур:{' '}
            <span className='font-semibold'>
              {passScoreSecondRound?.pass_score}
            </span>
          </div>
          <div>|</div>
          <div>
            Проходной балл 2 тур (ӘОТ):{' '}
            <span className='font-semibold'>
              {passScoreSecondRound?.pass_score_asp}
            </span>
          </div>
        </div>
      )}
      <div className='flex flex-row justify-end'>
        <div className='flex flex-col items-end'>
          <p>
            Участвовали:{' '}
            <span className='font-bold'>{resultsStats?.participated}</span> |
            Участвовали (ӘОТ):{' '}
            <span className='font-bold'>{resultsStats?.participated_asp}</span>
          </p>
          <p>
            Прошло: <span className='font-bold'>{resultsStats?.accepted}</span>{' '}
            | Прошло (ӘОТ):{' '}
            <span className='font-bold'>{resultsStats?.accepted_asp}</span>
          </p>
        </div>
      </div>
      <TableWithServerOrdering
        columns={columns}
        data={resultsData}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        loading={loading}
        ordering={ordering}
        handleOrdering={handleOrdering}
      />
    </div>
  );
}
