import bilAPI from '../api';

export const getAcceptanceStats = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/school_acceptance_stats_api/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
