import examAPI from '../examAPI';

export const getExamAccessibilityByCourseId = (exam) => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .get(`/exam/exam_accessibility/`, {
          params: {
            exam,
          },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postExamAccessibility = (body) => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .post('/exam/exam_accessibility/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchExamAccessibility = (accessibilityUuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .patch(`/exam/exam_accessibility/${accessibilityUuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
