import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/forms/Buttons/Button';
import Select from '../../../components/forms/Select/Select';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import { postExam } from '../../../services/exams/examsService';
import { EXAMTYPES } from '../../../utils/constants/constants';
import Input from '../../../components/forms/Input/Input';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import { PlusSmallIcon } from '@heroicons/react/24/outline';

const Add = ({ getData }) => {
  const { addSnackbar } = useSnackbar();

  const { register, handleSubmit, reset, watch, setValue } = useForm();
  const [open, setOpen] = useState(false);

  const createExamHandler = (data) => {
    if (data?.type == null) {
      data.type = 0;
    }

    if (!Number.isInteger(data?.duration / 1)) {
      addSnackbar('Длительность экзамена должно быть числом', 'error');
      return;
    }
    setOpen(false);

    postExam(data)?.finally(() => {
      getData();
      addSnackbar('Экзамен успешно добавлен', 'success');
    });
    reset();
  };

  return (
    <>
      <Button
        text='Добавить'
        Icon={PlusSmallIcon}
        color='primary'
        onClick={() => {
          reset();
          setOpen(true);
        }}
      />

      <CustomDialog
        isOpen={open}
        headerText='Подтвердите ваши действие'
        closeModal={() => {
          setOpen(false);
          reset();
        }}
      >
        <form
          onSubmit={handleSubmit(createExamHandler)}
          className='flex flex-col gap-2'
        >
          <div className='flex flex-col'>
            <Input
              label='Название'
              inputClassName='border'
              {...register('name', { required: true })}
              required
            />
            <div className='flex flex-col '>
              <label className='block text-sm font-medium text-gray-700'>
                Тип
                <span className={`text-error`}>{'*'}</span>
              </label>
              <Select
                value={watch('type') ? watch('type') : 0}
                onChange={(v) => {
                  setValue('type', v?.value);
                }}
                options={EXAMTYPES?.map((type, i) => ({
                  label: type,
                  value: i,
                }))}
              />
            </div>
            <Input
              label='Длительность экзамена'
              inputClassName='border'
              {...register('duration', { required: true })}
              required
            />
            <Input
              label='Вариант'
              inputClassName='border'
              {...register('variant', { required: true })}
              required
            />
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <Button
              text='Отмена'
              color='secondary'
              onClick={() => {
                setOpen(false);
                reset();
              }}
            />
            <Button text='Создать' color='success' type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Add;
