import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// import { getResults } from '../../services/results/resultsService';
import { Button } from '../../../components/forms/Buttons/Button';
import { uploadResultsToCheck } from '../../../services/results/resultsService';
import Select from '../../../components/forms/Select/Select';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import { getSettings } from '../../../services/settings/settingsService';
import { UserContext } from '../../../utils/contexts/UserContext';

export default function UploadResults() {
  // const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { addSnackbar } = useSnackbar();

  const [uploads, setUploads] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [round, setRound] = useState(null);
  const [districtType, setDistrictType] = useState(null);
  const [file, setFile] = useState(null);
  // const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState(null);
  const [fileStatus, setFileStatus] = useState(null);
  const [settings, setSettings] = useState([]);
  const inputRef = useRef(null);

  const getSettingsData = useCallback(() => {
    setIsLoading(true);
    getSettings({ allowed_schools: user.school.uuid, code: 'UPLOAD_TXT_ROUND' })
      ?.then((res) => {
        setSettings(res);
      })
      ?.finally(() => setIsLoading(false));
  }, [user.school.uuid]);

  useEffect(() => {
    getSettingsData();

    return () => {
      setFile(null);
      setFileError(null);
    };
  }, [getSettingsData]);

  return (
    <>
      {!isLoading ? (
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col items-center gap-2 md:flex-row'>
            <input
              className='border'
              type='file'
              accept='.txt'
              ref={inputRef}
              onChange={(e, d) => {
                setFile(e.target.files[0]);
                // e.target.value = null;
              }}
            />
            <Select
              className='z-20'
              value={round}
              isSearchable={false}
              options={[1, 2, 3, 4]?.reduce((res, v) => {
                if (settings?.some((c) => c.code === `UPLOAD_TXT_ROUND_${v}`)) {
                  res.push({
                    label: `${v} тур`,
                    value: v,
                  });
                }

                return res;
              }, [])}
              placeholder='Тур'
              onChange={(e) => {
                setRound(e.value);
                setDistrictType(null);
              }}
            />
            {round && (
              <Select
                className='z-20'
                value={districtType}
                isSearchable={false}
                options={[
                  ...(settings?.some((v) =>
                    [
                      'UPLOAD_TXT_ROUND_1',
                      'UPLOAD_TXT_ROUND_2',
                      'UPLOAD_TXT_ROUND_3',
                      'UPLOAD_TXT_ROUND_4',
                    ].includes(v.code),
                  )
                    ? [
                        {
                          label: 'Кала',
                          value: 1,
                        },
                      ]
                    : []),
                  ...(settings?.some((v) =>
                    ['UPLOAD_TXT_ROUND_1', 'UPLOAD_TXT_ROUND_3'].includes(
                      v.code,
                    ),
                  ) && [1, 3].includes(round)
                    ? [
                        {
                          label: 'Аудан',
                          value: 2,
                        },
                      ]
                    : []),
                ]}
                placeholder='Тип центра'
                onChange={(e) => {
                  setDistrictType(e.value);
                }}
              />
            )}
            <Button
              Icon={ArrowUpTrayIcon}
              color='primary'
              className='h-9'
              text='Отправить файл'
              loading={loading}
              onClick={() => {
                if (districtType && round && file) {
                  setLoading(true);
                  uploadResultsToCheck(file, round, districtType)
                    .then((res) => {
                      setUploads(res?.comments);
                      setCount(res.comments.length);
                      setFileError(res?.has_errors);
                      setFileStatus(res?.file_status);
                    })
                    // .catch(() => {
                    //   addSnackbar(t('Ошибка'), 'error');
                    // })
                    .finally(() => {
                      setLoading(false);
                      setFile(null);
                      inputRef.current.value = null;
                      setDistrictType(null);
                      setRound(null);
                    });
                }

                if (file === null) {
                  addSnackbar('Выберите файл', 'error');
                } else if (!districtType || !round) {
                  addSnackbar('Выберите тур / место', 'error');
                }
              }}
            />
          </div>
          <div className='flex flex-row justify-between gap-2'>
            <div>
              {fileStatus === 0 && (
                <span className='font-semibold text-red-600'>
                  Файл не загружен
                </span>
              )}
              {fileStatus === 1 && (
                <span className='font-semibold text-green-600'>
                  Файл загружен
                </span>
              )}
            </div>
            <div>
              <div>
                Количество записей:
                <span className='font-bold'>{' ' + count}</span>
              </div>
              <div>
                {fileError === true && (
                  <div>
                    Ошибки:{' '}
                    <span className='font-semibold text-red-600'>Да</span>
                  </div>
                )}
                {fileError === false && (
                  <div>
                    Ошибки:{' '}
                    <span className='font-semibold text-green-600'>Нет</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            {loading ? (
              <div className='h-8 text-center'>
                <SimpleLoader />
              </div>
            ) : (
              <table className='w-full table-auto'>
                <thead className='sticky top-0 z-10 border-b bg-sheet'>
                  <tr>
                    <td className='py-2 text-center font-medium uppercase'>
                      #
                    </td>
                    <td className='py-2 pl-4 font-medium uppercase'>ИИН</td>
                    <td className='py-2 pl-4 font-medium uppercase'>
                      Имя Фамилия
                    </td>
                    <td className='py-2 text-center font-medium uppercase'>
                      Вариант
                    </td>
                    <td className='py-2 text-center font-medium uppercase'>
                      Cтатус
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {uploads.map((student) => (
                    <tr
                      key={student?.index}
                      className={`border-b last:border-b-0 hover:bg-sheet-gray ${
                        student?.index % 2 === 0 && 'bg-white'
                      }`}
                    >
                      <td className='border-l border-r px-2 text-center'>
                        {student?.index}
                      </td>
                      <td className='border-r pl-4'>{student?.iin}</td>
                      <td className='border-r pl-4'>{student?.full_name}</td>

                      <td className='border-r text-center'>
                        {student?.variant}
                      </td>
                      <td className='border-r text-center'>
                        {student?.error ? (
                          <span className=' text-red-600'>
                            {student?.message}
                          </span>
                        ) : (
                          <span className=' text-green-600'>OK</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      ) : (
        <SimpleLoader className='h-8' />
      )}
    </>
  );
}
