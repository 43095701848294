import React from 'react';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { Button } from '../../components/forms/Buttons/Button';
import { deleteTransfer } from '../../services/transferStudents/transferBetweenSchoolsService';

export default function DeleteConfirmationModal({
  modalOpen,
  setModalOpen,
  transfer,
  updateList,
}) {
  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Трансфер'}
    >
      <div>
        Вы уверены, что хотите удалить трансфер{' '}
        <span className='font-bold'>{`${transfer?.application?.first_name} ${transfer?.application?.last_name}`}</span>{' '}
        в <span className='font-bold'>{transfer?.to_school?.name}</span>
      </div>
      <div className='flex flex-row justify-end gap-4'>
        <Button
          text='нет'
          onClick={() => {
            setModalOpen(false);
          }}
        />
        <Button
          color='error-solid'
          text='Да'
          onClick={async () => {
            await deleteTransfer(transfer.uuid)
              .then(() => {
                updateList();
              })
              .finally(() => {
                setModalOpen(false);
              });
          }}
        />
      </div>
    </CustomDialog>
  );
}
