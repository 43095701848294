import React, { useCallback, useEffect, useState } from 'react';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { getSettings } from '../../services/settings/settingsService';
import { getSchools } from '../../services/catalog/catalog';
import { useForm } from 'react-hook-form';
import SettingEditModal from './SettingEditModal';

const Setting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [schools, setSchools] = useState([]);
  useForm();

  const getSettingsData = useCallback(() => {
    setIsLoading(true);
    getSettings({})
      ?.then((res) => {
        setSettings(res);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!schools.length) {
      getSchools({ page_size: 50 }).then((res) => {
        setSchools(res.results);
      });
    }
    getSettingsData();
  }, [getSettingsData, schools.length]);

  return (
    <>
      {isLoading ? (
        <SimpleLoader className='h-10' />
      ) : (
        <>
          {settings?.map((setting) => {
            return (
              <div
                key={setting.uuid}
                className='flex flex-row items-center justify-between border-b py-2 px-20'
              >
                <span>{setting.name}</span>
                <SettingEditModal
                  setting={setting}
                  schools={schools}
                  getData={() => getSettingsData()}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default Setting;
