import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';

const ChoiceChip = ({
  key,
  selected,
  label,
  onClick,
  disabled,
  type,
  className,
  disabledOnSelect = true,
}) => {
  return (
    <button
      key={key}
      className={`group relative inline-flex h-10 items-center justify-center gap-1 overflow-hidden rounded-3xl px-5 py-1 font-medium 
    transition duration-100 ease-in 
    disabled:cursor-default ${
      selected ? 'bg-opacity-40 ring-1' : 'bg-opacity-10 hover:bg-opacity-20'
    }
  ${
    type === 'error'
      ? 'bg-error text-error ring-error'
      : type === 'secondary'
      ? 'bg-secondary text-secondary ring-secondary'
      : type === 'success'
      ? 'bg-success text-success ring-success'
      : `bg-primary text-primary ring-primary`
  } ${className}`}
      onClick={onClick}
      disabled={(selected && disabledOnSelect) || disabled}
    >
      <div className='flex flex-row items-center justify-center gap-2'>
        {selected ? (
          <CheckIcon
            className={`h-6 ${
              type === 'error'
                ? 'text-error'
                : type === 'secondary'
                ? 'text-secondary'
                : type === 'success'
                ? 'text-success'
                : `text-primary`
            }`}
          />
        ) : undefined}
        {label}
      </div>
    </button>
  );
};

export default ChoiceChip;
