import bilAPI from '../api';

export const getSocialDocuments = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/social_docs/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const postSocialDocument = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/core/social_docs/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSocialDocument = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/core/social_docs/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchSocialDocument = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/core/social_docs/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteSocialDocument = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/core/social_docs/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
