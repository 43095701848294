import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import { KAZ_RULES, RUS_RULES } from '../../utils/constants/rules';

export default function Statement({ setModalOpen }) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();

  const [isChecked, setIsChecked] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollableContainer = document.getElementById('agreementId');

      const isBottom =
        scrollableContainer.scrollHeight -
          scrollableContainer.scrollTop -
          scrollableContainer.clientHeight <
        1;

      setIsAtEnd(isBottom);
    };

    const handleScrollEnd = () => {
      if (isAtEnd && !hasScrolledToEnd) {
        // Your action to be performed when reaching the end
        setHasScrolledToEnd(true);
      }
    };

    document
      .getElementById('agreementId')
      .addEventListener('scroll', handleScroll);
    document
      .getElementById('agreementId')
      .addEventListener('scroll', handleScrollEnd);

    return () => {
      document
        .getElementById('agreementId')
        ?.removeEventListener('scroll', handleScroll);
      document
        .getElementById('agreementId')
        ?.removeEventListener('scroll', handleScrollEnd);
    };
  }, [isAtEnd, hasScrolledToEnd]);

  return (
    <div className='flex h-[40rem] flex-col gap-2'>
      <div
        id='agreementId'
        className='flex flex-col gap-4 overflow-y-scroll rounded-md border border-gray-800 text-justify text-sm'
      >
        {listRules(i18n.language)}
      </div>
      <div>
        <div className='flex items-center justify-end'>
          <p className='font-semibold'>{t('signup.read_to_the_end')}</p>
        </div>
        <div className='flex items-center justify-end'>
          <input
            id='checkRU'
            name='checkRU'
            type='checkbox'
            checked={isChecked}
            value={isChecked}
            disabled={!isAtEnd && !hasScrolledToEnd}
            onChange={() => setIsChecked((v) => !v)}
            className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
          />
          <label htmlFor='checkRU' className={'ml-2 block text-red-600'}>
            {t('signup.checkbox_text')}
          </label>
        </div>
        <div className='flex flex-row justify-end gap-2'>
          <Button
            text={t('buttons.cancel')}
            onClick={() => {
              navigate('/');
            }}
          />
          <Button
            text={t('buttons.continue')}
            onClick={() => {
              if (isChecked) {
                setModalOpen(false);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export const listRules = (lang) => {
  let data = null;

  if (lang === 'kk') {
    data = KAZ_RULES;
  } else {
    data = RUS_RULES;
  }

  return (
    <div>
      <h3 className='text-center font-bold'>
        {lang === 'kk'
          ? '«Білім-инновация» лицейлеріне конкурстық негізде іріктеу мен оқуға қабылдау ережелері'
          : 'Правила конкурсного отбора и приема на обучение в лицеи «Білім-инновация»'}
      </h3>
      <ol className='list-decimal px-8 py-2'>
        {data?.map((rule) => (
          <div key={rule?.title}>
            <div className='text-center font-bold'>
              <h3>{rule?.title}</h3>
            </div>
            {rule?.list?.map((item, i) => (
              <li key={i}>
                {item.text}
                <ul className={`${item?.subtext.type} list-inside`}>
                  {item?.subtext.content.map((sb, i) => (
                    <li key={i + 10}>{sb}</li>
                  ))}
                </ul>
              </li>
            ))}
          </div>
        ))}
      </ol>
    </div>
  );
};
