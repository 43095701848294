export const landingSelect = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderWidth: '1px',
    borderColor: '#717c9e',
    borderRadius: '12px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    textAlign: 'center',
    fontSize: '14px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#374151',
    };
  },
  menu: (provided, state) => ({
    ...provided,
    // backgroundColor: 'transparent',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'black',
  }),
};

export const navigationSelect = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderWidth: '1px',
    borderColor: '#717c9e',
    borderRadius: '12px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    textAlign: 'center',
    fontSize: '14px',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#374151',
    };
  },
  menu: (provided, state) => ({
    ...provided,
    // backgroundColor: 'transparent',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'black',
  }),
};
