import bilAPI from '../api';

export const getYears = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/core/exam_years/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getExamDates = (year) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/core/exam/`, { params: { year } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
