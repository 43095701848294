import bilAPI from '../api';

export const getSchoolStatistics = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/schools_stats/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getResultsUploadedCount = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/school_uploaded_count/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSchoolResultsStatistics = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/results_school_stats_api/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSchoolResultsStatisticsExcel = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/results_school_stats_export/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSchoolPassScoreStats = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/results/school_pass_score_stats_api/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getDistrictRegistrationComparisonStats = (school, end_date) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/district_stats/', {
          params: { school, end_date, page_size: 60 },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSchoolsRegistrationComparisonStats = (region, end_date) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/school_year_stats/', {
          params: { region, end_date, page_size: 60 },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSchoolsExamTimeStats = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/school_exam_time_stats/', {
          params: params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getSchoolClassroomStats = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/classroom_stats/', {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
