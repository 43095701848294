import React, { useContext, useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
// import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getApplication } from '../../../services/application/application';
import { classNames } from '../../../utils/helpers/helpers';
import ExaminationDetails from './ExaminationDetails';
import MainInfo from './MainInfo';
import VulnerableStudentDocuments from './VulnerableStudentDocuments';
import { Tab } from '@headlessui/react';
import DocumentTab from './DocumentTab';
import { getSocialDocumentsTypes } from '../../../services/socialDocs/socialDocsTypes';
import { getExamDates } from '../../../services/core/coreService';
import { ExamYearContext } from '../../../utils/contexts/ExamYearContext';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ApplicantInfo() {
  // const [t] = useTranslation();
  const { uuid } = useParams();
  const { examYear } = useContext(ExamYearContext);

  const [applicantInfo, setApplicantInfo] = useState(null);
  const [vulnerableGroups, setVulnerableGroups] = useState([]);
  const [examDates, setExamDates] = useState([]);
  const [vulnerableDocsLength, setVulnerableDocsLength] = useState(0);

  useEffect(() => {
    if (uuid) {
      getApplication(uuid).then((res) => {
        setApplicantInfo(res);
      });

      getSocialDocumentsTypes().then((res) => {
        setVulnerableGroups(res.results);
      });
    }

    return () => {};
  }, [uuid]);

  useEffect(() => {
    if (examYear?.uuid) {
      getExamDates(examYear.uuid).then((res) => {
        var options = res?.results?.map((date) => ({
          label: date.name,
          value: date.uuid,
        }));
        setExamDates(options);
      });
    }

    return () => {};
  }, [examYear]);

  return (
    <div className='flex w-full justify-center'>
      <div className='max-w-7xl xl:min-w-[80rem]'>
        <h3 className='mx-4 mb-8 text-3xl'>
          {'Данные поступающего: ' +
            applicantInfo?.first_name +
            ' ' +
            applicantInfo?.last_name}
        </h3>
        <Tab.Group vertical>
          <div className='flex flex-col md:flex-row-reverse'>
            <div className='md:w-1/4'>
              <Tab.List className='flex flex-col  rounded-md border-2 border-gray-800 bg-white text-left'>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'border-blue-600 bg-blue-600 text-white'
                        : 'text-gray-700',
                      'block border-b px-4 py-2 text-left text-sm',
                    )
                  }
                >
                  Основные данные
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'border-blue-600 bg-blue-600 text-white'
                        : 'text-gray-700',
                      'block border-b px-4 py-2 text-left text-sm',
                    )
                  }
                >
                  Свидетельство о рождении
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'border-blue-600 bg-blue-600 text-white'
                        : 'text-gray-700',
                      'block border-b px-4 py-2 text-left text-sm',
                    )
                  }
                >
                  Документы, подтверждающие принадлежность к социально уязвимым
                  группам
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'border-blue-600 bg-blue-600 text-white'
                        : 'text-gray-700',
                      'block  px-4 py-2 text-left text-sm',
                    )
                  }
                >
                  Указать данные экзамена #1
                </Tab>
              </Tab.List>
            </div>

            <Tab.Panels className='mx-4 bg-white shadow sm:rounded-lg md:w-3/4'>
              <Tab.Panel>
                <MainInfo applicant={applicantInfo} />
              </Tab.Panel>
              <Tab.Panel>
                <DocumentTab document={applicantInfo?.document} />
              </Tab.Panel>
              <Tab.Panel>
                <VulnerableStudentDocuments
                  applicant={applicantInfo}
                  vulnerableGroups={vulnerableGroups}
                  setApplicantInfo={setApplicantInfo}
                  setVulnerableDocsLength={setVulnerableDocsLength}
                />
              </Tab.Panel>
              <Tab.Panel>
                <ExaminationDetails
                  applicant={applicantInfo}
                  examDates={examDates}
                  vulnerableDocsLength={vulnerableDocsLength}
                />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
}
