import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMyResults } from '../../../services/results/resultsService';
import { ExamYearContext } from '../../../utils/contexts/ExamYearContext';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import NewResultForm from './NewResultForm/NewResultForm';
import OldResultForm from './OldResultForm/OldResultForm';

export default function MyResults() {
  const [t] = useTranslation();
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { examYear } = useContext(ExamYearContext);

  useEffect(() => {
    setIsLoading(true);
    getMyResults()
      .then((res) => {
        setResults(res?.results);
      })
      .finally(() => setIsLoading(false));
    return () => {};
  }, [examYear?.uuid]);

  if (isLoading) return <SimpleLoader className='h-10' />;
  else if (!results?.length) {
    return (
      <div className='flex flex-col items-center'>
        {isLoading ? (
          <SimpleLoader className='h-10' />
        ) : (
          <div className=' rounded-md bg-yellow-200 p-4'>
            <p className='text-2xl font-semibold'>
              {t('results.results_is_not_avaible')}
            </p>
          </div>
        )}
      </div>
    );
  } else if (results?.length && results[0].exam_year === 2024) {
    return <NewResultForm result={results[0]} />;
  } else {
    return <OldResultForm results={results} />;
  }
}
