import React, { useRef } from 'react';
import { useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import Select from '../../../components/forms/Select/Select';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import {
  ANSWER_OPTIONS,
  QUESTIONS_TYPES,
} from '../../../utils/constants/constants';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import { CameraIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { postQuestion } from '../../../services/exams/questionService';

const Add = ({ className, exam, questionId, getData }) => {
  const { addSnackbar } = useSnackbar();

  const [image, setImage] = useState();
  const [imageFile, setImageFile] = useState();
  const [type, setType] = useState(0);
  const [answer, setAnswer] = useState('A');
  const [open, setOpen] = useState(false);
  const fileRef = useRef();

  const createQuestionHandler = () => {
    const Body = new FormData();
    if (!image) {
      addSnackbar('Загрузите изображение', 'error');
      return;
    }
    Body.append('image', imageFile);
    Body.append('type', type);
    Body.append('exam', exam);
    Body.append('correct_answer', answer);
    setOpen(false);

    postQuestion(Body)?.finally(() => {
      addSnackbar('Вопрос успешно добавлен', 'success');
      getData();
    });
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    if (e.target.files[0].type.substring(0, 5) !== 'image') {
      addSnackbar(
        'Жүктелген файл сурет болуы керек! / Загружаемый вами файл должен быть изображением!',
        'error',
      );
      return;
    }
    if (e.target.files[0].size > 1048576) {
      addSnackbar(
        'Жүктелген файл 1 мегабайттан аспау керек! / Загружаемый вами файл не должен превышать 1 МБ',
        'error',
      );
      return;
    } else {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => setImage(reader.result);
      reader.readAsDataURL(file);

      setImageFile(file);
    }
  };

  return (
    <>
      <Button
        className={className}
        text='Добавить'
        Icon={PlusSmallIcon}
        onClick={() => {
          setOpen(true);
        }}
      />

      <CustomDialog
        isOpen={open}
        headerText={`Вопрос #${questionId}`}
        closeModal={() => {
          setOpen(false);
        }}
      >
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col'>
            <label className='block text-sm font-medium text-gray-700'>
              Тип
              <span className={`text-error`}>{'*'}</span>
            </label>
            <Select
              value={type}
              onChange={(v) => {
                setType(v?.value);
              }}
              options={QUESTIONS_TYPES?.map((type, i) => ({
                label: type,
                value: i,
              }))}
            />

            <center className='my-2'>
              {image ? (
                <img
                  className='img-responsive rounded-lg'
                  alt='question'
                  src={image}
                />
              ) : (
                <div className='flex h-52 w-full flex-col justify-center rounded-lg bg-yellow-300 text-center'>
                  Нет загруженного изображения
                </div>
              )}
            </center>
            <div className='flex flex-row justify-end'>
              <Button
                className='max-w-fit'
                Icon={CameraIcon}
                text='Загрузить'
                color='primary'
                onClick={() => fileRef.current.click()}
              />
            </div>
            <input
              hidden
              type='file'
              ref={fileRef}
              onChange={handleImageChange}
            />
            <div className='flex flex-row justify-center gap-10'>
              {ANSWER_OPTIONS?.map((option) => (
                <div className='flex flex-row items-center gap-1 ' key={option}>
                  <input
                    type='radio'
                    key={option}
                    checked={answer === option}
                    onChange={() => setAnswer(option)}
                  />
                  <label>{option}</label>
                </div>
              ))}
            </div>
          </div>
          <div className='flex flex-row justify-end gap-2'>
            <Button
              text='Отмена'
              color='secondary'
              onClick={() => {
                setOpen(false);
              }}
            />
            <Button
              text='Создать'
              color='success'
              onClick={() => createQuestionHandler()}
            />
          </div>
        </div>
      </CustomDialog>
    </>
  );
};

export default Add;
