import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import apply_kz from '../images/apply_kz.png';
import apply_ru from '../images/apply_ru.png';
import first_step_exam_icon from '../images/first_step_exam_icon.png';
import pen_icon from '../images/pen_icon.png';
import registration_kz from '../images/registration_kz.png';
import registration_ru from '../images/registration_ru.png';
import pass_kz from '../images/pass_kz.png';
import pass_ru from '../images/pass_ru.png';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const HowToEnrollSection = () => {
  const [t, i18n] = useTranslation();
  const slider = useRef();
  const HowToEnrollCardsBackground = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray('.card');
      gsap.to(panels, {
        xPercent: -100 * (panels.length - (window.innerWidth >= 1024 ? 2 : 1)),
        ease: 'none',
        lazy: false,
        scrollTrigger: {
          trigger: slider.current,
          start: 'top top',
          pin: true,
          scrub: 1,
          end: () => '+=' + 3500,
        },
      });
    }, HowToEnrollCardsBackground.current);
    return () => ctx.revert();
  }, [slider]);
  return (
    <div ref={HowToEnrollCardsBackground}>
      <div
        ref={slider}
        className='flex w-[610vw] flex-row flex-wrap overflow-x-hidden overflow-y-visible  bg-slate-200 lg:flex lg:w-[305vw] '
      >
        <section className='card relative flex h-auto w-screen flex-col items-center rounded-2xl bg-black-landing p-12 shadow-lg lg:w-[50vw]'>
          <span className='whitespace-nowrap rounded-full px-8 py-3 font-sfproBold text-2xl text-brown '>
            {t('welcome.how_to_enter_to_bil')}
          </span>
          <p className='mt-9 mb-6 text-center font-sfproBold text-2xl text-white sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl'>
            {t('welcome.registration_to_exam')}
          </p>
          <div className='flex flex-col items-center text-2xl text-secondary'>
            <p className='mb-4  text-2xl '>
              {t('welcome.registration_period_with_date_1')}
              <span className='font-bold '>
                {t('welcome.registration_period_with_date_2')}{' '}
              </span>
              {t('welcome.registration_period_with_date_3')}
            </p>
            {/* <Link to='/registration'> */}
            <Button
              className='h-14 rounded-xl px-3 py-5 text-2xl font-light'
              color='landing-blue'
              text={t('welcome.registration')}
            />
            {/* </Link> */}
          </div>
          <img
            className='mt-4 '
            src={i18n.language === 'ru' ? registration_ru : registration_kz}
            alt='registration'
          />
        </section>
        <section className='card relative flex h-auto w-screen  flex-col items-center rounded-2xl bg-white p-12 shadow-lg lg:w-[50vw]'>
          <span className='rounded-full bg-slate-900 px-8 py-3 font-sfproBold text-2xl text-white'>
            {t('welcome.docs')}
          </span>
          <p className='mt-9 mb-6 text-center font-sfproBold text-4xl sm:text-6xl'>
            {t('welcome.docs_for_registration')}
          </p>
          <div className='flex flex-col text-2xl text-secondary'>
            <ul className='list-disc sm:list-none'>
              <li>1. {t('welcome.application_form')}</li>
              <li>2. {t('welcome.birth_certificate')}</li>
              <li>3. 3*4 {t('welcome.photo')}</li>
            </ul>
            <span className='pt-4'>{t('welcome.process')}</span>
          </div>
          <img
            className='mt-4 '
            width={438}
            height={240}
            src={i18n.language === 'ru' ? apply_ru : apply_kz}
            alt='apply'
          />
        </section>
        <section className='card relative flex h-auto w-screen  flex-col items-center rounded-2xl bg-white p-12 shadow-lg lg:w-[50vw]'>
          <span className='rounded-full bg-success px-8 py-3 font-sfproBold text-2xl text-white'>
            {t('welcome.pass')}
          </span>
          <p className='mt-9 mb-6  text-center font-sfproBold text-4xl sm:text-6xl'>
            {t('welcome.pass')}
          </p>
          <div className='text-2xl text-secondary'>
            <ul className=' text-left  sm:text-center'>
              <li>{t('welcome.pass_text')}</li>
              {/* <li className='mt-4 text-center font-sfproBold text-2xl text-slate-900 sm:text-2xl'>
                {t('welcome.practice_test')}
              </li> */}
              {/* <li>{t('welcome.practice_test_dear')}</li>

              <li>{t('welcome.practice_test_tip_2')}</li> */}
            </ul>
          </div>
          <img
            className='mt-4 '
            src={i18n.language === 'ru' ? pass_ru : pass_kz}
            alt='pass'
          />
        </section>

        <section className='card relative flex h-auto w-screen flex-col items-center rounded-2xl bg-white p-12 shadow-lg lg:w-[50vw]'>
          <span className='rounded-full bg-blue-500 px-8 py-3 font-sfproBold text-2xl text-white'>
            {t('welcome.entrance_exam')}
          </span>
          <p className='mt-9 mb-6 text-center font-sfproBold text-4xl sm:text-6xl'>
            <span>{t('welcome.exam_structure_1')}</span>{' '}
            <span className='text-brown'>60 </span>{' '}
            <span>
              {t('welcome.exam_structure_2')} {t('welcome.exam_structure_3')}
            </span>
          </p>

          <div className='text-2xl text-secondary'>
            <ol className='list-disc'>
              <li>
                {t('welcome.math')} - 50 {t('welcome.questions')}
              </li>
              <li>
                {t('welcome.read')} - 10 {t('welcome.questions')}
              </li>
            </ol>

            <ul className='text-center'>
              <li className='pt-4'>{t('welcome.testing_language')}</li>
              <li className='pt-4'>
                {t('welcome.for_testing')} 110 {t('welcome.minutes')}{' '}
                {t('welcome.given')}
              </li>
            </ul>
          </div>
          <img className='mt-4 ' src={first_step_exam_icon} alt='clock icon' />
        </section>
        {/* <section className='card relative flex h-auto w-screen flex-col items-center rounded-2xl bg-white p-12 shadow-lg lg:w-[50vw]'>
          <span className=' rounded-full  bg-orange-landing px-8 py-3 font-sfproBold text-2xl text-white'>
            {t('welcome.second_round')}
          </span>
          <p className='mt-9 mb-6 text-center font-sfproBold text-4xl sm:text-6xl'>
            <span>{t('welcome.second_period_exam')}</span>{' '}
            <span className='text-brown'>60 </span>{' '}
            <span>
              {t('welcome.exam_structure_2')} {t('welcome.exam_structure_3')}
            </span>
          </p>
          <div className='items-center text-2xl text-secondary'>
            <ul>
              <li>
                {t('welcome.math')} - 50 {t('welcome.questions')}
              </li>
              <li>
                {t('welcome.read')} - 10 {t('welcome.questions')}
              </li>
              <li className='pt-6 text-slate-900'>
                {t('welcome.for_testing')} 110 {t('welcome.minutes')}{' '}
                {t('welcome.given')}
              </li>
            </ul>
          </div>
          <img className='mt-4 ' src={second_step_exam_icon} alt='icon' />
        </section> */}
        <section className='card relative flex h-auto w-screen flex-col items-center rounded-2xl bg-white p-12 shadow-lg lg:w-[50vw]'>
          <span className='whitespace-nowrap rounded-full bg-yellow-500 px-8 py-3 font-sfproBold text-2xl text-white'>
            {t('welcome.examination_day')}
          </span>
          <p className='mt-9 mb-6 text-center font-sfproBold text-4xl sm:text-6xl'>
            {t('welcome.necessary_things')}
          </p>
          <div className='text-2xl text-secondary'>
            <ul>
              <li>1. {t('welcome.certificate')}</li>
              <li>2. {t('welcome.form')}</li>
              <li>3. {t('welcome.pen')}</li>
            </ul>
          </div>
          <img className='mt-4 ' src={pen_icon} alt='pen icon' />
        </section>
      </div>
    </div>
  );
};

export default HowToEnrollSection;
