import bilAPI from '../api';

export const getSocialDocumentsTypes = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/docs_type/')
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
