import { Disclosure, Menu, Transition } from '@headlessui/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Bars3Icon,
  BellIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../utils/helpers/helpers';
import TokenService from '../../../services/token.service';
import Select from '../../forms/Select/Select';
import { ExamYearContext } from '../../../utils/contexts/ExamYearContext';
import { getYears } from '../../../services/core/coreService';

const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

export default function NavBar({ user, setUser }) {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { examYear, updateExamYear } = useContext(ExamYearContext);

  const [navigation, setNavigation] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(examYear?.uuid);

  useEffect(() => {
    if (location?.pathname === '/') {
      setNavigation([
        { name: t('welcome.admission'), href: '#' },
        { name: t('welcome.rules'), href: '#' },
        { name: t('welcome.contacts'), href: '#' },
        { name: t('welcome.faq'), href: '#' },
      ]);
    } else {
      if (user?.roles?.some((role) => role?.code === 'SSA')) {
        setNavigation([
          {
            name: 'Статистика регистраций школ',
            href: '/school-registration-statistics',
            header: 'Статистика регистраций школ',
          },
          {
            name: 'Статистика сравнения регистраций',
            href: '/registration-comparison-statistics',
            header: 'Статистика сравнения регистраций',
          },
          {
            name: 'Статистика времени экзаменов школ',
            href: '/school-exam-time-statistics',
            header: 'Статистика времени экзаменов школ',
          },
          {
            name: 'Статистика кабинетов',
            href: '/school-classroom-statistics',
            header: 'Статистика времени экзаменов школ',
          },
          {
            name: 'Статистика экзаменов школ',
            href: '/school-exam-statistics',
            header: 'Статистика экзаменов школ',
          },
          {
            name: 'Статистика проходных баллов школ',
            href: '/school-pass-score-statistics',
            header: 'Статистика проходных баллов школ',
          },
          {
            name: 'Трансфер',
            href: '/transfer',
            header: 'Трансфер',
          },
          {
            name: 'Онлайн экзамены',
            href: '/admin/exams',
            header: 'Экзамены',
          },
          {
            name: 'Результаты',
            href: '/admin/results',
            header: 'Результаты',
          },
          {
            name: 'Настройки',
            href: '/admin/settings',
            header: 'Настройки',
          },
          {
            name: 'Настройка доп. учеников',
            href: '/admin/extra_allowed_students_settings',
            header: 'Настройки',
          },
        ]);
      } else if (
        user?.roles?.some(
          (role) => role?.code === 'SA' || role?.code.includes('RC'),
        )
      ) {
        setNavigation([
          {
            name: 'Статистика регистраций школ',
            href: '/school-registration-statistics',
            header: 'Статистика регистраций школ',
          },
          {
            name: 'Статистика сравнения регистраций',
            href: '/registration-comparison-statistics',
            header: 'Статистика сравнения регистраций',
          },
          {
            name: 'Статистика времени экзаменов школ',
            href: '/school-exam-time-statistics',
            header: 'Статистика времени экзаменов школ',
          },
          {
            name: 'Статистика кабинетов',
            href: '/school-classroom-statistics',
            header: 'Статистика времени экзаменов школ',
          },
          {
            name: 'Статистика экзаменов школ',
            href: '/school-exam-statistics',
            header: 'Статистика экзаменов школ',
          },
          {
            name: 'Статистика проходных баллов школ',
            href: '/school-pass-score-statistics',
            header: 'Статистика проходных баллов школ',
          },
          {
            name: 'Результаты',
            href: '/admin/results',
            header: 'Результаты',
          },
        ]);
      } else {
        setNavigation([
          { name: 'Заявки', href: '/applicants', header: 'Заявки' },
          {
            name: 'Принятые ученики',
            href: '/students',
            header: 'Принятые ученики',
          },
          { name: 'Результаты', href: '/results', header: 'Результаты' },
          { name: 'Принять документы', href: '/accepted', header: 'Документы' },
          {
            name: 'Трансфер',
            href: '/transfer',
            header: 'Трансфер',
          },
          {
            name: 'Статистика кабинетов',
            href: '/school-classroom-statistics',
            header: 'Статистика времени экзаменов школ',
          },
          {
            name: 'Районы / Экзам центры',
            href: '/districts',
            header: 'Районы',
          },
          { name: 'Моя школа', href: '/my-school', header: 'Районы' },
          {
            name: 'Пробные экзамены',
            href: '/examinations-stats',
            header: 'Районы',
          },
          {
            name: 'Оффлайн регистрация района',
            href: '/district-registration',
            header: 'Регистрация района',
          },
        ]);
      }
    }

    return () => {};
  }, [location, t, user]);

  useEffect(() => {
    if (navigation.length > 1) {
      navigation.forEach((item) => {
        if (location?.pathname?.includes(item?.href)) {
          setCurrentPage(item.name);
        }
      });
    }

    return () => {};
  }, [location, navigation]);

  useEffect(() => {
    getYears().then((res) => {
      var options = res?.results?.map((y) => ({
        label: y?.year,
        value: y?.uuid,
        data: y,
      }));
      setYears(options);

      var currentYear = res?.results?.filter((y) => {
        return y?.current === true;
      })[0];

      updateExamYear(currentYear);
      setSelectedYear(currentYear?.uuid);
    });

    return () => {
      updateExamYear(null);
      setSelectedYear(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='min-h-full w-screen bg-sheet'>
        <Disclosure as='nav' className='bg-gray-800'>
          {({ open }) => (
            <>
              <div className='mx-auto px-4 sm:px-6 lg:px-8'>
                <div className='flex h-16 items-center justify-between'>
                  <div className='flex items-center'>
                    {/* <div className='flex-shrink-0'> */}
                    {/* <img src={logo} alt='logo' width='32' height='32' /> */}
                    {/* </div> */}
                    <div className='hidden md:block'>
                      <div className='flex items-baseline space-x-4 overflow-x-auto'>
                        {navigation.map((item, i) => (
                          <NavLink
                            key={i}
                            to={item.href}
                            onClick={() => {
                              setCurrentPage(item?.name);
                            }}
                            className={({ isActive }) =>
                              isActive
                                ? 'rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white'
                                : 'rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white'
                            }
                          >
                            <div className='relative'>
                              {[
                                '/registration-comparison-statistics',
                                '/school-exam-time-statistics',
                                '/transfer',
                                '/school-classroom-statistics',
                              ].includes(item?.href) && (
                                <div className='z-1 absolute -top-4 -right-6 rounded-md border border-red-600  bg-red-600 px-1 text-xs  font-bold text-white'>
                                  new
                                </div>
                              )}
                              {item.name}
                            </div>
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='hidden md:block'>
                    <div className='ml-4 flex items-center md:ml-6'>
                      {/* <button
                        type="button"
                        className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                      {/* Profile dropdown */}
                      <Select
                        customStyles={null}
                        isSearchable={false}
                        options={years}
                        value={selectedYear}
                        onChange={(e) => {
                          setSelectedYear(e.value);
                          updateExamYear(e.data);
                        }}
                      />
                      <Menu as='div' className='relative ml-3'>
                        <div>
                          <Menu.Button className='rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                            <span className='sr-only'>Open user menu</span>
                            <UserCircleIcon
                              className='h-6 w-6'
                              aria-hidden='true'
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='ring-black absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none'>
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <button
                                    // href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block w-full px-4 py-2 text-left text-sm text-gray-700',
                                    )}
                                    onClick={() => {
                                      TokenService.removeTokens();
                                      setUser(null);
                                      navigate('/login');
                                    }}
                                  >
                                    {item.name}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className='-mr-2 flex md:hidden'>
                    {/* Mobile menu button */}
                    <Disclosure.Button className='inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='sr-only'>Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className='block h-6 w-6'
                          aria-hidden='true'
                        />
                      ) : (
                        <Bars3Icon
                          className='block h-6 w-6'
                          aria-hidden='true'
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className='md:hidden'>
                <div className='space-y-1 px-2 pt-2 pb-3 sm:px-3'>
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as='a'
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className='border-t border-gray-700 pt-4 pb-3'>
                  <div className='flex items-center px-5'>
                    <div className='flex-shrink-0'>
                      {/* <img
                        className='h-10 w-10 rounded-full'
                        src={user.imageUrl}
                        alt=''
                      /> */}
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium leading-none text-white'>
                        {user?.name}
                      </div>
                      <div className='text-sm font-medium leading-none text-gray-400'>
                        {user?.name}
                      </div>
                    </div>
                    <button
                      type='button'
                      className='ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                    >
                      <span className='sr-only'>View notifications</span>
                      <BellIcon className='h-6 w-6' aria-hidden='true' />
                    </button>
                  </div>
                  <div className='mt-3 space-y-1 px-2'>
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        className='block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'
                        onClick={() => {
                          TokenService.removeTokens();
                          setUser(null);
                          navigate('/login');
                        }}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className='bg-white shadow'>
          <div className='mx-auto py-6 px-4 sm:px-6 lg:px-8'>
            <h1 className='text-3xl font-bold tracking-tight text-gray-900'>
              {currentPage}
            </h1>
          </div>
        </header>

        <main>
          <div className='mx-auto py-6 sm:px-6 lg:px-8'>
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}
