import { PencilIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../components/forms/Buttons/Button';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import Table from '../../components/Table/Table';
import { getDistricts } from '../../services/catalog/catalog';
import { deleteDistrict } from '../../services/catalog/districts';
import { UserContext } from '../../utils/contexts/UserContext';
import EditDistrict from './EditDistrict';

export default function Districts() {
  const { user } = useContext(UserContext);

  const [districtToEdit, setDistrictToEdit] = useState(null);
  const [districtToDelete] = useState(null);
  // const [districtToDelete, setDistrictToDelete] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [page] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user?.school?.uuid) {
      setLoading(true);
      getDistricts({ school: user?.school?.uuid, page_size: 50 })
        .then((res) => {
          setDistricts(res.results);
          setCount(res.count);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {};
  }, [user]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Район ID',
        accessor: 'district_id',
      },
      {
        Header: 'Название района',
        accessor: 'name',
      },
      {
        Header: 'Экзаменационный центр',
        accessor: 'is_exam_center',
        Cell: (props) => (
          <div className='text-center'>
            {props.value ? (
              <span className='rounded-md bg-green-600 px-2 py-1 text-white'>
                Да
              </span>
            ) : (
              <span className='rounded-md bg-red-600 px-2 py-1 text-white'>
                Нет
              </span>
            )}
          </div>
        ),
      },
      {
        Header: 'Адрес',
        accessor: 'address',
      },
      {
        Header: 'Географическое место',
        accessor: 'is_geographical',
        Cell: (props) => (
          <div className='text-center'>
            {props.value ? (
              <span className=' rounded-md bg-blue-200 px-2 py-1'>Да</span>
            ) : (
              <span>Нет</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Экзам центр заполнен',
        accessor: 'is_full',
        Cell: (props) =>
          props?.row?.original?.is_exam_center && (
            <div className='text-center'>
              {props.value ? (
                <span className='p2-1 p1-2 rounded-md border-2 border-red-600 px-2 py-1 font-bold text-red-600'>
                  Да
                </span>
              ) : (
                <span className='rounded-md border-2 border-green-600 px-2 py-1 font-bold text-green-600'>
                  Нет
                </span>
              )}
            </div>
          ),
      },
      {
        Header: '',
        accessor: 'actions',
        fixed: 'right',
        Cell: (props) => (
          <div className='flex gap-2'>
            <Button
              Icon={PencilIcon}
              onClick={() => {
                setEditModalOpen(true);
                setDistrictToEdit(props.row.original);
              }}
            />
            {/* <Button
              Icon={TrashIcon}
              color='error'
              onClick={() => {
                setDeleteModalOpen(true);
                setDistrictToDelete(props.row.original);
              }}
            /> */}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <div>
      <div className='text-right'>
        <Button
          text='Добавить'
          onClick={() => {
            setEditModalOpen(true);
            setDistrictToEdit(null);
          }}
        />
      </div>

      <Table
        columns={columns}
        data={districts}
        count={count}
        pageNumber={page}
        loading={loading}
        pageCount={1}
      />
      {false && (
        <CustomDialog
          isOpen={deleteModalOpen}
          setIsOpen={setDeleteModalOpen}
          closeModal={() => {
            setDeleteModalOpen(false);
          }}
          headerText={'Удалить'}
        >
          <div className='flex flex-col gap-4'>
            <p>
              Вы уверены, что хотите удалить ученика{' '}
              <b>{districtToDelete?.name}</b>?
            </p>
            <Button
              type='button'
              color='error'
              text='Удалить'
              onClick={() => {
                deleteDistrict(districtToDelete?.uuid).then(() => {
                  getDistricts()
                    .then((res) => {
                      setDistricts(res.results);
                    })
                    .finally(() => {
                      setDeleteModalOpen(false);
                    });
                });
              }}
            />
          </div>
        </CustomDialog>
      )}
      <EditDistrict
        user={user}
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        district={districtToEdit}
        setDistrictToEdit={setDistrictToEdit}
        setDistricts={setDistricts}
      />
    </div>
  );
}
