import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import { getFile } from '../../services/file/fileService';
import useSnackbar from '../../utils/hooks/useSnackbar';
import { getSchoolResultsStatisticsExcel } from '../../services/schoolStatistics/schoolStatistics';

const INTERVAL_TIME = 1000;
const REQUEST_LIMIT = 60;

const ExportExcelExamStats = ({ params }) => {
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();

  const [loadingFile, setLoadingFile] = useState(false);

  const handle = () => {
    setLoadingFile(true);

    let request_counter = 0;

    getSchoolResultsStatisticsExcel(params).then((res) => {
      const intervalId = setInterval(() => {
        if (request_counter >= REQUEST_LIMIT) {
          clearInterval(intervalId);
          addSnackbar(t('examinations.failure'), 'error');
          setLoadingFile(false);
        }

        getFile({ uuid: res?.uuid }).then((res) => {
          if (res?.status === 1) {
            window.location.href = res?.file;
            clearInterval(intervalId);
            setLoadingFile(false);
          }
        });

        request_counter++;
      }, INTERVAL_TIME);
    });
  };

  return (
    <Button
      type='button'
      text='Загрузить'
      color='success'
      Icon={ArrowDownTrayIcon}
      loading={loadingFile}
      onClick={() => handle()}
    />
  );
};

export default ExportExcelExamStats;
