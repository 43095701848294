import React, { useContext, useEffect, useState } from 'react';
import Select from '../../components/forms/Select/Select';
import Table from '../../components/Table/Table';
import { getMockupsStats } from '../../services/exams/mockupsStats';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';

export default function ExaminationStatistics() {
  // const { user } = useContext(UserContext);
  // const navigate = useNavigate();
  const { examYear } = useContext(ExamYearContext);
  const [mockups, setMockups] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(50);
  const [examType, setExamType] = useState(null);

  useEffect(() => {
    getMockupsStats({ page_size: 50, exam_year: examYear?.year })
      .then((res) => {
        setMockups(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / 50));
        setPage(1);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {};
  }, [examYear?.year]);

  const columns = React.useMemo(
    () => [
      {
        Header: '#',
        id: 'index',
        accessor: (_row, i) => i + 1,
        // Cell: (props) => {
        //   return <span>{props.state.pageIndex}</span>;
        // },
      },
      {
        Header: 'ИИН',
        accessor: 'account.iin',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique iins`,
      },
      {
        Header: 'Имя',
        accessor: 'account.first_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique names`,
      },
      {
        Header: 'Фамилия',
        accessor: 'account.last_name',
        aggregate: 'uniqueCount',
        Aggregated: ({ value }) => `${value} Unique surnames`,
      },
      {
        Header: 'Правильные',
        accessor: 'total_correct',
        Cell: (props) => (
          <div className='px-2'>
            {props.value > 0 ? (
              <span className='font-bold text-green-600'>{props.value}</span>
            ) : (
              <span className=''>{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Неправильные',
        accessor: 'total_wrong',
        Cell: (props) => (
          <div className='px-2'>
            {props.value > 0 ? (
              <span className='font-bold text-red-600'>{props.value}</span>
            ) : (
              <span className=''>{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Без ответа',
        accessor: 'total_no_answer',
        Cell: (props) => (
          <div className='px-2'>
            {props.value > 0 ? (
              <span className='font-bold text-gray-500'>{props.value}</span>
            ) : (
              <span className=''>{props.value}</span>
            )}
          </div>
        ),
      },
      {
        Header: 'Общий итог',
        accessor: 'result',
        Cell: (props) => (
          <div className='px-2'>
            <span className='font-bold'>{props.value}</span>
          </div>
        ),
      },
    ],
    [],
  );

  const nextPage = () => {
    setLoading(true);

    getMockupsStats({
      type: examType,
      page: page + 1,
      page_size: pageSize,
      exam_year: examYear?.year,
    })
      .then((res) => {
        setMockups(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v + 1);
  };

  const previousPage = () => {
    setLoading(true);

    getMockupsStats({
      type: examType,
      page: page - 1,
      page_size: pageSize,
      exam_year: examYear?.year,
    })
      .then((res) => {
        setMockups(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  const gotoPage = (pageNum) => {
    setLoading(true);

    getMockupsStats({
      type: examType,
      page: pageNum,
      page_size: pageSize,
      exam_year: examYear?.year,
    })
      .then((res) => {
        setMockups(res.results);
        setCount(res.count);
        setPageCount(Math.ceil(res.count / pageSize));
        setPage(pageNum);
      })
      .finally(() => {
        setLoading(false);
      });
    setPage((v) => v - 1);
  };

  return (
    <div className='flex flex-col gap-2'>
      <div>
        <Select
          className='h-6 w-48'
          placeholder={'Номер экзамена'}
          options={[
            { label: 'Все результаты', value: null },
            { label: 'Пробный-1', value: '0' },
            { label: 'Пробный-2', value: '1' },
          ]}
          // label={'Номер экзамена'}
          value={examType}
          onChange={(e) => {
            setLoading(true);

            setExamType(e.value);
            getMockupsStats({
              type: e.value,
              page: 1,
              page_size: pageSize,
              exam_year: examYear?.year,
            })
              .then((res) => {
                setMockups(res.results);
                setCount(res.count);
                setPageCount(Math.ceil(res.count / pageSize));
                setPage(1);
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        />
      </div>
      <Table
        columns={columns}
        data={mockups}
        count={count}
        pageNumber={page}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        pageCount={pageCount}
        loading={loading}
      />
    </div>
  );
}
