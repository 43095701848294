import axios from 'axios';
import TokenService from './token.service';

const baseURL = process.env.REACT_APP_API_URL;

const examAPI = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng'),
  },
});

examAPI.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

examAPI.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalConfig = error?.response?.config;

    if (error.toJSON().message === 'Network Error') {
      // alert('No internet connection');
      return Promise.reject(error);
    }

    if (
      !['/auth/login', '/core/transfer_students/'].includes(
        originalConfig.url,
      ) &&
      error?.response
    ) {
      if (error?.response?.status === 401) {
        try {
          const rs = await axios.post(baseURL + '/auth/refresh/', {
            refresh: TokenService.getLocalRefreshToken(),
          });
          const token = rs.data.access;
          TokenService.updateLocalAccessToken(token);
          return examAPI(originalConfig);
        } catch (error) {
          TokenService.removeTokens();
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
  },
);

export default examAPI;
