import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import { getSchoolPassScoreStats } from '../../services/schoolStatistics/schoolStatistics';

const SchoolPassScoreStatistics = () => {
  const [schoolStatistics, setSchoolStatistics] = useState([]);

  useEffect(() => {
    getSchoolPassScoreStats({ page_size: 50 }).then((res) => {
      setSchoolStatistics(res);
    });

    return () => {};
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'school_id',
      },
      {
        Header: 'Название',
        accessor: 'name',
      },
      {
        Header: 'Pass score 1 round',
        accessor: 'first_round_pass_scores.pass_score_kz',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },
      {
        Header: 'Pass score 1 round asp',
        accessor: 'first_round_pass_scores.pass_score_asp_kz',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },
      {
        Header: 'Pass score 2 round',
        accessor: 'second_round_pass_scores.pass_score_kz',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },

      {
        Header: 'Pass score 2 round asp',
        accessor: 'second_round_pass_scores.pass_score_asp_kz',
        Cell: (props) => <div className='text-center'>{props.value}</div>,
      },
    ],
    [],
  );

  return (
    <div className='flexflex-col gap-4'>
      <Table columns={columns} data={schoolStatistics} isSticky />
    </div>
  );
};

export default SchoolPassScoreStatistics;
