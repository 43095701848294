import React, { useCallback, useEffect, useState } from 'react';
import {
  getExtraAllowedStudents,
  patchExtraAllowedStudents,
} from '../../services/ExtraAllowedStudents/ExtraAllowedStudentsService';
import Edit from './BREAD/Edit';
import useSnackbar from '../../utils/hooks/useSnackbar';
import Add from './BREAD/Add';
import SimpleLoader from '../../components/Loader/SimpleLoader';

const ExtraStudentsSetting = () => {
  const { addSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [extraStudentsSettings, setExtraStudentsSettings] = useState([]);

  const onSave = async (uuid, body) => {
    await patchExtraAllowedStudents(uuid, body).then((res) => {
      const updatedExtraStudentsSettings = [...extraStudentsSettings];
      const requestToUpdate = updatedExtraStudentsSettings.find(
        (r) => r.uuid === uuid,
      );
      requestToUpdate.status = res.status;
      setExtraStudentsSettings(updatedExtraStudentsSettings);
      addSnackbar('Успешно', 'success');
    });
  };

  const getExtraAllowedStudentsData = useCallback(() => {
    setIsLoading(true);
    getExtraAllowedStudents()
      .then((res) => {
        setExtraStudentsSettings(res.results);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getExtraAllowedStudentsData();
  }, [getExtraAllowedStudentsData]);

  return (
    <div className='flex flex-col'>
      {isLoading ? (
        <SimpleLoader className='h-10' />
      ) : (
        <>
          <div className='flex flex-row justify-end'>
            <Add
              data={extraStudentsSettings}
              updateData={() => getExtraAllowedStudentsData()}
            />
          </div>
          <div className='flex flex-row items-center justify-between border-b py-2 px-20'>
            <div className='flex flex-row gap-2'>
              <span className='w-8'>{'id'}</span>
              <span>{'name'}</span>
            </div>
            <div></div>
          </div>
          {extraStudentsSettings.map((setting) => {
            return (
              <div
                key={setting.uuid}
                className='flex flex-row items-center justify-between border-b py-2 px-20'
              >
                <div className='flex flex-row gap-2'>
                  <span className='w-8'>{setting.school.school_id}</span>
                  <span>{setting.school.name}</span>
                </div>
                <div>
                  <Edit obj={setting} onSave={onSave} />
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ExtraStudentsSetting;
