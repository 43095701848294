import React, { useEffect, useRef, useState } from 'react';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import Select from '../../../components/forms/Select/Select';
import { Button } from '../../../components/forms/Buttons/Button';
import { ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import { uploadResultsExcel } from '../../../services/results/resultsService';

export default function UploadResultsAdmin() {
  const { addSnackbar } = useSnackbar();

  const [uploads, setUploads] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [leg, setLeg] = useState(null);
  const [isAsp, setIsAsp] = useState(null);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [fileStatus, setFileStatus] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    return () => {
      setFile(null);
      setFileError(null);
    };
  }, []);

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-col items-center gap-2 md:flex-row'>
        <input
          className='border'
          type='file'
          accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          ref={inputRef}
          onChange={(e, d) => {
            setFile(e.target.files[0]);
            // e.target.value = null;
          }}
        />
        <Select
          className='z-20'
          value={leg}
          isSearchable={false}
          options={[1, 2, 3, 4].map((v, i) => ({
            label: `${v} тур`,
            value: `${i}`,
          }))}
          placeholder='Тур'
          onChange={(e) => {
            setLeg(e.value);
          }}
        />
        <Select
          className='z-20'
          value={isAsp}
          isSearchable={false}
          options={[
            {
              label: 'ӘОТ',
              value: true,
            },
            {
              label: 'ӘОТ емес',
              value: false,
            },
          ]}
          placeholder='ӘОТ статус'
          onChange={(e) => {
            setIsAsp(e.value);
          }}
        />
        <Button
          Icon={ArrowUpTrayIcon}
          color='primary'
          className='h-9'
          text='Файлды жіберу'
          loading={loading}
          onClick={() => {
            if (isAsp !== null && leg !== null && file) {
              setLoading(true);
              uploadResultsExcel(file, leg, isAsp)
                .then((res) => {
                  setUploads(res?.comments);
                  setCount(res.comments.length);
                  setFileError(res?.has_errors);
                  setFileStatus(res?.file_status);
                })
                // .catch(() => {
                //   addSnackbar(t('Ошибка'), 'error');
                // })
                .finally(() => {
                  setLoading(false);
                  setFile(null);
                  inputRef.current.value = null;
                  setLeg(null);
                  setIsAsp(null);
                });
            }

            if (file === null) {
              addSnackbar('Файлды таңдаңыз', 'error');
            } else if (isAsp === null || leg === null) {
              addSnackbar('Турды / ӘОТ таңдаңыз', 'error');
            }
          }}
        />
      </div>
      <div className='flex flex-row justify-between gap-2'>
        <div>
          {fileStatus === 0 && (
            <span className='font-semibold text-red-600'>Файл жүктелмеді</span>
          )}
          {fileStatus === 1 && (
            <span className='font-semibold text-green-600'>Файл жүктелді</span>
          )}
        </div>
        <div>
          <div>
            Саны:<span className='font-bold'>{' ' + count}</span>
          </div>
          <div>
            {fileError === true && (
              <div>
                Қате: <span className='font-semibold text-red-600'>Иә</span>
              </div>
            )}
            {fileError === false && (
              <div>
                Қате: <span className='font-semibold text-green-600'>Жоқ</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <div className='h-8 text-center'>
            <SimpleLoader />
          </div>
        ) : (
          <table className='w-full table-auto'>
            <thead className='sticky top-0 z-10 border-b bg-sheet'>
              <tr>
                <td className='py-2 text-center font-medium uppercase'>#</td>
                <td className='py-2 pl-4 font-medium uppercase'>
                  Емтихан тапсырған мектебі
                </td>
                <td className='py-2 pl-4 font-medium uppercase'>Мектеп</td>
                <td className='py-2 pl-4 font-medium uppercase'>ЖСН</td>
                <td className='py-2 pl-4 font-medium uppercase'>Аты</td>
                <td className='py-2 pl-4 font-medium uppercase'>Жөні</td>
                <td className='py-2 text-center font-medium uppercase'>
                  Вариант
                </td>
                <td className='py-2 text-center font-medium uppercase'>
                  Дұрыс
                </td>
                <td className='py-2 text-center font-medium uppercase'>Қате</td>
                <td className='py-2 text-center font-medium uppercase'>Ұпай</td>
                <td className='py-2 text-center font-medium uppercase'>
                  Статус
                </td>
                <td className='py-2 pl-4 font-medium uppercase'>Тілі</td>
                <td className='py-2 pl-4 font-medium uppercase'>ӘОТ</td>
                <td className='py-2 pl-4 font-medium uppercase'></td>
              </tr>
            </thead>
            <tbody>
              {uploads.map((student) => (
                <tr
                  key={student?.index}
                  className={`border-b last:border-b-0 hover:bg-sheet-gray ${
                    student?.index % 2 === 0 && 'bg-white'
                  }`}
                >
                  <td className='border-l border-r px-2 text-center'>
                    {student?.index + 1}
                  </td>
                  <td className='border-r pl-4'>{student?.from_school}</td>
                  <td className='border-r pl-4'>{student?.school}</td>
                  <td className='border-r pl-4'>{student?.iin}</td>
                  <td className='border-r pl-4'>{student?.name}</td>
                  <td className='border-r pl-4'>{student?.surname}</td>
                  <td className='border-r text-center'>{student?.variant}</td>
                  <td className='border-r bg-green-100 text-center text-green-800'>
                    {student?.correct}
                  </td>
                  <td className='border-r bg-red-100 text-center text-red-700'>
                    {student?.wrong}
                  </td>
                  <td className='border-r bg-blue-100 text-center'>
                    {student?.total}
                  </td>
                  <td
                    className={`border-r text-center ${
                      student?.status === 'passed'
                        ? 'font-semibold text-green-600'
                        : student?.status === 'reserve'
                        ? ''
                        : 'font-semibold text-red-600'
                    }`}
                  >
                    {student?.status}
                  </td>
                  <td className='border-r text-center'>{student?.lang}</td>
                  <td className='border-r text-center'>
                    {student.is_asp === true && <span>Иә</span>}
                    {student.is_asp === false && <span>Жоқ</span>}
                  </td>

                  <td className='max-w-[20vw] border-r text-center'>
                    {student?.error ? (
                      <span className=' text-red-600'>{student?.message}</span>
                    ) : (
                      <span className=' text-green-600'>OK</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
