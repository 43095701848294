import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { uploadDistrictExcel } from '../../../services/catalog/districts';

export default function UploadDistrict() {
  const [uploads, setUploads] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  useEffect(() => {
    return () => {
      setFile(null);
      setFileError(null);
      setUploadStatus(null);
    };
  }, []);

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-row items-center gap-2'>
        <div>
          {/* <label
            class='mb-2 block text-sm font-medium text-gray-900'
            for='file_input'
          >
            Upload file
          </label> */}
          <input
            className='block w-full rounded-md border border-gray-200 text-sm shadow-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500'
            type='file'
            onChange={(e, d) => {
              setFile(e.target.files[0]);
            }}
          />
        </div>
        <Button
          text='Отправить файл'
          loading={loading}
          color='primary'
          onClick={() => {
            setLoading(true);
            uploadDistrictExcel(file)
              .then((res) => {
                setUploads(res?.comments);
                setCount(res.comments.length);
                setFileError(res?.has_errors);
                setUploadStatus(res?.file_status);
              })
              // .catch(() => {
              //   addSnackbar(t('Ошибка'), 'error');
              // })
              .finally(() => {
                setLoading(false);
              });
          }}
        />
      </div>
      <div className='flex flex-row justify-between gap-2'>
        <div className='flex flex-row gap-2'>
          <div>
            {fileError === true && (
              <span className='font-semibold text-red-600'>Есть ошибки</span>
            )}
            {fileError === false && (
              <span className='font-semibold text-green-600'>Ошибок нет</span>
            )}
          </div>
          <div>
            {uploadStatus === 0 && (
              <span className='font-semibold text-red-600'>
                Файл не загружен
              </span>
            )}
            {uploadStatus === 1 && (
              <span className='font-semibold text-green-600'>
                Все кандидаты успешно загружены
              </span>
            )}
          </div>
        </div>
        <div>
          Количество записей:<span className='font-bold'>{' ' + count}</span>
        </div>
      </div>
      <div>
        <table className='w-full table-auto'>
          <thead className='sticky top-0 z-10 border-b bg-sheet'>
            <tr>
              <td className='py-2 text-center font-medium uppercase'>#</td>
              <td className='py-2 pl-4 font-medium uppercase'>ID</td>
              <td className='py-2 pl-4 font-medium uppercase'>Имя</td>
              <td className='py-2 pl-4 font-medium uppercase'>Фамилия</td>
              <td className='py-2 pl-4 font-medium uppercase'>ИИН</td>
              <td className='py-2 text-center font-medium uppercase'>Группа</td>
              <td className='py-2 text-center font-medium uppercase'>Пол</td>
              <td className='py-2 text-center font-medium uppercase'>
                Регион ID
              </td>
              {/* <td className='py-2 text-center font-medium uppercase'>Школа</td> */}
              <td className='py-2 text-center font-medium uppercase'>Cтатус</td>
            </tr>
          </thead>
          <tbody>
            {uploads.map((student) => (
              <tr
                key={student?.index}
                className={`border-b last:border-b-0 hover:bg-slate-100 ${
                  student?.error ? 'bg-red-100' : 'bg-green-100'
                }`}
              >
                <td className='border-l border-r px-2 text-center'>
                  {student?.index + 1}
                </td>
                <td className='border-r pl-4'>{student?.account_id}</td>
                <td className='border-r pl-4'>{student?.name}</td>
                <td className='border-r pl-4'>{student?.surname}</td>
                <td className='border-r pl-4'>{student?.iin}</td>
                <td className='border-r text-center'>
                  {student?.lang_group === 'kaz' ? 'Қазақ' : 'Русская'}
                </td>
                <td className='border-r text-center'>
                  {student?.gender === 'K' && 'Жен'}
                  {student?.gender === 'E' && 'Муж'}
                </td>
                <td className='border-r pl-4 text-center'>
                  {student?.region_id}
                </td>

                <td className='border-r text-center'>
                  {student?.error ? (
                    <span className=''>{student?.message}</span>
                  ) : (
                    <span className=''>OK</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
