import React, { useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import { CheckIcon } from '@heroicons/react/24/solid';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Input from '../../../components/forms/Input/Input';

const Edit = ({ obj, onSave }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [studentsCount, setStudentsCount] = useState(obj.students_count);

  return (
    <div className='flex flex-row'>
      <Input
        value={studentsCount}
        onChange={(e) => setStudentsCount(e.target.value)}
        type='number'
        min='0'
        className='mr-2 w-32'
        disabled={!isEdit}
      />
      <Button
        Icon={isEdit ? XMarkIcon : PencilIcon}
        className={isEdit && `rounded-r-none`}
        color={isEdit ? 'primary-solid' : 'primary-solid'}
        onClick={() => {
          setStudentsCount(obj.students_count);
          setIsEdit((v) => !v);
        }}
      />

      <Button
        Icon={CheckIcon}
        className={`${!isEdit && 'invisible'} rounded-l-none `}
        color='success'
        onClick={() => {
          onSave(obj.uuid, { students_count: studentsCount });
          setIsEdit(false);
        }}
      />
    </div>
  );
};

export default Edit;
