import React, { useEffect, useState } from 'react';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import useSnackbar from '../../utils/hooks/useSnackbar';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../components/forms/Select/Select';
import Input from '../../components/forms/Input/Input';
import { Button } from '../../components/forms/Buttons/Button';
import { getDistricts } from '../../services/catalog/catalog';
import { getExamDates } from '../../services/core/coreService';
import { approveTransferStudent } from '../../services/transferStudents/transferBetweenSchoolsService';

export default function AcceptStudentModal({
  modalOpen,
  setModalOpen,
  transfer,
  // setStudentToAccept,
  updateList,
  examYear,
}) {
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  // const [applicant, setApplicant] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [examCenters, setExamCenters] = useState([]);
  const [examDates, setExamDates] = useState([]);

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (examYear?.uuid && transfer?.to_school?.uuid) {
      getDistricts({ school: transfer?.to_school?.uuid }).then((res) => {
        const options = res.results
          .filter((dist) => dist?.is_geographical)
          .map((item) => ({
            label: item.name,
            value: item.uuid,
          }));
        setDistricts(options);

        const centers = res.results
          .filter((dist) => dist?.is_exam_center)
          .map((item) => ({
            label:
              item.name +
              (item?.address ? ' - ' + item.address : '') +
              (item?.is_full ? ' - Центр заполнен!' : ''),
            value: item.uuid,
          }));
        setExamCenters(centers);
      });

      getExamDates(examYear?.uuid).then((res) => {
        var options = res?.results?.map((date) => ({
          label: date.name,
          value: date.uuid,
        }));
        setExamDates(options);
      });
    }

    return () => {
      setExamDates([]);
    };
  }, [examYear, transfer]);

  const onSubmit = (data) => {
    setLoading(true);
    const body = {
      exam_center: data.examCenter,
      district: transfer?.application?.district?.uuid,
      classroom: data.room,
      seat: data.place,
      exam: data.exam,
    };
    approveTransferStudent(transfer?.uuid, body)
      .then(() => {
        addSnackbar('Успешно', 'success');
        updateList();
      })
      .finally(() => {
        setLoading(false);
        setModalOpen(false);
      });
  };

  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Трансфер'}
    >
      <div className=''>
        <div className='border-b px-4 py-4'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Указать данные экзамена #1
          </h3>
        </div>
        <div className='mt-10 sm:mt-0'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='mt-5 md:col-span-3 md:mt-0'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='shadow sm:rounded-md'>
                  <div className='bg-white px-4 py-5 sm:p-6'>
                    <div className='grid grid-cols-6 gap-6'>
                      <Controller
                        control={control}
                        name='examCenter'
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            label={'Экзаменационный центр'}
                            required
                            errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                            className='col-span-6'
                            value={field.value}
                            options={examCenters}
                            // placeholder={t('announcement.select_positions')}
                            onChange={(e) => {
                              // setSelectedDistrict(e);
                              field.onChange(e.value);
                            }}
                          />
                        )}
                      />
                      {false && (
                        <Controller
                          control={control}
                          name='district'
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              label={'Район'}
                              required
                              errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                              className='col-span-6'
                              value={field.value}
                              options={districts}
                              // placeholder={t('announcement.select_positions')}
                              onChange={(e) => {
                                // setSelectedDistrict(e);
                                field.onChange(e.value);
                              }}
                            />
                          )}
                        />
                      )}

                      <Input
                        type='text'
                        className='col-span-6 sm:col-span-3'
                        label='Аудитория'
                        required
                        errorMsg={
                          errors?.parentLastName?.type
                            ? t('signup.enter_parent_surname')
                            : ''
                        }
                        {...register('room', {
                          required: true,
                        })}
                      />
                      <Input
                        type='text'
                        className='col-span-6 sm:col-span-3'
                        label='Место'
                        required
                        errorMsg={
                          errors?.parentLastName?.type
                            ? t('signup.enter_parent_surname')
                            : ''
                        }
                        {...register('place', {
                          required: true,
                        })}
                      />
                      <Controller
                        control={control}
                        name='exam'
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            label={'Выберите дату'}
                            required
                            errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                            className='col-span-6 sm:col-span-4'
                            value={field.value}
                            options={examDates}
                            // placeholder={t('announcement.select_positions')}
                            onChange={(e) => {
                              // setSelectedDistrict(e);
                              field.onChange(e.value);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className='bg-gray-50 px-4 py-2 text-right sm:px-6'>
                    <Button
                      loading={loading}
                      type='submit'
                      text='Принять ученика'
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </CustomDialog>
  );
}
