import bilAPI from '../api';

export const getSchools = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/schools/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getDistricts = (params) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/district/', { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const getRegions = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/regions/')
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
