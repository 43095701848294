import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import KazakhstanMap from './KazakhstanMap';
import SchoolCardsByRegion from './SchoolCardsByRegion';

const MapSection = () => {
  const [t] = useTranslation();
  const [selectedRegion, setSelectedRegion] = useState('Astana');
  return (
    <div className='flex w-screen  flex-col bg-white py-16 '>
      <div className='my-8 flex flex-col items-center justify-center -space-y-24 text-center sm:px-3 sm:text-left md:space-y-0'>
        <div>
          <p className='text-2xl md:text-5xl'> {t('welcome.choice_school')}</p>
          <p className='text-2xl md:text-5xl'>
            {' '}
            {t('welcome.school_on_all_kz')}
          </p>
        </div>
        <KazakhstanMap
          className='flex w-full flex-row justify-center px-8 md:mt-16'
          region={selectedRegion}
          setRegion={setSelectedRegion}
        />
        <div className='flex flex-col items-center lg:px-32'>
          <SchoolCardsByRegion region={selectedRegion} t={t} />
        </div>
      </div>
    </div>
  );
};

export default MapSection;
