import bilAPI from '../api';

export const getQuestions = (exam) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exam/questions/`, { params: { exam, page_size: 200 } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getQuestionById = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exam/questions/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postQuestion = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/exam/questions/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchQuestion = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/exam/questions/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteQuestion = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/exam/questions/${uuid}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};
