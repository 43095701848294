import examAPI from '../examAPI';

export const postTransferStudentsToBilOrg = (body) => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .post(`/core/transfer_students/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
