import React, { useEffect, useRef } from 'react';
// import bil_4_girls from './images/bil_4_girls.png';
import bil_logo_white from './images/bil_logo_white.png';
import bil_logo_white_ru from './images/bil_logo_white_ru.png';
import { useTranslation } from 'react-i18next';
import MainSection from './MainSection/MainSection';
import MapSection from './MapSection/MapSection';
import HowToEnrollSection from './HowToEnrollSection/HowToEnrollSection';
import ExamResultSection from './ExamResultSection/ExamResultSection';
import TutorialSection from './TutorialsSection/TutotialSection';
import FAQSection from './FAQSection/FAQSection';
import ContactsSection from './ContactsSection/ContactsSection';

const LandingV2 = () => {
  const [, i18n] = useTranslation();
  const landingPageRef = useRef();

  useEffect(() => {
    if (!['kk', 'ru'].includes(i18n.language)) {
      i18n.changeLanguage('kk');
    }
  }, [i18n]);
  return (
    <div
      className='relative snap-y snap-mandatory overflow-x-hidden font-sfpro'
      ref={landingPageRef}
    >
      <MainSection />
      {/*OlympiadSection in AlumniSection*/}
      {/* <AlumniSection /> */}
      {/* ThirtyYearsSection in AlumniSection */}
      {/* <SuccessSecretSection /> */}

      {/* <AboutExam examAboutRef={examAboutRef} /> */}
      <MapSection />

      <HowToEnrollSection />
      <ExamResultSection />
      <TutorialSection />
      <FAQSection />
      <ContactsSection />
      {/* --------- Footer ------------ */}
      <footer className='flex w-screen flex-row items-center justify-around bg-black-landing py-5 px-5 '>
        <img
          className='h-8  w-32 sm:h-16 sm:w-64 '
          src={i18n.language === 'ru' ? bil_logo_white_ru : bil_logo_white}
          alt='bil logo'
        />
        <p className='text-xs text-white sm:text-base'>© 2024 Bilemtihan.kz</p>
      </footer>
      {/* --------- Footer ------------ */}

      {/*Whatsapp icon for questions */}
      <a href='https://api.whatsapp.com/send?phone=77003323232&text=%D0%A1%D3%99%D0%BB%D0%B5%D0%BC%D0%B5%D1%82%D1%81%D1%96%D0%B7%20%D0%B1%D0%B5!%20%D0%91%D0%98%D0%9B%20%D2%9B%D0%B0%D0%B1%D1%8B%D0%BB%D0%B4%D0%B0%D1%83%20%D0%B5%D0%BC%D1%82%D0%B8%D1%85%D0%B0%D0%BD%D1%8B%D0%BD%D0%B0%20%D0%B1%D0%B0%D0%B9%D0%BB%D0%B0%D0%BD%D1%8B%D1%81%D1%82%D1%8B%20%D1%81%D2%B1%D1%80%D0%B0%D2%93%D1%8B%D0%BC%20%D0%B1%D0%B0%D1%80%20%D0%B5%D0%B4%D1%96.%0A%0A%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%95%D1%81%D1%82%D1%8C%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83%20%D0%B2%D1%81%D1%82%D1%83%D0%BF%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D1%8D%D0%BA%D0%B7%D0%B0%D0%BC%D0%B5%D0%BD%D0%BE%D0%B2%20%D0%B2%20%D0%91%D0%98%D0%9B.'>
        <div className='fixed right-5 bottom-5 z-50 flex h-[3.125rem] w-[3.125rem] flex-row items-center justify-center  rounded-full bg-green-700 lg:bottom-10 lg:right-10'>
          <svg
            className='h-8 fill-white'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 448 512'
          >
            <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
          </svg>
        </div>
      </a>
      {/*Whatsapp icon for questions */}
    </div>
  );
};

export default LandingV2;
