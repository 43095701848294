import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../components/forms/Buttons/Button';
import Input from '../../components/forms/Input/Input';
import Select from '../../components/forms/Select/Select';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { getDistricts, getSchools } from '../../services/catalog/catalog';
import { patchDistrict, postDistrict } from '../../services/catalog/districts';
import useSnackbar from '../../utils/hooks/useSnackbar';

export default function EditDistrict({
  user,
  editModalOpen,
  setEditModalOpen,
  district,
  setDistrictToEdit,
  setDistricts,
}) {
  const { addSnackbar } = useSnackbar();

  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    // watch,
  } = useForm();

  useEffect(() => {
    getSchools({ page_size: 50 }).then((res) => {
      const options = res.results.map((school) => ({
        label: school.name,
        value: school.uuid,
      }));

      setSchools(options);
    });

    return () => {};
  }, []);

  useEffect(() => {
    setValue('school', user?.school?.uuid);
    if (district?.uuid) {
      setValue('isExamCenter', district?.is_exam_center);
      setValue('name', district?.name);
      setValue('districtId', district?.district_id);
      setValue('isGeographical', district?.is_geographical);
      setValue('isFull', district?.is_full);
      setValue('address', district?.address);
    }

    return () => {
      reset();
    };
  }, [district, user, setValue, reset]);

  const onSubmit = async (data) => {
    setLoading(true);
    const body = {
      school: data.school,
      is_exam_center: data.isExamCenter,
      name: data.name,
      district_id: data.districtId,
      is_geographical: data.isGeographical,
      is_full: data.isFull,
      address: data.address,
    };

    if (district?.uuid) {
      await patchDistrict(district.uuid, body)
        .then(() => {
          getDistricts().then((res) => {
            setDistricts(res.results);
          });
          setEditModalOpen(false);
          addSnackbar('Успешно', 'success');
        })
        .catch(() => {
          addSnackbar('Ошибка', 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await postDistrict(body)
        .then(() => {
          getDistricts().then((res) => {
            setDistricts(res.results);
          });
          setEditModalOpen(false);
          addSnackbar('Успешно', 'success');
        })
        .catch(() => {
          addSnackbar('Ошибка', 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <CustomDialog
      isOpen={editModalOpen}
      setIsOpen={setEditModalOpen}
      closeModal={() => {
        setEditModalOpen(false);
      }}
      headerText={'Изменить'}
    >
      <form
        className='bg-white px-4 py-5 sm:p-6'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='grid grid-cols-6 gap-6'>
          <Controller
            control={control}
            name='school'
            render={({ field }) => (
              <Select
                label={'Школа'}
                isDisabled
                errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                className='col-span-6 sm:col-span-6'
                value={field.value}
                options={schools}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name='isExamCenter'
            render={({ field }) => (
              <Select
                label={'Выберите экзаменационный центр'}
                errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                className='col-span-6 sm:col-span-6'
                value={field.value}
                isSearchable={false}
                options={[
                  {
                    label: 'Да',
                    value: true,
                  },
                  {
                    label: 'Нет',
                    value: false,
                  },
                ]}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                }}
              />
            )}
          />

          <Input
            type='text'
            className='col-span-6'
            label='Название района'
            // errorMsg={
            //   errors?.parentLastName?.type
            //     ? t('signup.enter_parent_surname')
            //     : ''
            // }
            {...register('name', {})}
          />
          <Input
            type='text'
            className='col-span-6'
            label='ID района'
            errorMsg={
              errors?.districtId?.type ? 'ID должен состоять из 2 цифр' : ''
            }
            {...register('districtId', {
              minLength: 2,
              maxLength: 2,
            })}
          />
          <Controller
            control={control}
            name='isGeographical'
            render={({ field }) => (
              <Select
                label={'Это географическое место?'}
                errorMsg={errors?.isGeographical?.type ? 'Ошибка' : ''}
                className='col-span-6 sm:col-span-6'
                value={field.value}
                isSearchable={false}
                options={[
                  {
                    label: 'Да',
                    value: true,
                  },
                  {
                    label: 'Нет',
                    value: false,
                  },
                ]}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name='isFull'
            render={({ field }) => (
              <Select
                label={'Экзаменационный центр заполнен?'}
                errorMsg={errors?.isFull?.type ? 'Ошибка' : ''}
                className='col-span-6 sm:col-span-6'
                value={field.value}
                isSearchable={false}
                options={[
                  {
                    label: 'Да',
                    value: true,
                  },
                  {
                    label: 'Нет',
                    value: false,
                  },
                ]}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                }}
              />
            )}
          />
          <Input
            type='text'
            className='col-span-6'
            label='Адрес'
            errorMsg={errors?.address?.type ? 'Ошибка' : ''}
            {...register('address')}
          />
        </div>
        <Button
          loading={loading}
          className='mt-4 w-full'
          text={district?.uuid ? 'Обновить' : 'Создать'}
        />
      </form>
    </CustomDialog>
  );
}
