import bilAPI from '../api';

export const getExams = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exam/exams/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const getExamById = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/exam/exams/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postExam = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/exam/exams/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchExam = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/exam/exams/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteExam = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/exam/exams/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error');
        });
    } catch (error) {
      reject('system error');
    }
  });
};

export const postExamText = (body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/exam/exam_texts/', body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const patchExamText = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/exam/exam_texts/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
