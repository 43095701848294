import React, { useState } from 'react';
import { QUESTIONS_TYPES } from '../../utils/constants/constants';
import Edit from './BREAD/Edit';
import Delete from './BREAD/Delete';

const QuestionCard = ({ question, index, getData }) => {
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  return (
    <div className=' flex h-36 w-56 flex-col  rounded-md border-2  p-4'>
      <p className='flex flex-row justify-center  text-lg font-bold'>
        {index + 1} вопрос
      </p>
      <p className='flex flex-row items-center justify-center gap-1'>
        <span>Правильный ответ</span>
        <span className='text-lg font-bold'>{question?.correct_answer}</span>
      </p>
      <p className='text-sm'>{QUESTIONS_TYPES[question?.type]}</p>
      <div className='mt-1 flex flex-row justify-center'>
        {!isDeleteMode && (
          <Edit
            question={question}
            questionId={index + 1}
            getData={getData}
            isDeleteMode={isDeleteMode}
          />
        )}
        <Delete
          questionUUID={question?.uuid}
          questionId={index + 1}
          getData={getData}
          isDeleteMode={isDeleteMode}
          setIsDeleteMode={setIsDeleteMode}
        />
      </div>
    </div>
  );
};

export default QuestionCard;
