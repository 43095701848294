import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSnackbar from '../../utils/hooks/useSnackbar';

const Timer = ({ startTime, isInProgress, duration, end }) => {
  const { addSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const calculateTimeLeft = () => {
    const difference = +new Date() - +new Date(startTime);
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        minutes: duration - 1 - Math.floor(difference / 1000 / 60),
        seconds: (60 - Math.floor((difference / 1000) % 60)) % 60,
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    if (isInProgress && timeLeft.minutes === 0 && timeLeft.seconds === 1) {
      end();
    }

    if (isInProgress && timeLeft.minutes <= -1) {
      end();
    }

    if (isInProgress && timeLeft.minutes === 1 && timeLeft.seconds === 1) {
      addSnackbar(t('mockup.timeleft_1_min'), 'warning', 20000);
    }

    if (isInProgress && timeLeft.minutes === 5 && timeLeft.seconds === 1) {
      addSnackbar(t('mockup.timeleft_5_min'), 'warning', 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);
  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </span>,
    );
  });
  return (
    <div>
      {isInProgress && timeLeft.minutes >= 0 ? (
        <h4 className='text-center sm:text-left'>
          {`${t('mockup.timeleft')}:`}
          <br /> {timerComponents}
        </h4>
      ) : (
        <h4 className='text-center sm:text-left'>{t('mockup.time_is_up')}</h4>
      )}
    </div>
  );
};

export default Timer;
