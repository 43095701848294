import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function MainInfo({ profile }) {
  const [t] = useTranslation();

  return (
    <div className='flex flex-col-reverse md:flex-row md:justify-between'>
      <dl className='my-4 w-full border md:w-2/3'>
        <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('student.admission_school')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {profile?.school?.name}
          </dd>
        </div>
        <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('signup.iin')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {profile?.iin}
          </dd>
        </div>
        <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('signup.name')} / {t('signup.surname')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {profile?.first_name + ' ' + profile?.last_name}
          </dd>
        </div>
        <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('signup.birth_date')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {moment(profile?.birth_date).format('DD.MM.yyyy')}
          </dd>
        </div>
        <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('signup.gender')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {profile?.gender === 'M' ? 'Мужской' : 'Женский'}
          </dd>
        </div>
        <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('signup.study_lang')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {profile?.language === 'K' ? 'Қазақша' : 'Русский'}
          </dd>
        </div>
        <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
          <dt className='text-sm font-medium text-gray-500'>
            {t('student.testing_center')}
          </dt>
          <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
            {profile?.exam_center?.name}
          </dd>
        </div>
      </dl>
      {profile?.avatar_photo ? (
        <div className='flex justify-center py-4 md:h-72 md:w-1/3'>
          <img src={profile?.avatar_photo} alt='avatar_phot' />
        </div>
      ) : (
        <div className='flex h-72 items-center justify-center'>loading</div>
      )}
    </div>
  );
}
