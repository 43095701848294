import examAPI from '../examAPI';

export const postResult = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      examAPI
        .post(`/exam/complete_exam/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
