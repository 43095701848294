import { Disclosure, Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Bars3Icon,
  LanguageIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../utils/helpers/helpers';
import TokenService from '../../../services/token.service';
import { setDefaultLocale } from 'react-datepicker';

export const languages = [
  {
    key: 'kk',
    label: '🇰🇿 Қазақша',
    value: 'kk',
    flag: 'kz',
  },
  {
    key: 'ru',
    label: '🇷🇺 Русский',
    value: 'ru',
    flag: 'ru',
  },
];

export default function StudentNavBar({ user, setUser }) {
  const [t, i18n] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [navigation, setNavigation] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [userNavigation] = useState([{ name: t('buttons.logout'), href: '#' }]);

  useEffect(() => {
    if (location?.pathname.includes('/student')) {
      setNavigation([
        { name: t('Профиль'), href: '/student/profile' },
        { name: t('welcome.rules'), href: '/student/rules' },
        { name: t('mockup.online_tests'), href: '/student/exams' },
        { name: t('results.my_results'), href: '/student/my_results' },
        { name: t('results.results'), href: '/student/results' },
      ]);
    }

    return () => {};
  }, [location, t, user]);

  useEffect(() => {
    if (navigation.length > 1) {
      navigation.forEach((item) => {
        if (location?.pathname?.includes(item?.href)) {
          setCurrentPage(item.name);
        }
      });
    }

    return () => {};
  }, [location, navigation]);

  function handleLanguageChange(lang) {
    // console.log('event', event)
    i18n.changeLanguage(lang);
    setDefaultLocale(lang);
    // window.location.reload();
  }

  return (
    <>
      <div className='min-h-full bg-sheet'>
        <Disclosure as='nav' className='bg-gray-800'>
          {({ open }) => (
            <>
              <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <div className='flex h-16 items-center justify-between'>
                  <div className='flex items-center'>
                    {/* <div className='flex-shrink-0'> */}
                    {/* <img src={logo} alt='logo' width='32' height='32' /> */}
                    {/* </div> */}
                    <div className='hidden md:block'>
                      <div className=' flex items-baseline space-x-4'>
                        {navigation.map((item, i) => (
                          <NavLink
                            key={i}
                            to={item.href}
                            onClick={() => {
                              setCurrentPage(item?.name);
                            }}
                            className={({ isActive }) =>
                              isActive
                                ? 'rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white'
                                : 'rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white'
                            }
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='hidden md:block'>
                    <div className='ml-4 flex items-center md:ml-6'>
                      <Menu as='div' className='relative ml-3'>
                        <div>
                          <Menu.Button className='rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                            <span className='sr-only'>Open user menu</span>
                            <LanguageIcon
                              className='h-6 w-6'
                              aria-hidden='true'
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='ring-black absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none'>
                            {languages.map((item) => (
                              <Menu.Item key={item.value}>
                                {({ active }) => (
                                  <button
                                    // href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-200' : '',
                                      'block w-full px-4 py-2 text-left text-sm text-gray-700',
                                    )}
                                    onClick={() => {
                                      handleLanguageChange(item.value);
                                    }}
                                  >
                                    {item.label}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>

                      {/* Profile dropdown */}
                      <Menu as='div' className='relative ml-3'>
                        <div>
                          <Menu.Button className='rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                            <span className='sr-only'>Open user menu</span>
                            <UserCircleIcon
                              className='h-6 w-6'
                              aria-hidden='true'
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='ring-black absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-opacity-5 focus:outline-none'>
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <button
                                    // href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block w-full px-4 py-2 text-left text-sm text-gray-700',
                                    )}
                                    onClick={() => {
                                      localStorage.clear();
                                      TokenService.removeTokens();
                                      setUser(null);
                                      navigate('/login');
                                    }}
                                  >
                                    {item.name}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className='-mr-2 flex md:hidden'>
                    {/* Mobile menu button */}
                    <Disclosure.Button className='inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='sr-only'>Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className='block h-6 w-6'
                          aria-hidden='true'
                        />
                      ) : (
                        <Bars3Icon
                          className='block h-6 w-6'
                          aria-hidden='true'
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              {/* mobile version */}

              <Disclosure.Panel className='md:hidden'>
                <div className='space-y-1 px-2 pt-2 pb-3 sm:px-3'>
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as='a'
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                      onClick={() => {
                        navigate(item.href);
                      }}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className='border-t border-gray-700 pt-4 pb-3'>
                  <div className='flex items-center px-4'>
                    <div className='flex rounded-lg bg-slate-100 p-0.5'>
                      <button
                        className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-4 text-sm font-semibold ${
                          i18n.language === 'kk' && 'bg-white shadow'
                        }`}
                        type='button'
                        onClick={() => {
                          i18n.changeLanguage('kk');
                          setDefaultLocale('kk');
                        }}
                      >
                        <span
                          className={`not-sr-only ml-2  ${
                            i18n.language === 'kk'
                              ? 'text-gray-800'
                              : 'text-slate-600'
                          }`}
                        >
                          Қазақша
                        </span>
                      </button>
                      <button
                        className={`flex items-center rounded-md py-[0.4375rem] pl-2 pr-4 text-sm font-semibold ${
                          i18n.language === 'ru' && 'bg-white shadow'
                        }`}
                        type='button'
                        onClick={() => {
                          i18n.changeLanguage('ru');
                          setDefaultLocale('ru');
                        }}
                      >
                        <span
                          className={`not-sr-only ml-2  ${
                            i18n.language === 'ru'
                              ? 'text-gray-800'
                              : 'text-slate-600'
                          }`}
                        >
                          Русский
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className='mt-3 space-y-1 px-2'>
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        className='block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white'
                        onClick={() => {
                          TokenService.removeTokens();
                          setUser(null);
                          localStorage.clear();
                          navigate('/login');
                        }}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className='bg-white shadow'>
          <div className='mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8'>
            <h1 className='text-3xl font-bold tracking-tight text-gray-900'>
              {currentPage}
            </h1>
          </div>
        </header>

        <main>
          <div className='mx-auto max-w-[112rem] py-6 sm:px-6 lg:px-8 '>
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}
