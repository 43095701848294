import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import { Controller, useForm } from 'react-hook-form';
import { postExtraAllowedStudents } from '../../../services/ExtraAllowedStudents/ExtraAllowedStudentsService';
import Select from '../../../components/forms/Select/Select';
import { getSchools } from '../../../services/catalog/catalog';
import Input from '../../../components/forms/Input/Input';
import { PlusIcon } from '@heroicons/react/24/solid';

const Add = ({ data, updateData }) => {
  const [open, setOpen] = useState(false);
  const [schools, setSchools] = useState([]);
  const usedSchools = useMemo(() => data.map((d) => d.school.uuid), [data]);

  const {
    control,
    register,
    handleSubmit,

    reset,
  } = useForm();

  const onSubmit = (data) => {
    postExtraAllowedStudents(data).then((res) => {
      updateData();
      setOpen(false);
    });
  };

  useEffect(() => {
    if (!schools.length)
      getSchools().then((res) => {
        setSchools(res.results);
      });
  });

  return (
    <>
      <Button Icon={PlusIcon} onClick={() => setOpen(true)} />
      <CustomDialog
        isOpen={open}
        setIsOpen={setOpen}
        closeModal={() => {
          setOpen(false);
          reset();
        }}
        headerText={'Комментарий'}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2'>
          <Controller
            control={control}
            name={`school`}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                value={field.value}
                label={'Школа'}
                required
                onChange={(v) => field.onChange(v.value)}
                options={schools
                  .filter((s) => !usedSchools.includes(s.uuid))
                  .map((s) => ({ value: s.uuid, label: s.name }))}
              />
            )}
          />
          <Input
            label={'Количество доп. учеников'}
            type='number'
            min='0'
            {...register('students_count', {
              required: 'student count is required field',
            })}
          />
          <div className='flex flex-row justify-end'>
            <Button text='Создать' color='success' type='submit' />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};
export default Add;
