const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem('refreshTkn');
  return refreshToken;
};

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem('accessTkn');
  return accessToken;
};

const updateLocalAccessToken = (token) => {
  localStorage.setItem('accessTkn', token);
};

const removeTokens = () => {
  localStorage.removeItem('refreshTkn');
  localStorage.removeItem('accessTkn');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  removeTokens,
};

export default TokenService;
