import {
  DocumentIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import { getApplicantsLite } from '../../services/application/application';
import { PassRules } from '../../utils/constants/rules';
import logo from '../../assets/images/biledu.png';

export default function ExportStudent() {
  const [t] = useTranslation();
  const { uuid } = useParams();

  const [studentInfo, setStudentInfo] = useState(null);
  const [loadingDownload, setLoadingDownload] = useState(false);

  useEffect(() => {
    console.log('uuid', uuid);

    if (uuid) {
      getApplicantsLite(uuid).then((res) => {
        setStudentInfo(res);
      });
    }

    return () => {};
  }, [uuid]);

  return (
    <div className='flex overflow-x-scroll bg-sheet p-8 lg:justify-center'>
      <div className='md:max-w-7xl'>
        <div className='mb-8 flex flex-row lg:justify-end'>
          <Button
            color='primary'
            type='button'
            text='Скачать PDF'
            Icon={DocumentIcon}
            loading={loadingDownload}
            onClick={async () => {
              setLoadingDownload(true);
              const pdf = new jsPDF('p', 'mm');
              const pdfData = await html2canvas(
                document.querySelector('#pdf'),
                {
                  // allowTaint: false,
                  useCORS: true,
                },
              );
              const img = pdfData.toDataURL('image/png');

              const imgWidth = 210;
              const pageHeight = 295;

              const imgProperties = pdf.getImageProperties(img);
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const imgHeight =
                (imgProperties.height * imgWidth) / imgProperties.width;
              let heightLeft = imgHeight;

              let position = 10;
              pdf.addImage(img, 'PNG', 0, position, pdfWidth, imgHeight);

              heightLeft -= pageHeight;

              while (heightLeft >= 0) {
                position += heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(img, 'PNG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pageHeight;
              }

              // let avatar = new Image();
              // avatar.crossOrigin = 'anonymous';
              // avatar.src = studentInfo?.avatar_photo;

              // avatar.onerror = () => {
              //   setLoadingDownload(false);
              //   console.log('error');
              // };

              // avatar.onload = () => {
              //   // await for the image to be fully loaded
              //   pdf.addImage(avatar, 'PNG', 12, 20, 40, 40);
              //   pdf.save(
              //     `${studentInfo?.iin}_${studentInfo?.last_name}_${studentInfo?.first_name}.pdf`,
              //   );
              //   setLoadingDownload(false);
              // };
              pdf.save(
                `${studentInfo?.iin}_${studentInfo?.last_name}_${studentInfo?.first_name}.pdf`,
              );
              setLoadingDownload(false);
            }}
          />
        </div>

        <div
          id='pdf'
          className='flex h-[112rem] w-[80rem] flex-col gap-2 bg-white'
          translate='no'
        >
          <div className='flex w-full justify-center'>
            <img src={logo} alt='logo' width='68' height='68' />
          </div>
          <div className='flex flex-row'>
            <div className='w-1/3'></div>
            <div className='w-1/3'>
              <h2 className='text-center text-xl'>
                "Білім-инновация" лицейіне қабылдау емтиханына
              </h2>
            </div>
            <div className='w-1/3'></div>
          </div>
          <div className='flex flex-row'>
            <div className='h-9 w-1/3 bg-slate-900'></div>
            <div className='w-1/3 text-center text-4xl font-semibold'>
              Рұқсатнама / Пропуск
            </div>
            <div className='h-9 w-1/3 bg-slate-900'></div>
          </div>

          <div className='mx-8 mt-12 rounded-md border-2 p-4'>
            <h2 className='mb-4 text-xl font-semibold'>
              Үміткер туралы ақпарат / Информация о кандидате
            </h2>

            <div className='flex flex-row border-b-2 pb-4'>
              <div className='w-72'>
                <img
                  src={studentInfo?.avatar_photo + `?timestamp=${new Date()}`}
                  alt='avatar_photo'
                  crossOrigin='anonymous'
                />
              </div>
              <div className='flex w-full flex-col text-left'>
                {studentInfo?.first_name && studentInfo?.last_name ? (
                  <h1 className='mb-4 ml-5 text-4xl font-semibold'>
                    {studentInfo.first_name + ' ' + studentInfo.last_name}
                  </h1>
                ) : (
                  <div>загрузка...</div>
                )}
                <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
                  <dt className='font-medium'>ID / Login :</dt>
                  <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                    {studentInfo?.username}
                  </dd>
                </div>
                <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
                  <dt className='font-medium'>Құпиясөз / Пароль :</dt>
                  <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                    {studentInfo?.password}
                  </dd>
                </div>
                <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
                  <dt className='font-medium'>
                    Таңдалған мектеп / Выбранная школа :
                  </dt>
                  <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                    {studentInfo?.school?.name}
                  </dd>
                </div>
              </div>
            </div>
            <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
              <dt className='font-medium'>ЖСН / ИИН :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.iin}
              </dd>
            </div>
            <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
              <dt className='font-medium'>Туған күні / Дата рождения :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {moment(studentInfo?.birth_date).format('DD.MM.yyyy')}
              </dd>
            </div>
            <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
              <dt className='font-medium'>Жынысы / Пол :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.gender === 'M'
                  ? t('signup.male')
                  : t('signup.female')}
              </dd>
            </div>
            <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
              <dt className='font-medium'>Тобы / Группа :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.language === 'K' ? 'Қазақ' : 'Русская'}
              </dd>
            </div>
            <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
              <dt className='font-medium'>Мектебі / Школа :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.current_school_name}
              </dd>
            </div>
            <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
              <dt className='font-medium'>Мекен - жайы / Адрес :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.district?.name + ' - ' + studentInfo?.address}
              </dd>
            </div>
            <div className='flex flex-row gap-16 px-4 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6'>
              <dt className='font-medium'>Телефон :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {`${studentInfo?.parent_phone_number},  ${studentInfo?.parent_phone_number_2}`}
              </dd>
            </div>
          </div>

          <div className='mx-8 mt-4 rounded-md border-2 p-4'>
            <h2 className='mb-2 text-xl font-semibold'>
              Емтихан туралы ақпарат / Информация об экзамене
            </h2>
            <div className='flex flex-row gap-16 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4'>
              <dt className='font-medium'>
                Емтихан тапсыратын орталық / Центр сдачи экзамена:
              </dt>
              <dd className='mt-1 text-left font-bold sm:col-span-1 sm:mt-0'>
                {`${studentInfo?.exam_center?.name} ${
                  studentInfo?.exam_center?.address
                    ? ' ' + studentInfo?.exam_center?.address
                    : ''
                }`}
              </dd>
            </div>
            <div className='flex flex-row gap-16 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4'>
              <dt className='font-medium'>Күні / Дата :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.exam?.name
                  ? studentInfo?.exam?.name?.substr(8, 10)
                  : '-'}
              </dd>
            </div>
            <div className='flex flex-row gap-16 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4'>
              <dt className='font-medium'>Уақыты / Время :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.exam?.name
                  ? studentInfo?.exam?.name?.substr(0, 5)
                  : '-'}
              </dd>
            </div>
            <div className='flex flex-row gap-16 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4'>
              <dt className='font-medium'>Аудитория :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.classroom}
              </dd>
            </div>
            <div className='flex flex-row gap-16 py-3 text-lg sm:grid sm:grid-cols-2 sm:gap-4'>
              <dt className='font-medium'>Орын / Место :</dt>
              <dd className='mt-1 text-left sm:col-span-1 sm:mt-0'>
                {studentInfo?.seat}
              </dd>
            </div>
          </div>

          <div className='mx-8 mt-4 rounded-md border-2 border-red-600'>
            <h2 className='mx-4 mb-2 text-xl font-semibold text-red-600'>
              Ескертпе / Примечание
            </h2>
            <PassRules />
          </div>
          <div className='text mx-4 mt-4 flex flex-row justify-center gap-8'>
            <div className='font-semibold'>{studentInfo?.school?.name}</div>
            <div className='flex flex-row items-center'>
              <span>
                <InformationCircleIcon className='mr-2 h-6' />
              </span>
              {`Мектеп телефоны : ${studentInfo?.school?.phone_number} | Мектеп мекен-жайы : ${studentInfo?.school?.address}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
