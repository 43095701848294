import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ChoiceChip from '../../components/ChoiceChip/ChoiceChip';
import { Button } from '../../components/forms/Buttons/Button';
import { ANSWER_OPTIONS } from '../../utils/constants/constants';
import { UserContext } from '../../utils/contexts/UserContext';
import ExamEndButton from './ExamEndButton';
import ExamTextsButton from './texts/ExamTextsButton';

const ExaminationForm = ({
  question,
  saveAnswer,
  answer,
  isInProgress,
  setQuestionByIndex,
  questionIndex,
  questionLenght,
  endExam,
  examState,
  exam,
}) => {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <div className='col-span-9 flex w-full flex-row justify-center'>
      {question?.uuid ? (
        <div className='flex flex-col items-center gap-5'>
          <div
            className={
              'grid grid-cols-2 place-items-center sm:invisible sm:place-content-start ' +
              (question?.type === 0 && ' invisible')
            }
          >
            {examState ? <ExamTextsButton exam={exam} user={user} /> : ''}
          </div>
          <img
            className='img-responsive rounded-lg'
            alt='question'
            src={question?.image}
          />
          <hr />
          <div className='flex max-w-fit  flex-row flex-wrap justify-center gap-3 px-3 sm:gap-10 sm:px-0'>
            {ANSWER_OPTIONS?.map((option) => (
              <ChoiceChip
                label={option}
                key={option}
                selected={answer === option}
                disabledOnSelect={false}
                disabled={!isInProgress}
                className='max-w-fit'
                onClick={() => {
                  if (answer === option) {
                    saveAnswer(question?.uuid);
                  } else {
                    saveAnswer(question?.uuid, option);
                  }
                }}
              />
            ))}
          </div>
          <div className='flex w-full flex-row justify-between'>
            <Button
              className={!(questionIndex > 0) && 'invisible'}
              //   text={t('buttons.prev')}
              text={t('buttons.back')}
              onClick={() => setQuestionByIndex(questionIndex - 1)}
            />
            {questionIndex < questionLenght - 1 ? (
              <Button
                text={t('buttons.next')}
                onClick={() => setQuestionByIndex(questionIndex + 1)}
              />
            ) : examState ? (
              <ExamEndButton
                header={t('mockup.confirm_end')}
                onConfirm={endExam}
              />
            ) : (
              <Link to={`/student/exam/${exam?.uuid}/result`}>
                <Button color='success' text={t('student.results')} />
              </Link>
            )}
          </div>
        </div>
      ) : (
        <div className='items-centerw-full flex w-full flex-col'>
          <div className='w-10/12  rounded-md bg-yellow-200 p-4'>
            {t('mockup.question_not_choosed')}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExaminationForm;
