import bilAPI from '../api';

export const getClassroom = (exam_time, exam_center) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get('/core/classroom/', {
          params: {
            exam_time,
            exam_center,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const exportClassroomPassesPDF = (exam_time, exam_center, classroom) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(
          `/core/classroom_pdf/?exam_time=${exam_time}&exam_center=${exam_center}&classroom=${classroom}`,
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
};
