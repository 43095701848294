import React from 'react';
import exam_result_icon_ru from '../images/exam_result_icon_ru.png';
import exam_result_icon_kz from '../images/exam_result_icon_kz.png';
import { useTranslation } from 'react-i18next';

const ExamResultSection = () => {
  const [t, i18n] = useTranslation();

  return (
    <div className='flex w-screen  flex-col items-center gap-20 bg-white py-16 xl:flex-row xl:justify-center '>
      <img
        className='hidden md:flex'
        width={522}
        height={628}
        src={i18n.language === 'ru' ? exam_result_icon_ru : exam_result_icon_kz}
        alt='exam result'
      />

      <div className='flex flex-col gap-8 text-center sm:w-[40rem] sm:text-left  '>
        <p className='font-sfproBold text-5xl'>
          <span>{t('welcome.examination_results_1_1')} </span>{' '}
          <span className='text-brown'>
            {t('welcome.examination_results_1_2')}
          </span>{' '}
          <span> {t('welcome.examination_results_1_3')}</span>
        </p>
        <div className='flex flex-row gap-5 px-5 sm:px-0'>
          <svg
            className='min-w-fit rounded-xl'
            width='30'
            height='31'
            viewBox='0 0 30 31'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M30.001 0.509521H0.514648V30.0095H30.001V0.509521Z'
              fill='#121233'
            />
            <path
              d='M29.7821 15.3062L15.1416 0.658936L0.501079 15.3062L15.1416 29.9535L29.7821 15.3062Z'
              fill='#ED7051'
            />
            <path
              d='M15.2127 7.84375L17.8036 12.5847L22.5437 15.1781L17.8036 17.7701L15.2127 22.5124L12.6205 17.7701L7.88184 15.1781L12.6205 12.5847L15.2127 7.84375Z'
              fill='white'
            />
          </svg>

          <ul className='text-2xl'>
            <li>
              {t('welcome.examination_results_3_1')}
              <span className='text-brown'>
                {t('welcome.examination_results_3_2')}
              </span>
              {t('welcome.examination_results_3_3')}
            </li>
          </ul>
        </div>
        <div className='flex flex-row gap-5 px-5 sm:px-0'>
          <svg
            className='min-w-fit rounded-xl'
            width='30'
            height='31'
            viewBox='0 0 30 31'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M30.001 0.509521H0.514648V30.0095H30.001V0.509521Z'
              fill='#121233'
            />
            <path
              d='M29.7821 15.3062L15.1416 0.658936L0.501079 15.3062L15.1416 29.9535L29.7821 15.3062Z'
              fill='#6C8CE4'
            />
            <path
              d='M15.2127 7.84375L17.8036 12.5847L22.5437 15.1781L17.8036 17.7701L15.2127 22.5124L12.6205 17.7701L7.88184 15.1781L12.6205 12.5847L15.2127 7.84375Z'
              fill='white'
            />
          </svg>
          <ul className='text-2xl'>
            <li>
              <span>{t('welcome.examination_results_2_1_1')}</span>{' '}
              <a
                className='text-primary hover:underline'
                href='https://bilemtihan.kz'
              >
                bilemtihan.kz
              </a>{' '}
              <span>{t('welcome.examination_results_2_1_2')}</span>
            </li>
          </ul>
        </div>

        <img
          className='flex md:hidden'
          src={
            i18n.language === 'ru' ? exam_result_icon_ru : exam_result_icon_kz
          }
          alt='exam result'
        />
      </div>
    </div>
  );
};

export default ExamResultSection;
