import { Tab } from '@headlessui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../utils/contexts/UserContext';
import { classNames } from '../../utils/helpers/helpers';
import ContactInfo from './StudentTabs/ContactInfo';
import MainInfo from './StudentTabs/MainInfo';

export default function StudentProfile() {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (user?.application?.uuid) {
      setProfile(user.application);

      // Test
    }

    return () => {};
  }, [user]);

  return (
    <Tab.Group>
      <Tab.List className='-mb-px flex flex-col rounded-t-md border sm:flex-row sm:space-x-8'>
        <Tab
          key='mainTab'
          className={({ selected }) =>
            classNames(
              selected
                ? 'border-blue-600 bg-blue-100 text-blue-600'
                : 'border-transparent text-gray-900',
              'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium md:max-w-[12rem]',
            )
          }
        >
          {t('student.info')}
        </Tab>
        <Tab
          key='contactTab'
          className={({ selected }) =>
            classNames(
              selected
                ? 'border-blue-600 bg-blue-100 text-blue-600'
                : 'border-transparent text-gray-900',
              'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium md:max-w-[12rem]',
            )
          }
        >
          {t('student.contacts')}
        </Tab>
      </Tab.List>
      <Tab.Panels translate='no' className='rounded-b-md border'>
        <Tab.Panel key='main' className='space-y-10 px-4 py-8'>
          <MainInfo profile={profile} />
        </Tab.Panel>
        <Tab.Panel key='contact' className='space-y-10 px-4 py-8'>
          <ContactInfo profile={profile} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}
