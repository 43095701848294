import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';
import { classNames } from '../../../../utils/helpers/helpers';
import FirstDayResult from './FirstDayResult';
import SecondDayResult from './SecondDayResult';

export default function OldResultForm({ results }) {
  const [t] = useTranslation();

  const [studentResultDayOne, setStudentResultDayOne] = useState(null);
  const [studentResultDayTwo, setStudentResultDayTwo] = useState(null);
  const [studentResultDayThree, setStudentResultDayThree] = useState(null);
  const [studentResultDayFour, setStudentResultDayFour] = useState(null);

  useEffect(() => {
    if (results?.length) {
      setStudentResultDayFour(results?.find((v) => v?.round === 3));
      setStudentResultDayThree(results?.find((v) => v?.round === 2));
      setStudentResultDayTwo(results?.find((v) => v?.round === 1));
      setStudentResultDayOne(results?.find((v) => v?.round === 0));
    }
    return () => {};
  }, [results]);

  return (
    <Tab.Group>
      <Tab.List className='-mb-px flex flex-col rounded-t-md border sm:flex-row sm:space-x-8'>
        {studentResultDayOne?.uuid && (
          <Tab
            key='firstDayResultTab'
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-blue-600 bg-blue-100 text-blue-600'
                  : 'border-transparent text-gray-900',
                'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium md:max-w-[12rem]',
              )
            }
          >
            {t('student.results_day_1')}
          </Tab>
        )}
        {studentResultDayTwo?.uuid && (
          <Tab
            key='secondDayResultTab'
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-blue-600 bg-blue-100 text-blue-600'
                  : 'border-transparent text-gray-900',
                'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium md:max-w-[12rem]',
              )
            }
          >
            {t('student.results_day_2')}
          </Tab>
        )}
        {studentResultDayThree?.uuid && (
          <Tab
            key='ThirdDayResult'
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-blue-600 bg-blue-100 text-blue-600'
                  : 'border-transparent text-gray-900',
                'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium md:max-w-[20rem]',
              )
            }
          >
            {t('student.results_day_1_ekibastuz')}
          </Tab>
        )}
        {studentResultDayFour?.uuid && (
          <Tab
            key='fourthDayResult'
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-blue-600 bg-blue-100 text-blue-600'
                  : 'border-transparent text-gray-900',
                'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium md:max-w-[20rem]',
              )
            }
          >
            {t('student.results_day_2_ekibastuz')}
          </Tab>
        )}
      </Tab.List>
      <Tab.Panels translate='no' className='rounded-b-md border'>
        {studentResultDayOne?.uuid && (
          <Tab.Panel key='firstDayResult' className='space-y-10 px-4 py-8'>
            <FirstDayResult studentResult={studentResultDayOne} />
          </Tab.Panel>
        )}
        {studentResultDayTwo?.uuid && (
          <Tab.Panel key='secondDayResult' className='space-y-10 px-4 py-8'>
            <SecondDayResult studentResult={studentResultDayTwo} />
          </Tab.Panel>
        )}
        {studentResultDayThree?.uuid && (
          <Tab.Panel key='ThirdDayResult' className='space-y-10 px-4 py-8'>
            <FirstDayResult studentResult={studentResultDayThree} />
          </Tab.Panel>
        )}
        {studentResultDayFour?.uuid && (
          <Tab.Panel key='fourthDayResult' className='space-y-10 px-4 py-8'>
            <SecondDayResult studentResult={studentResultDayFour} />
          </Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );
}
