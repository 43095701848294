import React, { useEffect, useState } from 'react';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { getExams } from '../../services/exams/examsService';
import Add from './BREAD/Add';
import ExaminationCard from './ExaminactionCard';

const Examinations = () => {
  const [exams, setExams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pickedExam, setPickedExam] = useState();

  useEffect(() => {
    getExamsData();
  }, []);

  const getExamsData = () => {
    setIsLoading(true);
    getExams()
      .then((res) => {
        setExams(res.results);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading ? (
        <SimpleLoader className='h-5' />
      ) : (
        <div className='flex w-full flex-col gap-2'>
          <div className='flex flex-row justify-end'>
            <Add getData={getExamsData} />
          </div>
          <div className='flex w-full flex-col border-b-2 border-slate-300' />
          <div className='flex flex-col'>
            {exams?.map((exam, i) => {
              return (
                <ExaminationCard
                  exam={exam}
                  pickedExam={pickedExam}
                  setPickedExam={setPickedExam}
                  getData={getExamsData}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Examinations;
