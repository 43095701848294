import { RadioGroup } from '@headlessui/react';
import {
  ArrowDownRightIcon,
  ArrowUpRightIcon,
  Bars2Icon,
} from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import Select from '../../components/forms/Select/Select';
import DatePicker from '../../components/forms/DatePicker/DatePicker';
import moment from 'moment';
import StatsTable from './StatsTable';

export default function SchoolListCompareTable({
  data,
  loading,
  regions,
  getData,
}) {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDay, setSelectedDay] = useState(new Date());

  const columns = React.useMemo(
    () => [
      {
        Header: 'Школа',
        accessor: 'name',
      },
      {
        Header: 'Сравнение 2023/2024',
        accessor: 'isIncreased',
        Cell: (props) => (
          <div className=' font-bold'>
            {props.value === 0 && (
              <ArrowDownRightIcon className='h-6 text-red-600' />
            )}
            {props.value === 1 && (
              <ArrowUpRightIcon className='h-6 text-green-600' />
            )}
            {props.value === 2 && <Bars2Icon className='h-6' />}
          </div>
        ),
      },
      {
        Header: '2023',
        accessor: 'y_2023',
      },
      {
        Header: '2024',
        accessor: 'y_2024',
      },
      {
        Header: 'Цель 2024',
        accessor: 'current_year_goal',
        Cell: (props) => (
          <div
            className={`${
              props.value > props.row.original.y_2024
                ? 'bg-red-300'
                : props.value === props.row.original.y_2024
                ? 'bg-blue-300'
                : 'bg-green-300'
            } text-center font-bold`}
          >
            {props.value}
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row gap-4'>
        {false && (
          <RadioGroup
            className='flex flex-row gap-2 text-sm font-semibold'
            value={selectedRegion}
            onChange={(e) => console.log('e', e)}
          >
            <RadioGroup.Option value='all'>
              {({ checked }) => (
                <span
                  className={
                    checked
                      ? 'cursor-pointer rounded-md bg-blue-600 px-4 py-2 text-white'
                      : 'cursor-pointer rounded-md bg-gray-200 px-4 py-2'
                  }
                >
                  Все регионы
                </span>
              )}
            </RadioGroup.Option>
            {regions?.map((r) => (
              <RadioGroup.Option key={r?.value} value={r?.value}>
                {({ checked }) => (
                  <span
                    className={
                      checked
                        ? 'cursor-pointer rounded-md bg-blue-600 px-4 py-2 text-white'
                        : 'cursor-pointer rounded-md bg-gray-200 px-4 py-2'
                    }
                  >
                    {r?.label}
                  </span>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        )}
        <div className='flex flex-row gap-2 border-r-2 border-gray-800 pr-2'>
          <Select
            label='Регион:'
            value={selectedRegion}
            isSearchable={false}
            options={[{ label: 'Все регионы', value: null }, ...regions]}
            onChange={(e) => {
              setSelectedRegion(e.value);
              getData(e.value, moment(selectedDay).format('DD.MM'));
            }}
          />
        </div>
        <div>
          <DatePicker
            className='w-64'
            label={'Сравнение до:'}
            selected={selectedDay}
            onChange={(date) => {
              setSelectedDay(date);
              getData(selectedRegion, moment(date).format('DD.MM'));
            }}
            // placeholderText={t('buttons.select')}
            dateFormat='dd / MM / yyyy'
          />
        </div>
      </div>
      <StatsTable columns={columns} data={data} loading={loading} />
    </div>
  );
}
