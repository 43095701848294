import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import Input from '../../../components/forms/Input/Input';
import Select from '../../../components/forms/Select/Select';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import {
  getApplication,
  patchApplication,
} from '../../../services/application/application';
import { getDistricts } from '../../../services/catalog/catalog';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import { getExamDates } from '../../../services/core/coreService';
import { ExamYearContext } from '../../../utils/contexts/ExamYearContext';
import { Tab } from '@headlessui/react';
import { classNames, showImageOrPdf } from '../../../utils/helpers/helpers';
import {
  deleteSocialDocument,
  getSocialDocuments,
  postSocialDocument,
} from '../../../services/socialDocs/socialDocs';
import { ArrowUpTrayIcon, TrashIcon } from '@heroicons/react/24/solid';
import { getSocialDocumentsTypes } from '../../../services/socialDocs/socialDocsTypes';

export default function UpdateExamInfoModal({
  student,
  modalOpen,
  setModalOpen,
  refreshData,
}) {
  const [i18n] = useTranslation();
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();
  const { examYear } = useContext(ExamYearContext);

  const [districts, setDistricts] = useState([]);
  const [examCenters, setExamCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [examDates, setExamDates] = useState([]);

  const [vulnerableGroups, setVulnerableGroups] = useState([]);
  const [fileList, setFileList] = useState(null);
  const [aspLoading, setAspLoading] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isAsp, setIsAsp] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [uploadDisabled, setUploadDisabled] = useState(false);

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    getDistricts().then((res) => {
      const options = res.results
        .filter((dist) => dist?.is_geographical)
        .map((item) => ({
          label: item.name,
          value: item.uuid,
        }));
      setDistricts(options);

      const centers = res.results
        .filter((dist) => dist?.is_exam_center)
        .map((item) => ({
          label:
            item.name +
            (item?.address ? ' - ' + item.address : '') +
            (item?.is_full ? ' - Центр заполнен!' : ''),
          value: item.uuid,
        }));
      setExamCenters(centers);
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (examYear?.uuid) {
      getExamDates(examYear?.uuid).then((res) => {
        var options = res?.results?.map((date) => ({
          label: date.name,
          value: date.uuid,
        }));
        setExamDates(options);
      });
    }

    return () => {
      setExamDates([]);
    };
  }, [examYear]);

  useEffect(() => {
    if (student?.uuid) {
      setValue('parentPhoneNumber', student?.parent_phone_number);
      setValue('birthDate', new Date(student?.birth_date));
      setValue('language', student?.language);

      setValue('examCenter', student?.exam_center?.uuid);
      setValue('district', student?.district?.uuid);
      setValue('room', student?.classroom);
      setValue('place', student?.seat);
      setValue('exam', student?.exam?.uuid);
      // setValue('examTime', student?.exam_time);
    }

    return () => {
      reset();
    };
  }, [student, setValue, reset]);

  const onSubmit = (data) => {
    setLoading(true);
    const body = {
      parent_phone_number: data.parentPhoneNumber,
      birth_date: format(data.birthDate, 'yyyy-MM-dd'),
      language: data.language,

      exam_center: data.examCenter,
      district: data.district,
      classroom: data.room,
      seat: data.place,
      exam: data.exam,
    };

    patchApplication(student?.uuid, body)
      .then(() => {
        refreshData();
        addSnackbar(t('Успешно'), 'success');
      })
      .catch(() => {
        addSnackbar(t('Ошибка'), 'error');
      })
      .finally(() => {
        setLoading(false);
        setModalOpen(false);
      });
  };

  useEffect(() => {
    if (student?.uuid) {
      getApplication(student?.uuid).then((res) => {
        setIsAsp(res?.social_doc_type ? true : false);
        setSelectedGroup(res?.social_doc_type);
      });

      getSocialDocumentsTypes().then((res) => {
        setVulnerableGroups(res.results);
      });

      getSocialDocuments({ application: student.uuid }).then((res) => {
        setUploadedDocuments(res.results);
      });
    }

    return () => {};
  }, [student]);

  const handleFileChange = (e) => {
    setFileList(e.target.files);
  };

  const updateAspStatus = async (socDocs, categoryFiles) => {
    let body;
    if (socDocs.length >= categoryFiles.length && socDocs.length !== 0) {
      body = {
        asp_status: 2,
      };
    } else {
      body = {
        asp_status: 1,
      };
    }
    await patchApplication(student?.uuid, body)
      .then(() => {
        addSnackbar(t('Успешно'), 'success');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUploadClick = async () => {
    setAspLoading(true);
    if (!fileList || !selectedGroup.uuid) {
      return;
    }

    for (const file of files) {
      const data = new FormData();

      data.append('application', student?.uuid);
      data.append(`document`, file, file.name);

      try {
        await postSocialDocument(data).then((res) => {
          // console.log('res', res);
        });
      } catch (error) {
        addSnackbar(t('Ошибка'), 'error');
      }
    }
    try {
      await getSocialDocuments({ application: student?.uuid })
        .then((res) => {
          setUploadedDocuments(res.results);
          updateAspStatus(res?.results, selectedGroup?.files);
        })
        .finally(() => {
          setAspLoading(false);
          setFileList(null);
          setUploadDisabled(false);
        });
    } catch (error) {
      addSnackbar(t('Ошибка'), 'error');
    }
  };

  const files = fileList ? [...fileList] : [];

  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Обновить данные'}
    >
      <Tab.Group>
        <Tab.List className='flex space-x-1 rounded-md bg-gray-900 p-1'>
          <Tab
            key={'main'}
            className={({ selected }) =>
              classNames(
                'w-full rounded-md py-2.5 text-sm font-medium leading-5',
                'font-semibold ring-offset-2 ring-offset-blue-400',
                selected
                  ? 'bg-white text-gray-800 shadow'
                  : 'text-white hover:bg-gray-500 hover:text-white',
              )
            }
          >
            Негізгі ақпарат
          </Tab>
          <Tab
            key={'asp'}
            className={({ selected }) =>
              classNames(
                'w-full rounded-md py-2.5 text-sm font-medium leading-5',
                'font-semibold ring-offset-2 ring-offset-blue-400',
                selected
                  ? 'bg-white text-gray-800 shadow'
                  : 'text-white hover:bg-gray-500 hover:text-white',
              )
            }
          >
            ӘОТ құжаттары
          </Tab>
        </Tab.List>
        <Tab.Panels className='mt-2 min-h-[40rem] rounded-md border'>
          <Tab.Panel
            key={1}
            className={classNames(
              'rounded-md bg-white p-2',
              'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
            )}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='bg-white px-4 py-5 sm:p-6'>
                <div className='grid grid-cols-6 gap-6'>
                  <div className='col-span-6 md:col-span-3'>
                    <label
                      htmlFor='whatsapp'
                      className='block text-sm font-medium text-gray-700'
                    >
                      {t('signup.parent_phone')}
                      <span className='text-error'>*</span>
                    </label>
                    <div className='mt-1 flex rounded-md shadow-sm'>
                      <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                        +7
                      </span>
                      <input
                        type='text'
                        name='parentPhoneNumber'
                        id='parentPhoneNumber'
                        className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                        // placeholder='www.example.com'
                        // errorMsg={
                        //   errors?.whatsapp?.type ? t('signup.parent_phone_error') : ''
                        // }
                        {...register('parentPhoneNumber', {
                          required: true,
                          minLength: 10,
                          maxLength: 10,
                          pattern: {
                            value: /^7[0-9]{9}?$/,
                          },
                        })}
                      />
                    </div>
                    {errors?.whatsapp?.type && (
                      <span className='mt-1 text-xs font-semibold text-error'>
                        {t('signup.parent_phone_error')}
                      </span>
                    )}
                  </div>

                  <div className='col-span-6 md:col-span-3'>
                    <Controller
                      control={control}
                      name='birthDate'
                      render={({ field }) => (
                        <DatePicker
                          className='w-full'
                          label={t('signup.birth_date')}
                          required
                          errorMsg={errors?.birthDate?.message}
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          placeholderText={t('buttons.select')}
                          dateFormat='dd / MM / yyyy'
                          maxDate={new Date()}
                        />
                      )}
                    />
                  </div>

                  <Controller
                    control={control}
                    name='language'
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        label={t('signup.study_lang')}
                        required
                        errorMsg={
                          errors?.language?.type
                            ? t('signup.select_study_lang')
                            : ''
                        }
                        className='col-span-6 md:col-span-3'
                        value={field.value}
                        options={[
                          {
                            label: 'Қазақша',
                            value: 'K',
                          },
                          {
                            label: 'Русский',
                            value: 'R',
                          },
                        ]}
                        // placeholder={t('announcement.select_positions')}
                        onChange={(e) => field.onChange(e.value)}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name='examCenter'
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        label={'Экзаменационный центр'}
                        required
                        errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                        className='col-span-6'
                        value={field.value}
                        options={examCenters}
                        // placeholder={t('announcement.select_positions')}
                        onChange={(e) => {
                          // setSelectedDistrict(e);
                          field.onChange(e.value);
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name='district'
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        label={'Район'}
                        required
                        errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                        className='col-span-6'
                        value={field.value}
                        options={districts}
                        // placeholder={t('announcement.select_positions')}
                        onChange={(e) => {
                          // setSelectedDistrict(e);
                          field.onChange(e.value);
                        }}
                      />
                    )}
                  />

                  <Input
                    type='text'
                    className='col-span-6 sm:col-span-3'
                    label='Аудитория'
                    required
                    errorMsg={
                      errors?.parentLastName?.type
                        ? t('signup.enter_parent_surname')
                        : ''
                    }
                    {...register('room', {
                      required: true,
                    })}
                  />
                  <Input
                    type='text'
                    className='col-span-6 sm:col-span-3'
                    label='Место'
                    required
                    errorMsg={
                      errors?.parentLastName?.type
                        ? t('signup.enter_parent_surname')
                        : ''
                    }
                    {...register('place', {
                      required: true,
                    })}
                  />

                  <Controller
                    control={control}
                    name='exam'
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        label={'Выберите дату'}
                        required
                        errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                        className='col-span-6 sm:col-span-3'
                        value={field.value}
                        options={examDates}
                        // placeholder={t('announcement.select_positions')}
                        onChange={(e) => {
                          // setSelectedDistrict(e);
                          field.onChange(e.value);
                        }}
                      />
                    )}
                  />

                  {/* <Controller
              control={control}
              name='examTime'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label={'Выберите время'}
                  required
                  isSearchable={false}
                  errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                  className='col-span-6 text-xs sm:col-span-3'
                  value={field.value}
                  options={[
                    {
                      label:
                        '10:00 Астана уақыты / Время Астаны \n 09:00 Батыс Қазақстан уақыты / По времени западного Казахстана',
                      value: '0',
                    },
                    {
                      label:
                        '14:00 Астана уақыты / Время Астаны \n 13:00 Батыс Қазақстан уақыты / По времени западного Казахстана',
                      value: '1',
                    },
                  ]}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => {
                    // setSelectedDistrict(e);
                    field.onChange(e.value);
                  }}
                />
              )}
            /> */}
                </div>
              </div>
              <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
                <Button loading={loading} type='submit' text='Обновить' />
              </div>
            </form>
          </Tab.Panel>
          <Tab.Panel
            key={2}
            className={classNames(
              'rounded-md bg-white p-2',
              'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
            )}
          >
            <div className=''>
              <div className='p-4'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>
                  Документы, подтверждающие принадлежность к социально уязвимым
                  группам
                </h3>
              </div>
              <div className='flex cursor-pointer items-center border-y p-4'>
                <input
                  id='checkRU'
                  name='checkRU'
                  type='checkbox'
                  checked={isAsp}
                  value={isAsp}
                  onChange={(e) => {
                    setIsAsp((v) => !v);

                    if (e.target.checked === false) {
                      setUploadDisabled(true);
                      const body = {
                        social_doc_type: '',
                        // asp_status: getAspStatus(),
                      };
                      patchApplication(student?.uuid, body)
                        .then((res) => {
                          // setApplicantInfo(res);
                          refreshData();
                          setSelectedGroup(null);
                          addSnackbar(t('Категория изменена'), 'success');
                        })
                        .finally(() => {
                          setUploadDisabled(false);
                        });
                    }
                  }}
                  className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
                />
                <label htmlFor='checkRU' className={'ml-2 block'}>
                  Относится ли ученик к социально уязвимым категориям населения
                </label>
              </div>
              <div
                className={`${
                  isAsp ? '' : 'pointer-events-none opacity-50'
                } flex flex-col gap-4`}
              >
                <div className='mx-4 mt-2 flex flex-row items-center justify-between'>
                  <h3 className='w-2/3'>
                    <span className='text-lg font-semibold text-blue-600'>
                      Категория:
                    </span>
                    {/* {applicant?.social_doc_type?.name} */}
                    <Select
                      loading={uploadDisabled}
                      isSearchable={false}
                      className=''
                      value={selectedGroup?.uuid}
                      options={[
                        // { label: t('signup.not_in_category'), value: null },
                        ...vulnerableGroups.map((group, i) => ({
                          label:
                            `${i + 1}. ` +
                            (i18n.language === 'kk'
                              ? group?.name
                              : group?.name_ru),
                          value: group?.uuid,
                          docs_list: { kz: group?.files, ru: group?.files_ru },
                          data: group,
                        })),
                      ]}
                      // placeholder={t('announcement.select_positions')}
                      // onChange={(e) => field.onChange(e.value)}
                      onChange={(e) => {
                        setSelectedGroup(e.data);
                        setUploadDisabled(true);
                        const body = {
                          social_doc_type: isAsp ? e.data?.uuid : '',
                          // asp_status: getAspStatus(),
                        };
                        patchApplication(student?.uuid, body)
                          .then((res) => {
                            // setApplicantInfo(res);
                            refreshData();
                            addSnackbar(t('Категория изменена'), 'success');
                          })
                          .finally(() => {
                            setUploadDisabled(false);
                          });
                      }}
                    />
                  </h3>
                  {/* <Button text='Добавить документ' Icon={PlusIcon} /> */}
                </div>
                <div className='mx-4'>
                  <p className='font-semibold text-blue-600'>
                    Список подтверждающих документов:
                  </p>
                  <div>
                    <ul className='rounded-md border bg-white p-2'>
                      {selectedGroup?.files.map((file, i) => (
                        <li className='m-1' key={i}>
                          {`${i + 1}. ` + file}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className='mx-4'>
                  <p className='mb-2 font-semibold text-blue-600'>
                    Загруженные документы:
                  </p>
                  <div className='grid min-h-[4rem] grid-cols-3 gap-2'>
                    {uploadedDocuments?.map((doc) =>
                      doc?.document ? (
                        <div
                          key={doc?.uuid}
                          className='flex flex-col justify-between rounded-md border p-2'
                        >
                          {/* <img
                    src={doc?.document}
                    alt='doc'
                    className='h-32 object-cover'
                  /> */}
                          {showImageOrPdf(doc?.document, '4')}
                          <Button
                            className='mt-2 w-16'
                            color='error'
                            Icon={TrashIcon}
                            onClick={() => {
                              setUploadDisabled(true);
                              deleteSocialDocument(doc?.uuid).then(() => {
                                getSocialDocuments({
                                  application: student?.uuid,
                                })
                                  .then((res) => {
                                    setUploadedDocuments(res.results);
                                    updateAspStatus(
                                      res?.results,
                                      selectedGroup?.files,
                                    );
                                  })
                                  .finally(() => {
                                    setUploadDisabled(false);
                                  });
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      ),
                    )}
                  </div>
                </div>
                <div className='m-4 rounded-md border p-2 '>
                  <p className='font-semibold text-blue-600'>
                    Выберите документы:
                  </p>
                  <div>
                    <input type='file' onChange={handleFileChange} multiple />

                    <ul className='mt-2 border-y bg-white p-2'>
                      {files?.length ? (
                        files.map((file, i) => (
                          <li className='m-1' key={i}>
                            {file.name} - {file.type}
                          </li>
                        ))
                      ) : (
                        <div className='text-center italic text-gray-600'>
                          Загрузите файлы
                        </div>
                      )}
                    </ul>
                  </div>
                  <div className='grid grid-cols-3'>
                    {/* <div className=' box-border h-64 w-36 border bg-slate-600'></div>
          <div className=' box-border h-64 w-36 border bg-slate-600'></div>
          <div className=' box-border h-64 w-36 border bg-slate-600'></div>
        <div className=' box-border h-64 w-36 border bg-slate-600'></div> */}
                  </div>
                  <div className='flex flex-row justify-end px-4'>
                    <Button
                      className='my-2'
                      loading={aspLoading}
                      disabled={uploadDisabled || files?.length === 0}
                      Icon={ArrowUpTrayIcon}
                      text='Загрузить документы'
                      onClick={() => handleUploadClick()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </CustomDialog>
  );
}
