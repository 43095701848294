import React, { useContext, useEffect, useState } from 'react';
import {
  getRejectReasons,
  getResults,
  patchResult,
} from '../../services/results/resultsService';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { Button } from '../../components/forms/Buttons/Button';
import {
  ChevronDownIcon,
  EyeIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import Select from '../../components/forms/Select/Select';
import useSnackbar from '../../utils/hooks/useSnackbar';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../utils/contexts/UserContext';
import ExportToBilOrgModal from './ExportToBilOrgModal';
import { getAcceptanceStats } from '../../services/acceptance/acceptanceService';

const PAGE_SIZE = 200;

export default function AcceptedStudents() {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const [reasons, setReasons] = useState([]);
  const [studentsResults, setStudentsResults] = useState([]);
  const [acceptanceStats, setAcceptanceStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [studentToEdit, setStudentToEdit] = useState(null);

  useEffect(() => {
    if (user?.school?.school_id) {
      setLoading(true);
      getAcceptanceStats().then((res) => {
        setAcceptanceStats(res);
        console.log(res);
      });
      getResults({
        page: 1,
        page_size: PAGE_SIZE,
        to_approve_docs: true,
      })
        .then((res) => {
          setStudentsResults(res.results);
          setCount(res.count);
        })
        .finally(() => {
          setLoading(false);
        });

      getRejectReasons().then((res) => {
        setReasons(
          res.results.map((v) => ({ label: v.name, value: v.uuid })).reverse(),
        );
      });
    }

    return () => {};
  }, [user]);

  const addStudents = () => {
    setLoadingMore(true);
    getResults({
      page: page + 1,
      page_size: PAGE_SIZE,
      round: user?.school?.school_id === '039' ? 3 : 1,
    })
      .then((res) => {
        setStudentsResults(studentsResults?.concat(res?.results));
        setPage(page + 1);
      })
      .finally(() => setLoadingMore(false));
  };

  const receiveStudentDocuments = async (
    resultUuid,
    acceptanceStatus,
    reason,
  ) => {
    // console.log(isAccepted, reason);
    if (acceptanceStatus === 2 && reason === null) {
      addSnackbar('Себебін таңдаңыз', 'info');
      const updatedResults = [...studentsResults];
      const resultToUpdate = updatedResults.find((r) => r.uuid === resultUuid);
      resultToUpdate.acceptance_status = 2;
      setStudentsResults(updatedResults);
    } else {
      let body;
      if (acceptanceStatus === 2) {
        body = {
          acceptance_status: acceptanceStatus,
          reason: reason,
        };
      } else {
        body = {
          acceptance_status: acceptanceStatus,
        };
      }

      await patchResult(resultUuid, body)
        .then((res) => {
          const updatedResults = [...studentsResults];
          const resultToUpdate = updatedResults.find(
            (r) => r.uuid === resultUuid,
          );
          resultToUpdate.acceptance_status = res.acceptance_status;
          resultToUpdate.reason = res.reason;
          setStudentsResults(updatedResults);
          addSnackbar('Успешно', 'success');
        })
        .catch((err) => {
          // console.log('err', err);
          if (err?.response?.data?.code === '001') {
            addSnackbar(
              'Пожалуйста, проверьте ребенка с пройденным статусом',
              'error',
            );
          } else if (err?.response?.data?.code === '002') {
            addSnackbar('Добавьте причину', 'error');
          } else if (err?.response?.data?.code === '003') {
            addSnackbar('Эти значения нельзя поменять', 'error');
          } else if (err?.response?.data?.code === '004') {
            addSnackbar(
              'не редактируется, пока олимпиадный статус не присвоется ученикам с одинаковыми баллами',
              'error',
            );
          } else if (err?.response?.data?.code === '005') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников',
              'error',
            );
          } else if (err?.response?.data?.code === '006') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников для Русской группы',
              'error',
            );
          } else if (err?.response?.data?.code === '007') {
            addSnackbar(
              'Достигнуто максимальное количество принятых учеников для Казахской группы',
              'error',
            );
          } else if (err?.response?.data?.code === '008') {
            addSnackbar(
              'Администрация закрыла доступ для приема документов учеников',
              'error',
            );
          } else {
            addSnackbar('Ошибка', 'error');
          }
        });
    }
  };

  const updateOlympiadStatus = async (resultUuid, status) => {
    const body = {
      olympiad_status: status,
    };

    await patchResult(resultUuid, body)
      .then((res) => {
        const updatedResults = [...studentsResults];
        const resultToUpdate = updatedResults.find(
          (r) => r.uuid === resultUuid,
        );
        resultToUpdate.olympiad_status = res.olympiad_status;
        setStudentsResults(updatedResults);
        addSnackbar('Успешно', 'success');
      })
      .catch((err) => {
        addSnackbar('Ошибка', 'error');
      });
  };

  return (
    <div className='pt-4'>
      <CommentEditModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        studentToEdit={studentToEdit}
        studentsResults={studentsResults}
        setStudentsResults={setStudentsResults}
      />
      {loading ? (
        <div className='h-full w-full p-12'>
          <SimpleLoader className={'h-14 w-14'} />
        </div>
      ) : studentsResults?.length ? (
        <>
          <div className='mb-4 flex flex-row justify-end'>
            <div className='flex flex-col'>
              <ExportToBilOrgModal />
              <p className='flex flex-col items-end'>
                {!acceptanceStats.max_accept_ru &&
                acceptanceStats.max_accept_ru !==
                  acceptanceStats.max_accept_kz ? (
                  <>
                    <span>{`Лимит: ${acceptanceStats.max_accept_kz} ${
                      acceptanceStats.extra_students
                        ? `(+${acceptanceStats.extra_students})`
                        : ''
                    }`}</span>
                    <span>{`Принято: ${acceptanceStats.accepted_kz}`}</span>
                  </>
                ) : (
                  <>
                    <span>{`Лимит (kz): ${acceptanceStats.max_accept_kz} ${
                      acceptanceStats.extra_students
                        ? `(+${acceptanceStats.extra_students})`
                        : ''
                    }`}</span>
                    <span>{`Лимит (ru): ${acceptanceStats.max_accept_ru} ${
                      acceptanceStats.extra_students
                        ? `(+${acceptanceStats.extra_students})`
                        : ''
                    }`}</span>
                    <span>{`Принято (kz): ${acceptanceStats.accepted_kz}`}</span>
                    <span>{`Принято (ru): ${acceptanceStats.accepted_ru}`}</span>
                  </>
                )}
              </p>
            </div>
          </div>
          <div className='max-h-screen items-center gap-4 overflow-x-auto'>
            <table className='w-full table-auto'>
              <thead className='sticky top-0 z-10 border-b bg-gray-800 text-white'>
                <tr>
                  <td className='p-2 font-medium uppercase'>#</td>
                  <td className='border-l p-2 font-medium uppercase'>ЖСН</td>
                  <td className='border-l p-2 font-medium uppercase'>Аты</td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Жөні
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Телефон
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Топ
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    ӘОТ
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Дұрыс
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Қате
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    2 тур
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Олимпиада
                  </td>
                  <td className='w-auto border-l py-2 text-center font-medium uppercase'>
                    1 тур(+)2 тур
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Статус
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Құжат
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Cебеп
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'>
                    Коммент
                  </td>
                  <td className='border-l py-2 text-center font-medium uppercase'></td>
                </tr>
              </thead>
              <tbody className=''>
                {studentsResults.map((student, index) => (
                  <tr
                    key={index}
                    className='border-b last:border-b-0 hover:bg-sheet-gray'
                  >
                    <td className='border-r p-2'>{index + 1}</td>

                    <td className='border-r p-2'>
                      {student?.application?.iin}
                    </td>
                    <td className='border-r p-2'>
                      {student?.application?.first_name}
                    </td>
                    <td className='border-r p-2'>
                      {student?.application?.last_name}
                    </td>

                    <td className='border-r text-center'>
                      {student?.application?.parent_phone_number}
                    </td>
                    <td className='border-r px-2 text-center'>
                      {student?.application?.language === 'K' ? (
                        <span className=''>Қазақша</span>
                      ) : (
                        <span className=''>Русский</span>
                      )}
                    </td>
                    <td className='border-r px-2 text-center'>
                      {student?.is_asp ? (
                        <span className=''>Иә</span>
                      ) : (
                        <span className=''>Жоқ</span>
                      )}
                    </td>
                    <td className='border-r text-center text-green-600'>
                      {student?.total_correct}
                    </td>
                    <td className='border-r text-center text-red-600'>
                      {student?.total_wrong}
                    </td>
                    <td className='border-r bg-gray-800 text-center text-white'>
                      {student?.result}
                    </td>
                    <td className='border-r text-center'>
                      <Select
                        className='w-40 px-1 2xl:w-auto'
                        isSearchable={false}
                        options={[
                          {
                            label: 'Иә / Да',
                            value: 2,
                          },
                          {
                            label: 'Жоқ / Нет',
                            value: 1,
                          },

                          {
                            label: 'Ақпарат жоқ / Нет инфо',
                            value: 0,
                          },
                        ]}
                        value={student?.olympiad_status}
                        onChange={(e) =>
                          updateOlympiadStatus(student?.uuid, e.value)
                        }
                      />
                    </td>
                    <td className='border-r bg-gray-500 text-center text-white'>
                      {student?.total_points}
                    </td>
                    <td className='border-r text-center'>
                      <div className='text-center'>
                        {student?.status === 0 && (
                          <span className='font-semibold text-red-600'>
                            Өтпеді
                          </span>
                        )}
                        {student?.status === 1 && (
                          <span className=''>Резерв</span>
                        )}
                        {student?.status === 2 && (
                          <span className='font-semibold text-green-600'>
                            Өтті
                          </span>
                        )}
                      </div>
                    </td>
                    <td
                      className={`border-r text-center ${
                        student?.acceptance_status === 0
                          ? 'bg-yellow-300'
                          : student?.acceptance_status === 3
                          ? 'bg-green-300'
                          : 'bg-red-300'
                      }`}
                    >
                      <Select
                        className='w-40 px-1 2xl:w-auto'
                        isSearchable={false}
                        options={[
                          {
                            label: 'Қабылданды / Принят',
                            value: 3,
                          },
                          {
                            label: 'Оқудан бас тартты / Отказался учиться',
                            value: 2,
                          },
                          // {
                          //   label: 'Телефон не брал',
                          //   value: 1,
                          // },
                          {
                            label: 'Белгісіз / Неизвестно',
                            value: 0,
                          },
                        ]}
                        value={student?.acceptance_status}
                        onChange={(e) =>
                          receiveStudentDocuments(
                            student?.uuid,
                            e.value,
                            student?.reason,
                          )
                        }
                      />
                    </td>
                    <td className='border-r'>
                      <Select
                        className='w-40 px-1 2xl:w-auto'
                        isSearchable={false}
                        isDisabled={student?.acceptance_status !== 2}
                        options={reasons}
                        value={student?.reason}
                        onChange={(e) =>
                          receiveStudentDocuments(student?.uuid, 2, e.value)
                        }
                      />
                    </td>
                    <td className='border-r px-2'>
                      <div className='flex flex-row items-center justify-around'>
                        <p className='w-40 break-words text-sm'>
                          {student?.comment}
                        </p>

                        <button
                          type='button'
                          className='items-center rounded-md p-1 hover:bg-blue-600 hover:text-white'
                          onClick={() => {
                            setStudentToEdit(student);
                            setModalOpen(true);
                          }}
                        >
                          <PencilIcon className='h-4 ' />
                        </button>
                      </div>
                    </td>
                    <td>
                      <Button
                        type='button'
                        Icon={EyeIcon}
                        className=''
                        onClick={() => {
                          navigate(
                            `/results/${student?.application?.uuid}/pass`,
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {studentsResults?.length < count && (
              <div className='mt-2 text-center'>
                <Button
                  type='button'
                  text='Показать больше'
                  loading={loadingMore}
                  Icon={ChevronDownIcon}
                  onClick={() => {
                    if (count > page * 200) {
                      addStudents();
                    }
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className='text-center text-xl italic text-secondary'>
          нет данных
        </div>
      )}
    </div>
  );
}

function CommentEditModal({
  modalOpen,
  setModalOpen,
  studentToEdit,
  studentsResults,
  setStudentsResults,
}) {
  const { addSnackbar } = useSnackbar();
  const { register, handleSubmit, setValue } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue('comment', studentToEdit?.comment);

    return () => {};
  }, [setValue, studentToEdit]);

  const onSubmit = async (data) => {
    setLoading(true);
    const body = {
      comment: data.comment,
    };

    await patchResult(studentToEdit?.uuid, body)
      .then((res) => {
        const updatedResults = [...studentsResults];
        const resultToUpdate = updatedResults.find(
          (r) => r.uuid === studentToEdit?.uuid,
        );
        resultToUpdate.comment = res.comment;
        setStudentsResults(updatedResults);
        setModalOpen(false);
      })
      .catch((err) => {
        addSnackbar('Ошибка', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Комментарий'}
    >
      <form className='flex flex-col gap-4' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <textarea
            id='about_2'
            name='about_2'
            rows={3}
            className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 disabled:bg-slate-200 sm:text-sm'
            defaultValue={''}
            {...register('comment')}
          />
        </div>
        <Button
          loading={loading}
          type='submit'
          text='Изменить'
          onClick={() => {}}
        />
      </form>
    </CustomDialog>
  );
}
