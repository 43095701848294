import React, { useContext, useState } from 'react';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { Button } from '../../components/forms/Buttons/Button';
import { Controller, useForm } from 'react-hook-form';
import { UserContext } from '../../utils/contexts/UserContext';
import { postTransferStudent } from '../../services/transferStudents/transferBetweenSchoolsService';
import Select from '../../components/forms/Select/Select';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import SearchwithResults from '../../components/SearchWithResults/SearchwithResults';
import useSnackbar from '../../utils/hooks/useSnackbar';

export default function CreateTransferModal({
  schools,
  modalOpen,
  setModalOpen,
  updateList,
}) {
  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
    // watch,
  } = useForm();
  const { user } = useContext(UserContext);
  const { addSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  // const [iin, setIin] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);

  const onSubmit = async (data) => {
    const body = {
      from_school: selectedStudent?.school?.uuid,
      to_school: data.toSchool,
      transferred_by: user?.uuid,
      application: selectedStudent?.uuid,
    };

    if (data.fromSchool === data.toSchool) {
      addSnackbar('Выберите другую школу', 'error');
    } else if (!selectedStudent?.uuid) {
      addSnackbar('Выберите ученика', 'error');
    } else {
      setLoading(true);
      postTransferStudent(body)
        .then(() => {
          updateList();
        })
        .finally(() => {
          setLoading(false);
          setModalOpen(false);
          reset();
          setSelectedStudent(null);
        });
    }
  };

  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Трансфер'}
    >
      <form className='' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-4 p-2'>
          <div className=''>
            <h3 className='font-semibold'>1. Выберите ученика</h3>
            <div className='flex flex-row items-end gap-2'>
              <SearchwithResults selectStudent={setSelectedStudent} />
              {/* <Input
                type='text'
                label='Поиск по ИИН'
                value={iin}
                onChange={(e) => {
                  setIin(e.target.value);
                }}
              /> */}
            </div>
            {selectedStudent && (
              <div className='mt-2 flex flex-row gap-2 rounded-md border-2 border-blue-600 p-2'>
                <div>Ученик: </div>
                <div className='font-semibold'>
                  {`${selectedStudent?.first_name} ${selectedStudent?.last_name} | ${selectedStudent?.iin}`}
                </div>
              </div>
            )}
          </div>
          <div className='border-t pt-2'>
            <h3 className='font-semibold'>2. Выберите школы</h3>

            <div className='flex flex-row items-center justify-between'>
              <Controller
                control={control}
                name='fromSchool'
                render={({ field }) => (
                  <Select
                    label={'Из школы'}
                    errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                    className='w-5/12'
                    value={selectedStudent?.school?.uuid}
                    isDisabled
                    options={schools}
                  />
                )}
              />
              <div className=''>
                <ArrowRightIcon className='h-6' />
              </div>
              <Controller
                control={control}
                name='toSchool'
                render={({ field }) => (
                  <Select
                    label={'В школу'}
                    errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                    className='w-5/12'
                    value={field.value}
                    options={schools}
                    // placeholder={t('announcement.select_positions')}
                    onChange={(e) => {
                      // setSelectedDistrict(e);
                      field.onChange(e.value);
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <Button
          type='submit'
          loading={loading}
          className='mt-4 w-full'
          text={'Создать трансфер'}
        />
      </form>
    </CustomDialog>
  );
}
