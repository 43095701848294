import React from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function showImageOrPdf(docUrl, heightOfDoc) {
  if (docUrl?.substr(docUrl?.length - 4) === '.pdf') {
    return (
      <div className={''}>
        <Document file={docUrl}>
          <Page
            pageNumber={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            height={heightOfDoc * 16}
          />
        </Document>
      </div>
    );
  }

  if (docUrl) {
    return (
      <React.Fragment>
        <div className={''}>
          <Document file={docUrl}>
            <Page
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              height={heightOfDoc * 16}
            />
          </Document>
        </div>

        <img className='h-[8rem] object-cover' src={docUrl} alt='doc' />
      </React.Fragment>
    );
  }

  return <div className='italic'>no image</div>;
}
