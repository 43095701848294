import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { getExamById } from '../../services/exams/examsService';
import { getQuestions } from '../../services/exams/questionService';
import { UserContext } from '../../utils/contexts/UserContext';
import ExaminationForm from './ExaminationForm';
import ExamTextsButton from '../ExaminationStudent/texts/ExamTextsButton';

const ExaminationResult = () => {
  const { user } = useContext(UserContext);
  const [t] = useTranslation();
  const { examUUID } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [exam, setExam] = useState('');
  const [questions, setQuestions] = useState([]);

  const [pickedQuestion, setPickedQuestion] = useState();

  // const userUUID = store((state) => state?.userUUID);
  // const setUserUUID = store((state) => state?.setUserUUID);

  // const userAttempt = store((state) => state?.userAttempt);
  // const setUserAttempt = store((state) => state?.setUserAttempt);

  // const resetZustand = useStore(examUUID)((state) => state?.reset);

  useEffect(() => {
    if (!exam) getExamData(examUUID);
    if (!questions?.lenght) getQuestionsData(examUUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExamData = (uuid) => {
    setIsLoading(true);
    getExamById(uuid)
      ?.then((res) => {
        setExam(res);
        // console.log(res);
      })
      .finally(() => setIsLoading(false));
  };
  const getQuestionsData = (exam) => {
    setIsLoading(true);
    getQuestions(exam)
      ?.then((res) => {
        let q = res?.results
          .filter((q) => {
            // Что бы удалить вопросы казахского или русского
            if (user?.application?.language === 'R') {
              return ![1, 2].includes(q?.type);
            }
            return ![3, 4].includes(q?.type);
          })
          ?.sort((a, b) => (a?.type > b?.type ? 1 : -1)); //поставить по порядку
        setQuestions(q);
        // console.log(q);
      })
      ?.finally(() => setIsLoading(false));
  };
  const setQuestionByIndex = (index) => {
    let q = questions[index];
    setPickedQuestion(q);
  };
  return (
    <div className='flex flex-col gap-5'>
      {isLoading && !exam?.results ? (
        <SimpleLoader className='h-5' />
      ) : exam ? (
        <>
          <div className='px-2 text-center sm:text-lg'>
            {t('mockup.result_summary', {
              stage: exam?.name,
              variant: exam?.variant,
              language:
                user?.application?.language === 'R' ? 'Русский' : 'Казахский',
            })}
          </div>
          <div className='mx-auto flex max-w-fit  flex-col items-stretch justify-center  text-white sm:flex-row'>
            <div className='rounded-t-lg border border-b-0 bg-green-600 p-2 sm:rounded-l-lg sm:rounded-r-none sm:border-r-0'>{`${t(
              'mockup.num_of_correct_1',
            )} ${exam?.result ? exam?.result?.total_correct : 0}`}</div>
            <div className='border-x bg-error p-2 sm:border-y sm:border-x-0'>{`${t(
              'mockup.num_of_wrong_1',
            )} ${exam?.result ? exam?.result?.total_wrong : 0}`}</div>
            <div className='border-x bg-yellow-500 p-2 sm:border-y sm:border-x-0'>{`${t(
              'mockup.num_of_unmarked_1',
            )} ${
              exam?.result
                ? exam?.result?.total_no_answer
                : exam?.questions_count
            }`}</div>
            <div className='rounded-b-lg border border-t-0 p-2 text-slate-900 sm:rounded-l-none sm:rounded-r-lg sm:border-l-0'>{`${t(
              'mockup.total',
            )} ${exam?.result ? exam?.result?.result : 0}`}</div>
          </div>
          <div className='hidden grid-cols-2 place-items-center sm:grid sm:place-content-start'>
            <ExamTextsButton exam={exam} user={user} />
          </div>
          <hr />
          <div>
            <div className='flex  flex-col items-center gap-6 sm:flex-row sm:items-start sm:gap-0'>
              <div className='min-w-fit max-w-fit'>
                <div className='grid grid-cols-5 gap-0.5'>
                  {questions?.map((question, i) => (
                    <Button
                      key={question?.uuid}
                      className={
                        'w-12  ' +
                        (pickedQuestion?.uuid === question?.uuid &&
                          ` ring-4 ring-opacity-60 brightness-75 ring-${
                            !question?.your_answer
                              ? 'yellow-400'
                              : question?.your_answer ===
                                question?.correct_answer
                              ? 'success'
                              : 'error'
                          } `)
                      }
                      color={
                        !question?.your_answer
                          ? 'warning'
                          : question?.your_answer === question?.correct_answer
                          ? 'success'
                          : 'error-solid'
                      }
                      onClick={() => {
                        setPickedQuestion(question);
                      }}
                      text={i + 1}
                    />
                  ))}
                </div>
              </div>
              <div className='grid w-full grid-cols-9 justify-center px-3 sm:pl-20'>
                <ExaminationForm
                  exam={exam}
                  user={user}
                  question={pickedQuestion}
                  setQuestionByIndex={setQuestionByIndex}
                  questionIndex={questions?.findIndex(
                    (question) => question?.uuid === pickedQuestion?.uuid,
                  )}
                  questionLenght={questions?.length}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>{t('mockup.not_exist_or_closed')}</div>
      )}
    </div>
  );
};
export default ExaminationResult;
