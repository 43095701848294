import React, { useState } from 'react';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../components/forms/Buttons/Button';
import Select from '../../components/forms/Select/Select';
import {
  exportClassroomPassesPDF,
  getClassroom,
} from '../../services/application/passesService';
import useSnackbar from '../../utils/hooks/useSnackbar';
import { DocumentArrowDownIcon } from '@heroicons/react/24/solid';

export default function ExportPasses({
  modalOpen,
  setModalOpen,
  examCenters,
  examDates,
}) {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, getValues, control } = useForm();
  const { addSnackbar } = useSnackbar();

  const [classrooms, setClassrooms] = useState([]);
  const [classroomsLoading, setClassroomsLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    await exportClassroomPassesPDF(
      data.examDate,
      data.examCenter,
      data.classroom,
    )
      .then((res) => {
        window.open(res.file, '_blank');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Комментарий'}
    >
      <form className='flex flex-col gap-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex w-full flex-col gap-4'>
          <Controller
            control={control}
            name='examDate'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={'Время экзмена'}
                required
                className='col-span-6'
                value={field.value}
                options={examDates}
                onChange={(e) => {
                  field.onChange(e.value);

                  if (getValues()?.examCenter) {
                    setClassroomsLoading(true);
                    getClassroom(e.value, getValues()?.examCenter)
                      .then((res) => {
                        const options = res?.classrooms
                          ?.sort(function (a, b) {
                            return a - b;
                          })
                          ?.map((cls) => ({
                            label: cls,
                            value: cls,
                          }));
                        setClassrooms(options);
                      })
                      .finally(() => {
                        setClassroomsLoading(false);
                      });
                  }
                }}
              />
            )}
          />
          {/* {examCenters?.length > 0 && ( */}
          <Controller
            control={control}
            name='examCenter'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={'Экзаменационный центр'}
                required
                className=''
                value={field.value}
                options={examCenters}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                  if (getValues()?.examDate) {
                    setClassroomsLoading(true);
                    getClassroom(getValues()?.examDate, e.value)
                      .then((res) => {
                        const options = res?.classrooms
                          ?.sort(function (a, b) {
                            return a - b;
                          })
                          ?.map((cls) => ({
                            label: cls,
                            value: cls,
                          }));
                        setClassrooms(options);
                      })
                      .finally(() => {
                        setClassroomsLoading(false);
                      });
                  } else {
                    addSnackbar('Пожалуйста выберите время экзамена', 'info');
                  }
                }}
              />
            )}
          />
          {/* )} */}
          <Controller
            control={control}
            name='classroom'
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label={'Кабинет'}
                required
                loading={classroomsLoading}
                className=''
                value={field.value}
                options={classrooms}
                isDisabled={classroomsLoading}
                // placeholder={t('announcement.select_positions')}
                onChange={(e) => {
                  // setSelectedDistrict(e);
                  field.onChange(e.value);
                }}
              />
            )}
          />
        </div>
        <div className='flex justify-end'>
          <Button
            loading={loading}
            type='submit'
            Icon={DocumentArrowDownIcon}
            color='success'
            text='Загрузить'
            onClick={() => {}}
          />
        </div>
      </form>
    </CustomDialog>
  );
}
