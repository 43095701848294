import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import CustomDialog from '../../components/modals/CustomDialog/CustomDialog';

const ExamEndButton = ({ header, onConfirm, className }) => {
  const [t] = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        className={`col-span-3  ${className}`}
        color='warning'
        text={t('buttons.finish')}
        onClick={() => setOpen(true)}
      />

      <CustomDialog
        isOpen={open}
        headerText={header}
        closeModal={() => {
          setOpen(false);
        }}
      >
        <div className='flex flex-row justify-end gap-2'>
          <Button
            text={t('buttons.cancel')}
            color='secondary'
            onClick={() => setOpen(false)}
          />
          <Button
            text={t('buttons.finish')}
            color='error'
            onClick={() => onConfirm()}
          />
        </div>
      </CustomDialog>
    </>
  );
};

export default ExamEndButton;
