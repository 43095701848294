import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '../../components/forms/Buttons/Button';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { getExams } from '../../services/exams/examsService';

const formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('-');
};

const ExaminationsView = () => {
  const [t] = useTranslation();
  const [exams, setExams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getExamsData();
  }, []);

  const getExamsData = () => {
    setIsLoading(true);
    getExams()
      .then((res) => {
        // console.log(res.results);
        setExams(res.results);
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className='flex w-full flex-col '>
      {isLoading ? (
        <SimpleLoader className='h-10' />
      ) : (
        <div className='grid grid-cols-1 place-items-center justify-start gap-5 sm:grid-cols-2 sm:place-items-stretch lg:grid-cols-3 xl:grid-cols-4 '>
          {exams?.length ? (
            exams?.map((exam, i) => {
              return (
                <div
                  className='flex  w-[18rem] flex-col rounded-lg border '
                  key={exam?.uuid}
                >
                  <p className='bg-slate-200 py-3 px-5'>
                    {i + 1}. {exam?.name}
                  </p>
                  <div className='flex h-full flex-col items-start justify-start p-5'>
                    <p className='mb-5'>
                      {t('mockup.option')}: {exam?.variant}
                    </p>
                    <p>
                      {t('mockup.duration')}: {exam?.duration}
                    </p>
                    <p>
                      {t('mockup.num_of_questions')}: {exam?.questions_count}
                    </p>
                    {!!exam?.result ? (
                      <>
                        <p>
                          {t('mockup.num_of_correct')}:{' '}
                          {exam?.result?.total_correct}
                        </p>
                        <p>
                          {t('mockup.num_of_wrong')}:{' '}
                          {exam?.result?.total_wrong}
                        </p>
                        <p>
                          {t('mockup.num_of_unmarked')}:{' '}
                          {exam?.result?.total_no_answer}
                        </p>
                        <p>
                          {t('mockup.total')}: {exam?.result?.result}
                        </p>
                        <Link to={`/student/exam/${exam?.uuid}/result`}>
                          <Button
                            className='mt-2 max-w-fit'
                            color='success'
                            text={t('student.results')}
                          />
                        </Link>
                      </>
                    ) : !exam?.result &&
                      !exam?.attempt?.is_in_progress &&
                      !!exam?.attempt ? (
                      <>
                        <p>
                          {t('mockup.num_of_correct')}: {0}
                        </p>
                        <p>
                          {t('mockup.num_of_wrong')}: {0}
                        </p>
                        <p>
                          {t('mockup.num_of_unmarked')}: {exam?.questions_count}
                        </p>
                        <p>
                          {t('mockup.total')}: {0}
                        </p>
                        <Link to={`/student/exam/${exam?.uuid}/result`}>
                          <Button
                            className='mt-2 max-w-fit'
                            color='success'
                            text={t('student.results')}
                          />
                        </Link>
                      </>
                    ) : (
                      <Link to={`/student/exam/${exam?.uuid}`}>
                        <Button
                          className='mt-2 max-w-fit'
                          text={t(
                            !!exam?.attempt
                              ? 'buttons.continue'
                              : 'buttons.begin',
                          )}
                          color={!!exam?.attempt && 'success'}
                        />
                      </Link>
                    )}
                  </div>
                  <p className='bg-slate-200 py-3 px-5 text-xs'>{`${t(
                    'mockup.finished_time',
                  )}: ${
                    exam?.result
                      ? formatDate(exam?.result?.created)
                      : t('mockup.not_passed')
                  }`}</p>
                </div>
              );
            })
          ) : (
            <div className='col-span-1 flex flex-col items-center sm:col-span-2  lg:col-span-3 xl:col-span-4'>
              <div className=' rounded-md bg-yellow-200 p-4'>
                <p className='text-2xl font-semibold'>
                  {t('mockup.exams_is_not_avaible')}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExaminationsView;
