import React, { useContext, useEffect, useState } from 'react';
import Select from '../../components/forms/Select/Select';
import Table from '../../components/Table/Table';
import { getRegions } from '../../services/catalog/catalog';
import { getSchoolsExamTimeStats } from '../../services/schoolStatistics/schoolStatistics';
import { UserContext } from '../../utils/contexts/UserContext';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { getExamDates } from '../../services/core/coreService';

export default function SchoolExamTimeStats() {
  const { user } = useContext(UserContext);
  const { examYear } = useContext(ExamYearContext);

  const [schoolStatistics, setSchoolStatistics] = useState([]);

  const [regions, setRegions] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (examYear?.uuid) {
      setLoading(true);
      getExamDates(examYear.uuid).then((res) => {
        setExams(res.results.sort((a, b) => (a.name > b.name ? 1 : -1)));
      });
      getSchoolsExamTimeStats({ exam_year: examYear.uuid, page_size: 60 })
        .then((res) => {
          setSchoolStatistics(res.results);
        })
        .finally(() => {
          setLoading(false);
        });

      getRegions().then((res) => {
        setRegions(res.results);
      });
    }
    return () => {};
  }, [examYear]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'school_id',
      },
      {
        Header: 'Название',
        accessor: 'name',
      },
      ...exams.map((e) => ({ Header: e.name, accessor: `${e.uuid}_count` })),
    ];
  }, [exams]);

  return !loading ? (
    <div className='flex flex-col gap-4'>
      <div>
        <Select
          className={`h-6 w-48 ${
            user?.roles?.some((role) => role?.code.includes('RC')) && 'hidden'
          }`}
          placeholder={'Регион'}
          value={selectedRegion}
          options={[
            { label: 'Все регионы', value: '' },
            ...regions.map((region, i) => ({
              label: region?.name,
              value: region?.uuid,
            })),
          ]}
          onChange={(e) => {
            setSelectedRegion(e.value);
            getSchoolsExamTimeStats({
              exam_year: examYear?.uuid,
              page_size: 50,
              region: e.value,
            }).then((res) => {
              setSchoolStatistics(res.results);
            });
          }}
        />
      </div>
      <Table columns={columns} data={schoolStatistics} />
    </div>
  ) : (
    <SimpleLoader className='h-16' />
  );
}
