import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../utils/contexts/UserContext';
import Table from '../../components/Table/Table';
import { Button } from '../../components/forms/Buttons/Button';
import { getDistrictsFiles } from '../../services/catalog/districts';
import { useNavigate } from 'react-router-dom';

export default function DistrictRegistration() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [count, setCount] = useState(0);
  const [aspLink, setAspLink] = useState(null);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Район ID',
        accessor: 'district.district_id',
      },
      {
        Header: 'Название района',
        accessor: 'district.name',
      },
      {
        Header: 'Экзаменационный центр',
        accessor: 'district.is_exam_center',
        Cell: (props) => (
          <div className=''>
            {props.value ? (
              <span className='rounded-md bg-green-600 px-2 py-1 text-white'>
                Да
              </span>
            ) : (
              <span className='rounded-md bg-red-600 px-2 py-1 text-white'>
                Нет
              </span>
            )}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'file_excel',
        fixed: 'right',
        Cell: (props) => (
          <div className='flex gap-2'>
            <Button
              color='success'
              text='xslx'
              onClick={() => {
                window.open(props.value);
              }}
            />
            {/* <Button color='error-solid' text='pdf' onClick={() => {}} /> */}
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'file_pdf',
        fixed: 'right',
        Cell: (props) => (
          <div className='flex gap-2'>
            {/* <Button color='success' text='xslx' onClick={() => {}} /> */}
            <Button
              color='error-solid'
              text='pdf'
              onClick={() => {
                window.open(props.value);
              }}
            />
          </div>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (user?.school?.uuid) {
      setLoading(true);
      getDistrictsFiles({
        school: user?.school?.uuid,
        page_size: 50,
        is_exam_center: true,
      })
        .then((res) => {
          setAspLink(res.results[0]?.inst_file_pdf);
          setDistricts(res.results);
          setCount(res.count);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {};
  }, [user]);

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row justify-between'>
        <Button
          color='primary'
          text='Добавить excel'
          onClick={() => {
            navigate('upload');
          }}
        />
        <Button
          text='ӘОТ инфо'
          onClick={() => {
            window.open(aspLink);
          }}
        />
      </div>
      <Table
        columns={columns}
        data={districts}
        count={count}
        pageNumber={1}
        loading={loading}
        pageCount={1}
      />
    </div>
  );
}
