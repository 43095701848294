import React from 'react';
import { useTranslation } from 'react-i18next';

import star from '../images/star.png';
import bil_girl_with_rectangle from '../images/bil_girl_with_rectangle.png';
import bil_guys from '../images/bil_guys.png';

const ContactsSection = () => {
  const [t] = useTranslation();
  return (
    <div className='flex w-screen  flex-col gap-16 bg-yellow-landing pt-16 pb-8'>
      <div className='hidden w-screen -translate-y-10 flex-row justify-center lg:hidden'>
        <div className='flex flex-col justify-end px-6 text-3xl'>
          <p className='text-center'>{t('welcome.call_center')}</p>
          <p className='text-center font-sfproBold text-3xl lg:text-6xl'>
            +7 700 332 32 32
          </p>
          <div className='mt-4 flex flex-row justify-center gap-4'>
            <a href='https://api.whatsapp.com/send?phone=77003323232&text=%D0%A1%D3%99%D0%BB%D0%B5%D0%BC%D0%B5%D1%82%D1%81%D1%96%D0%B7%20%D0%B1%D0%B5!%20%D0%91%D0%98%D0%9B%20%D2%9B%D0%B0%D0%B1%D1%8B%D0%BB%D0%B4%D0%B0%D1%83%20%D0%B5%D0%BC%D1%82%D0%B8%D1%85%D0%B0%D0%BD%D1%8B%D0%BD%D0%B0%20%D0%B1%D0%B0%D0%B9%D0%BB%D0%B0%D0%BD%D1%8B%D1%81%D1%82%D1%8B%20%D1%81%D2%B1%D1%80%D0%B0%D2%93%D1%8B%D0%BC%20%D0%B1%D0%B0%D1%80%20%D0%B5%D0%B4%D1%96.%0A%0A%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%95%D1%81%D1%82%D1%8C%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83%20%D0%B2%D1%81%D1%82%D1%83%D0%BF%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D1%8D%D0%BA%D0%B7%D0%B0%D0%BC%D0%B5%D0%BD%D0%BE%D0%B2%20%D0%B2%20%D0%91%D0%98%D0%9B.'>
              <svg
                className='h-8 fill-black-landing lg:h-10'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
              </svg>
            </a>
            <a href='https://t.me/biledu_bot'>
              <svg
                className='h-8 fill-black-landing lg:h-10'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 496 512'
              >
                <path d='M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z'></path>
              </svg>
            </a>
            <a href='https://www.instagram.com/bil.edu.kz/'>
              <svg
                className='h-8 fill-black-landing lg:h-10'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path
                  className='h-8 fill-black-landing'
                  d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className='flex w-screen -translate-y-10  flex-col justify-center md:flex md:translate-x-16 md:flex-row'>
        <div className='hidden  flex-row items-end justify-center md:flex'>
          <img
            className='w-44'
            src={bil_girl_with_rectangle}
            alt='bil student'
          />
          <svg
            className=' flex -translate-y-28 -translate-x-7'
            width='81'
            height='81'
            viewBox='0 0 81 81'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M40.1654 0L54.3603 25.9751L80.3307 40.1841L54.3603 54.3857L40.1654 80.3682L25.9629 54.3857L0 40.1841L25.9629 25.9751L40.1654 0Z'
              fill='white'
            />
          </svg>
        </div>
        <div className='flex flex-col items-center md:hidden'>
          <svg
            className='translate-y-4'
            width='81'
            height='81'
            viewBox='0 0 81 81'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M40.1654 0L54.3603 25.9751L80.3307 40.1841L54.3603 54.3857L40.1654 80.3682L25.9629 54.3857L0 40.1841L25.9629 25.9751L40.1654 0Z'
              fill='white'
            />
          </svg>
          <img
            className='-translate-x-20'
            height={64}
            width={64}
            src={star}
            alt='white start'
          />
        </div>
        <div className='flex flex-col justify-end text-3xl md:w-[37.5rem] lg:text-5xl'>
          <p className='text-center'>{t('welcome.call_center')}</p>
          <p className='text-center font-sfproBold text-3xl lg:text-6xl'>
            +7 700 332 32 32
          </p>
          <div className='mt-4 flex flex-row justify-center gap-4'>
            <a href='https://api.whatsapp.com/send?phone=77003323232&text=%D0%A1%D3%99%D0%BB%D0%B5%D0%BC%D0%B5%D1%82%D1%81%D1%96%D0%B7%20%D0%B1%D0%B5!%20%D0%91%D0%98%D0%9B%20%D2%9B%D0%B0%D0%B1%D1%8B%D0%BB%D0%B4%D0%B0%D1%83%20%D0%B5%D0%BC%D1%82%D0%B8%D1%85%D0%B0%D0%BD%D1%8B%D0%BD%D0%B0%20%D0%B1%D0%B0%D0%B9%D0%BB%D0%B0%D0%BD%D1%8B%D1%81%D1%82%D1%8B%20%D1%81%D2%B1%D1%80%D0%B0%D2%93%D1%8B%D0%BC%20%D0%B1%D0%B0%D1%80%20%D0%B5%D0%B4%D1%96.%0A%0A%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%95%D1%81%D1%82%D1%8C%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83%20%D0%B2%D1%81%D1%82%D1%83%D0%BF%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D1%8D%D0%BA%D0%B7%D0%B0%D0%BC%D0%B5%D0%BD%D0%BE%D0%B2%20%D0%B2%20%D0%91%D0%98%D0%9B.'>
              <svg
                className='h-8 fill-black-landing lg:h-10'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
              </svg>
            </a>
            <a href='https://t.me/biledu_bot'>
              <svg
                className='h-8 fill-black-landing  lg:h-10'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 496 512'
              >
                <path d='M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z'></path>
              </svg>
            </a>
            <a href='https://www.instagram.com/bil.edu.kz/'>
              <svg
                className='h-8 fill-black-landing  lg:h-10'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
              >
                <path d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z' />
              </svg>
            </a>
          </div>
        </div>
        <div className='hidden w-32 -translate-x-16 -translate-y-32 flex-col justify-end md:flex'>
          <img className='blur' src={star} alt='star' />
        </div>
        <div className='hidden -translate-x-20 translate-y-48 flex-col justify-end md:flex'>
          <img width={300} height={380} src={bil_guys} alt='bil students' />
        </div>
      </div>
      <div className=' flex flex-row items-start justify-center md:-translate-x-14 md:justify-evenly'>
        <div className='hidden w-32 md:flex'>
          <img src={star} alt='star' />
        </div>
        <div className='flex flex-col text-center'></div>
        <div className='hidden w-32 md:flex'></div> {/*Заглушка*/}
      </div>
    </div>
  );
};

export default ContactsSection;
