import React, { useEffect, useState } from 'react';
import StatsTable from './StatsTable';
import {
  ArrowUpRightIcon,
  ArrowDownRightIcon,
  Bars2Icon,
} from '@heroicons/react/24/solid';
import { RadioGroup } from '@headlessui/react';
import Select from '../../components/forms/Select/Select';
import DatePicker from '../../components/forms/DatePicker/DatePicker';
import moment from 'moment';

export default function DistrictCompareTable({
  data,
  loading,
  schools,
  regions,
  getData,
}) {
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [selectedSchool, setSelectedSchool] = useState(schools[0]?.value);
  const [schoolList, setSchoolList] = useState(schools);
  const [selectedDay, setSelectedDay] = useState(new Date());

  const minDate = new Date();
  minDate.setDate(1);
  minDate.setMonth(1);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Район',
        accessor: 'name',
      },
      {
        Header: 'Статус',
        accessor: 'isIncreased',
        Cell: (props) => (
          <div className=' font-bold'>
            {props.value === 0 && (
              <ArrowDownRightIcon className='h-6 text-red-600' />
            )}
            {props.value === 1 && (
              <ArrowUpRightIcon className='h-6 text-green-600' />
            )}
            {props.value === 2 && <Bars2Icon className='h-6' />}
          </div>
        ),
      },
      {
        Header: '2023',
        accessor: 'y_2023',
      },
      {
        Header: '2024',
        accessor: 'y_2024',
      },
    ],
    [],
  );

  useEffect(() => {
    if (schools?.length) {
      setSchoolList(schools);
      setSelectedSchool(schools[0].value);
    }

    return () => {
      setSchoolList([]);
    };
  }, [schools]);

  const filterSchools = (regionUuid) => {
    setSelectedRegion(regionUuid);
    if (regionUuid === 'all') {
      setSchoolList(schools);
    } else if (regionUuid) {
      const filtered = schools.filter((s) => s?.region === regionUuid);
      setSchoolList(filtered);
    }
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row gap-4'>
        {false && (
          <RadioGroup
            className='flex flex-row gap-2 text-sm font-semibold'
            value={selectedRegion}
            onChange={(e) => filterSchools(e)}
          >
            <RadioGroup.Option value='all'>
              {({ checked }) => (
                <span
                  className={
                    checked
                      ? 'cursor-pointer rounded-md bg-blue-600 px-4 py-2 text-white'
                      : 'cursor-pointer rounded-md bg-gray-200 px-4 py-2'
                  }
                >
                  Все регионы
                </span>
              )}
            </RadioGroup.Option>
            {regions?.map((r) => (
              <RadioGroup.Option key={r?.value} value={r?.value}>
                {({ checked }) => (
                  <span
                    className={
                      checked
                        ? 'cursor-pointer rounded-md bg-blue-600 px-4 py-2 text-white'
                        : 'cursor-pointer rounded-md bg-gray-200 px-4 py-2'
                    }
                  >
                    {r?.label}
                  </span>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        )}
        <div className='flex flex-row gap-2 border-r-2 border-gray-800 pr-2'>
          <Select
            label='Школы'
            value={selectedSchool}
            className='w-80'
            options={schoolList}
            onChange={(e) => {
              setSelectedSchool(e.value);
              getData(e.value, moment(selectedDay).format('DD.MM'));
            }}
          />
          <Select
            label='Школы по региону:'
            value={selectedRegion}
            isSearchable={false}
            options={[{ label: 'Все регионы', value: 'all' }, ...regions]}
            onChange={(e) => {
              filterSchools(e.value);
            }}
          />
        </div>
        <div>
          <DatePicker
            className='w-64'
            label={'Сравнение до:'}
            selected={selectedDay}
            onChange={(date) => {
              setSelectedDay(date);
              getData(selectedSchool, moment(date).format('DD.MM'));
            }}
            // placeholderText={t('buttons.select')}
            dateFormat='dd / MM / yyyy'
            minDate={minDate}
          />
        </div>
      </div>
      <StatsTable columns={columns} data={data} loading={loading} />
    </div>
  );
}
