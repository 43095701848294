import React from 'react';
import { useTranslation } from 'react-i18next';

const TutorialSection = () => {
  const [t] = useTranslation();
  return (
    <div className='flex w-screen  flex-col items-center bg-white py-16  '>
      <div className='flex flex-col items-center gap-12 px-4 sm:w-[39.625rem] sm:px-0 xl:w-[80.25rem]'>
        <p className=' px-5 text-center text-3xl sm:px-0 sm:text-left sm:text-5xl'>
          {t('welcome.tutorial_1')}
          <span className='text-brown'>{t('welcome.tutorial_2')}</span>
          {t('welcome.tutorial_3')}
        </p>
        <div className='flex flex-col gap-4 xl:flex-row'>
          <iframe
            className='sm:h-[22.25rem] sm:w-[39.625rem]'
            src='https://www.youtube.com/embed/3M9UkyFeF8M'
            title='Видео-инструкция'
          />
          <iframe
            className='sm:h-[22.25rem] sm:w-[39.625rem]'
            src='https://www.youtube.com/embed/HJHL3p3ADAc'
            title='Видео-инструкция(рус)'
          />
        </div>
      </div>
    </div>
  );
};

export default TutorialSection;
