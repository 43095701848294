import React from 'react';

const BoysIcon = () => (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_37_3266)'>
      <path
        d='M49.7705 0H10C4.47715 0 0 4.47716 0 10V49.7984C0 55.3212 4.47715 59.7984 10 59.7984H49.7705C55.2933 59.7984 59.7705 55.3212 59.7705 49.7984V10C59.7705 4.47715 55.2933 0 49.7705 0Z'
        fill='#111233'
      />
      <path
        d='M59.6717 29.8522L29.9946 0.161133L0.317383 29.8522L29.9946 59.5432L59.6717 29.8522Z'
        fill='#6C8CE4'
      />
      <path
        d='M30.1385 14.7256L35.3931 24.3357L44.9987 29.5927L35.3931 34.8497L30.1385 44.4598L24.8839 34.8497L15.2783 29.5927L24.8839 24.3357L30.1385 14.7256Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_37_3266'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const GirlsIcon = () => (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_36_3262)'>
      <path
        d='M49.6552 0H10.3218C4.85959 0 0.431641 4.47716 0.431641 10V49.7984C0.431641 55.3212 4.85959 59.7984 10.3218 59.7984H49.6552C55.1173 59.7984 59.5453 55.3212 59.5453 49.7984V10C59.5453 4.47715 55.1173 0 49.6552 0Z'
        fill='#111233'
      />
      <path
        d='M59.4482 29.8522L30.0972 0.161133L0.746094 29.8522L30.0972 59.5432L59.4482 29.8522Z'
        fill='#ED7051'
      />
      <path
        d='M30.2388 14.7256L35.4356 24.3357L44.9357 29.5927L35.4356 34.8497L30.2388 44.4598L25.042 34.8497L15.542 29.5927L25.042 24.3357L30.2388 14.7256Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_36_3262'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const MixedIcon = () => (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_37_3275)'>
      <path
        d='M49.9785 0H10.208C4.68516 0 0.208008 4.47716 0.208008 10V49.7984C0.208008 55.3212 4.68515 59.7984 10.208 59.7984H49.9785C55.5013 59.7984 59.9785 55.3212 59.9785 49.7984V10C59.9785 4.47715 55.5013 0 49.9785 0Z'
        fill='#111233'
      />
      <path
        d='M59.8798 29.8522L30.2025 0.161133L0.525391 29.8522L30.2025 59.5432L59.8798 29.8522Z'
        fill='#C9B291'
      />
      <path
        d='M30.3455 14.7256L35.6001 24.3357L45.2057 29.5927L35.6001 34.8497L30.3455 44.4598L25.091 34.8497L15.4854 29.5927L25.091 24.3357L30.3455 14.7256Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_37_3275'>
        <rect width='60' height='60' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const SchoolCardsByRegion = ({ region, t }) => {
  return (
    <div className='flex flex-row flex-wrap justify-center gap-2'>
      {region === '03' && ( // Astana, Akmola
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.astana_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7172 53 88 54 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77051315602'
                >
                  <span className='text-primary hover:underline '>
                    +7 705 131 56 02{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.astana_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.astana_kyz')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77071496980'
                >
                  <span className='text-primary hover:underline '>
                    +7 707 149 69 80{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.astana_kyz_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.kokshetau_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77162328372'
                >
                  <span className='text-primary hover:underline '>
                    +7 7162 32 83 72{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.kokshetau_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <MixedIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.shuchinsk_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7163 62 11 45</span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77711134097'
                >
                  <span className='text-primary hover:underline '>
                    +7 771 113 40 97{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.shuchinsk_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '04' && ( //Aqtobe
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.aqtobe_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77132467177'
                >
                  <span className='text-primary hover:underline '>
                    +7 7132 46 71 77{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.aqtobe_bil_address')}</p>
            </div>
          </div>
          {/* <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.aqtobe_kyz')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77071496980'
                >
                  <span className='text-primary hover:underline '>
                    +7 707 149 69 80{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.aqtobe_kyz_address')}</p>
            </div>
          </div> */}
        </>
      )}
      {region === '05' && ( //Almaty, Almaty oblast
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.almaty_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 727 230 69 43 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77066760630'
                >
                  <span className='text-primary hover:underline '>
                    +7 706 676 06 30{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.almaty_bil_address')}</p>
            </div>
          </div>

          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.esik_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77074983456'
                >
                  <span className='text-primary hover:underline '>
                    +7 707 498 34 56{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>

              <p>{t('welcome.esik_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '06' && ( // Atyrau
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.atyrau_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77781337788'
                >
                  <span className='text-primary hover:underline '>
                    +7 778 133 77 88{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>

              <p>{t('welcome.atyrau_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.atyrau_kyz')}</p>
              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7122 30 43 55</span>

                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77788715384'
                >
                  <span className='text-primary hover:underline '>
                    +7 778 871 53 84{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.atyrau_kyz_address')}</p>
            </div>
          </div>
          {/* <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.kulsary_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77474016997'
                >
                  <span className='text-primary hover:underline '>
                    +7 747 401 69 97{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.kulsary_bil_address')}</p>
            </div>
          </div> */}
        </>
      )}
      {region === '07' && ( //ZKO
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.oral_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77064301046'
                >
                  <span className='text-primary hover:underline '>
                    +7 706 430 10 46
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.oral_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '08' && ( // Zhambyl
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.taraz_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7262 45 14 15 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77781717379'
                >
                  <span className='text-primary hover:underline '>
                    +7 778 171 73 79{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.taraz_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.taraz_kyz')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7262 57 74 66 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77471020584'
                >
                  <span className='text-primary hover:underline '>
                    +7 747 102 05 84{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.taraz_kyz_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '09' && ( //Karaganda
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.karagandy_bil')}</p>
              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77713586138'
                >
                  <span className='text-primary hover:underline '>
                    +7 771 358 61 38{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.karagandy_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.karagandy_kyz')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7212 50 22 20</span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77765022206'
                >
                  <span className='text-primary hover:underline '>
                    +7 776 502 22 06{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.karagandy_kyz_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '10' && ( // Kostanay
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.kostanay_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77142550411'
                >
                  <span className='text-primary hover:underline '>
                    +7 7142 55 04 11{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.kostanay_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '11' && ( //Kyzylorda
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.kyzylorda_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7242 21 24 92 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77072550009'
                >
                  <span className='text-primary hover:underline '>
                    +7 707 255 00 09{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.kyzylorda_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.kyzylorda_kyz')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7242 70 00 80 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77072550010'
                >
                  <span className='text-primary hover:underline '>
                    +7 707 255 00 10{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.kyzylorda_kyz_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '12' && ( //Mangystau
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.aqtau_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7292 33 84 27</span>
              </div>
              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77292338429'
                >
                  <span className='text-primary hover:underline '>
                    +7 7292 33 84 29
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.aqtau_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.zhanaozen_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7293 45 59 76 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77476708744'
                >
                  <span className='text-primary hover:underline '>
                    +7 747 670 87 44{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.zhanaozen_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '13' && ( //Shymkent, Turkestan oblast
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.shymkent_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7252 32 11 56 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77002359389'
                >
                  <span className='text-primary hover:underline '>
                    +7 700 235 93 89{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.shymkent_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.shymkent_kyz')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7252 57 66 62 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77476356734'
                >
                  <span className='text-primary hover:underline '>
                    +7 747 635 67 34{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.shymkent_kyz_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.turkestan_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77027908505'
                >
                  <span className='text-primary hover:underline '>
                    +7 702 790 85 05{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.turkestan_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.turkestan_kyz_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77027908505'
                >
                  <span className='text-primary hover:underline '>
                    +7 702 790 85 05{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.turkestan_kyz_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.arys_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7254 02 16 54 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77055661640'
                >
                  <span className='text-primary hover:underline '>
                    +7 705 566 16 40{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.arys_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '14' && ( // Pavlodar
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.pavlodar_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7182 31 03 62 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77000310362'
                >
                  <span className='text-primary hover:underline '>
                    +7 700 031 03 62{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.pavlodar_bil_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <GirlsIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.pavlodar_kyz')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77182607198'
                >
                  <span className='text-primary hover:underline '>
                    +7 7182 60 71 98{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.pavlodar_kyz_address')}</p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.ekibastuz_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7187 75 02 08</span>

                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77751405364'
                >
                  <span className='text-primary hover:underline '>
                    +7 775 140 53 64{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.ekibastuz_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '15' && ( //SKO
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <MixedIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.petropavl_bil')}</p>

              <div className='items-left flex flex-col text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77152517231'
                >
                  <span className='text-primary hover:underline '>
                    +7 7152 51 72 31{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.petropavl_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '16' && ( //VKO
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.oskemen_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 723 225 29 62 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77051875801'
                >
                  <span className='text-primary hover:underline '>
                    +7 705 187 58 01{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.oskemen_bil_address')}</p>
            </div>
          </div>
        </>
      )}

      {region === '18' && ( //Abay
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.semey_bil')}</p>
              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77222771576'
                >
                  <span className='text-primary hover:underline '>
                    +7 722 277 15 76{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.semey_bil_address')}</p>
            </div>
          </div>
        </>
      )}
      {region === '19' && ( //Zhetisu
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.taldykorgan_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7282 22 26 69 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77002244904'
                >
                  <span className='text-primary hover:underline '>
                    +7 700 224 49 04{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.taldykorgan_bil_address')}</p>
            </div>
          </div>
        </>
      )}

      {region === '20' && ( // Ulutau
        <>
          <div className='flex flex-row items-center gap-5 rounded-xl py-5 px-4 shadow-xl sm:px-8'>
            <BoysIcon />

            <div className='flex flex-col  text-left text-secondary'>
              <p>{t('welcome.zhezkazgan_bil')}</p>

              <div className='items-left flex flex-col text-left text-xl text-slate-900 sm:text-2xl'>
                <span>+7 7102 41 00 99 </span>
                <a
                  className='flex flex-row items-center gap-2 '
                  href='https://api.whatsapp.com/send?phone=77066371924'
                >
                  <span className='text-primary hover:underline '>
                    +7 706 637 19 24{' '}
                  </span>
                  <svg
                    className='h-6 fill-success '
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 448 512'
                  >
                    <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'></path>
                  </svg>
                </a>
              </div>
              <p>{t('welcome.zhezkazgan_bil_address')}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SchoolCardsByRegion;
