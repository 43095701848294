/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { getExamById } from '../../services/exams/examsService';
import {
  getQuestions,
  patchQuestion,
} from '../../services/exams/questionService';
import { EXAMTYPES } from '../../utils/constants/constants';
import Add from './BREAD/Add';
import { Button } from '../../components/forms/Buttons/Button';
import Input from '../../components/forms/Input/Input';
import {
  CheckIcon,
  PencilSquareIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import QuestionCard from './QuestionCard';

const Questions = () => {
  const { examUUID } = useParams();
  const [exam, setExam] = useState();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [keys, setKeys] = useState('');
  const [tmpKeys, setTmpKeys] = useState('');
  const [editKeys, setEditKeys] = useState(false);
  const inputRef = useRef();
  // const { addSnackbar } = useSnackbar();

  useEffect(() => {
    getExamById(examUUID)
      ?.then((res) => {
        setExam(res);
      })
      ?.finally(() => {
        getQuestionsData(examUUID);
      });
  }, [examUUID]);

  const getQuestionsData = () => {
    setIsLoading(true);
    getQuestions(examUUID)
      ?.then((res) => {
        setQuestions(res?.results);
        const tmp = res?.results
          ?.map((q) => (q.correct_answer ? q.correct_answer : ' '))
          ?.join('');
        setKeys(tmp);
        setTmpKeys(tmp);
      })
      ?.finally(() => setIsLoading(false));
  };

  const updateKeys = () => {
    let c = 0;
    let loaded = 0;
    setIsLoading(true);
    Array.from(tmpKeys.toUpperCase()).forEach((e, i) => {
      if (e !== keys[i]) {
        c++;
        patchQuestion(questions[i].uuid, { correct_answer: e })?.finally(() => {
          if (++loaded === c) {
            setIsLoading(false);
            getQuestionsData();
          }
        });
      }
    });
    if (!c) setIsLoading(false);
  };

  return (
    <div className='flex w-full flex-col gap-2'>
      {isLoading ? (
        <SimpleLoader className='h-5' />
      ) : (
        <>
          <p className='text-center text-3xl font-bold'>{`Вопросы экзамена: ${
            exam?.name
          } | Тип: ${EXAMTYPES[exam?.type]} | Вариант: ${exam?.variant}`}</p>
          <div className='flex w-full flex-col border-b-2 border-slate-300' />
          <center>
            <div className='flex flex-row justify-center'>
              <Input
                ref={inputRef}
                value={tmpKeys}
                placeholder={'Enter keys'}
                className='min-w-[45rem] rounded-l-lg border'
                disabled={!editKeys}
                inputClassName='rounded-r-none focus:outline-none uppercase'
                onChange={(v) => {
                  let text = v.target.value?.replace(/\s/g, '');
                  if (text?.length <= 70) {
                    setTmpKeys(text);
                  } else {
                    setTmpKeys(text?.slice(0, 70));
                  }
                }}
              />
              <Button
                type='button'
                className={`rounded-l-none ${editKeys && 'rounded-r-none'}`}
                color={editKeys && 'error'}
                Icon={editKeys ? XMarkIcon : PencilSquareIcon}
                onClick={() => {
                  if (editKeys) setTmpKeys(keys);
                  setEditKeys((v) => !v);
                }}
              />
              <Button
                type='button'
                className={`rounded-l-none ${!editKeys && 'invisible'}`}
                color={'success-simple'}
                Icon={CheckIcon}
                onClick={() => {
                  updateKeys();
                  setEditKeys((v) => !v);
                }}
              />
              <p className='py-2 pl-10 text-center'>{`${tmpKeys?.length}/${questions.length}`}</p>
            </div>
          </center>
          <div className='flex w-full flex-col border-b-2 border-slate-300' />
          <center>
            <div className='grid max-w-fit grid-cols-5 gap-2'>
              {questions?.map((question, i) => {
                return (
                  <QuestionCard
                    key={question?.uuid}
                    question={question}
                    index={i}
                    getData={getQuestionsData}
                  />
                );
              })}
              <Add
                className='w-56 text-2xl sm:h-36'
                exam={examUUID}
                questionId={questions?.length + 1}
                getData={getQuestionsData}
              />
            </div>
          </center>
        </>
      )}
    </div>
  );
};

export default Questions;
