import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Button } from '../../components/forms/Buttons/Button';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import { patchAttempt, postAttempt } from '../../services/exams/attemptService';
import { getExamById } from '../../services/exams/examsService';
import { getQuestions } from '../../services/exams/questionService';
import { postResult } from '../../services/exams/resultExamService';
import { UserContext } from '../../utils/contexts/UserContext';
import useSnackbar from '../../utils/hooks/useSnackbar';
import ExamEndButton from './ExamEndButton';
import ExaminationForm from './ExaminationFrom';
import ExamTextsButton from './texts/ExamTextsButton';
import Timer from './Timer';

const log = (config) => (set, get, api) =>
  config(
    (...args) => {
      // console.log('  applying', args);
      set(...args);
      // console.log('  new state', get());
    },
    get,
    api,
  );

const initialState = {
  startTime: null,
  userAttempt: {},
  userUUID: '',
  //
  exam: '',
  examState: '',
  questions: [],
  answers: {},
};

const useStore = (examUUID) =>
  create(
    persist(
      log((set, get) => ({
        ...initialState,
        reset: () => {
          set({ ...initialState });
        },
        setUserUUID: (userUUID) => {
          set({ userUUID });
        },
        setStartTime: (startTime) => set({ startTime }),
        setUserAttempt: (userAttempt) => set({ userAttempt: userAttempt }),
        setExam: (exam) => set({ exam }),
        setExamState: (examState) => set({ examState }),
        setQuestions: (questions) => set({ questions }),
        setAnswers: (answers) => set({ answers }),
      })),
      {
        name: examUUID, // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      },
    ),
  );

const ExaminationStudentForm = () => {
  const { user } = useContext(UserContext);
  const { addSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const { examUUID } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // const [startTime, setStartTime] = useState();
  // const [exam, setExam] = useState('');
  // const [examState, setExamState] = useState('');\
  // const [questions, setQuestions] = useState([]);
  // const [answers, setAnswers] = useState({});

  const [pickedQuestion, setPickedQuestion] = useState();

  const [answer, setAnswer] = useState();

  const store = useStore(examUUID);

  const [startTime, setStartTime] = store((state) => [
    state?.startTime,
    state?.setStartTime,
  ]);
  const [examState, setExamState] = store((state) => [
    state?.examState,
    state?.setExamState,
  ]);

  // const userUUID = store((state) => state?.userUUID);
  // const setUserUUID = store((state) => state?.setUserUUID);

  // const userAttempt = store((state) => state?.userAttempt);
  // const setUserAttempt = store((state) => state?.setUserAttempt);
  const exam = store((state) => state?.exam);
  const setExam = store((state) => state?.setExam);
  const questions = store((state) => state?.questions);
  const setQuestions = store((state) => state?.setQuestions);
  const answers = store((state) => state?.answers);
  const setAnswers = store((state) => state?.setAnswers);

  const resetZustand = useStore(examUUID)((state) => state?.reset);

  useEffect(() => {
    if (exam === undefined) resetZustand();

    if (!exam) getExamData(examUUID);
    if (!questions?.lenght) getQuestionsData(examUUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startExam = () => {
    let startTime = new Date();
    setStartTime(startTime);
    setExamState(true);

    const body = {
      account: user?.uuid,
      exam: examUUID,
      start: startTime,
      is_in_progress: true,
    };
    postAttempt(body)?.then((res) => {
      localStorage.setItem(`${examUUID}-attempt`, res?.uuid);
      setStartTime(startTime);
      setExamState(true);

      // console.log(res?.uuid);
    });
  };

  const endExam = () => {
    const bodyAttempt = { is_in_progress: false };
    const attempt = localStorage.getItem(`${examUUID}-attempt`);
    // console.log(attempt);
    const body = JSON.stringify(
      Object.keys(answers)?.map((q) => ({
        question: q,
        answer: answers[q],
      })),
    );
    // console.log(body);
    patchAttempt(attempt, bodyAttempt)
      ?.then(() => {})
      ?.finally(() => {
        postResult(examUUID, body);
      });
    addSnackbar(t('mockup.exam_is_over'), 'warning', 20000);
    setExamState(false);
  };

  const setQuestionByIndex = (index) => {
    let q = questions[index];
    setPickedQuestion(q);
    Object.keys(answers)?.includes(q?.uuid)
      ? setAnswer(answers[q?.uuid])
      : setAnswer();
  };

  const getExamData = (uuid) => {
    setIsLoading(true);
    getExamById(uuid)
      ?.then((res) => {
        setExam(res);
        // console.log(res);
        if (res?.attempt) {
          setExamState(res?.attempt?.is_in_progress);
          setStartTime(res?.attempt?.start);
          localStorage.setItem(`${examUUID}-attempt`, res?.attempt?.uuid);
        }
      })
      .finally(() => setIsLoading(false));
  };
  const getQuestionsData = (exam) => {
    setIsLoading(true);
    getQuestions(exam)
      ?.then((res) => {
        let q = res?.results
          .filter((q) => {
            // Что бы удалить вопросы казахского или русского
            if (user?.application?.language === 'R') {
              return ![1, 2].includes(q?.type);
            }
            return ![3, 4].includes(q?.type);
          })
          ?.sort((a, b) => (a?.type > b?.type ? 1 : -1)); //поставить по порядку
        setQuestions(q);
      })
      ?.finally(() => setIsLoading(false));
  };

  const saveAnswer = (question, answer) => {
    let tmpAnswers = answers;
    if (answer) {
      tmpAnswers[question] = answer;
      setAnswer(answer);
    } else {
      delete tmpAnswers[question];
      setAnswer();
    }
    setAnswers(tmpAnswers);
  };

  return (
    <div className='flex flex-col gap-5'>
      {isLoading ? (
        <SimpleLoader className='h-5' />
      ) : exam ? (
        <>
          <div className='flex flex-col justify-center gap-5 sm:flex-row sm:gap-20'>
            <div className='grid grid-cols-3'>
              <p className='col-span-3 px-10 text-center text-2xl font-semibold'>
                {t('mockup.test')}
              </p>
              {examState ? (
                <ExamEndButton
                  className='mx-10 sm:mx-0'
                  header={t('mockup.confirm_end')}
                  onConfirm={endExam}
                />
              ) : examState?.length === 0 ? (
                <Button
                  className='col-span-3 mx-10 sm:mx-0 '
                  text={t('buttons.begin')}
                  color='success'
                  onClick={() => startExam()}
                />
              ) : (
                ''
              )}
            </div>
            <div className='grid grid-cols-7 '>
              {examState?.length !== 0 ? (
                <div className='col-span-7'>
                  <Timer
                    startTime={startTime}
                    isInProgress={examState}
                    duration={exam?.duration}
                    setIsInProgress={setExamState}
                    end={() => endExam()}
                    //   student={responses}
                  />
                </div>
              ) : (
                <div className='col-span-7 flex flex-col justify-between text-center sm:text-left'>
                  <span>{t('mockup.test_not_started_header')}</span>
                  <hr />
                  <span>{t('mockup.test_not_started_text')}</span>
                </div>
              )}
            </div>
            <div className='grid grid-cols-2 place-items-center sm:place-content-start'>
              {examState ? <ExamTextsButton exam={exam} user={user} /> : ''}
            </div>
          </div>
          <hr />
          <div>
            {examState?.length !== 0 ? (
              <>
                <div className='flex  flex-col items-center gap-3'>
                  <div className='flex w-full flex-col items-center'>
                    <div className='w-10/12  rounded-md bg-error p-4 text-white'>
                      <p className='text-2xl font-semibold'>
                        {t('mockup.attention')}
                      </p>
                      <p>{t('mockup.attention_before_finish')}</p>
                    </div>
                  </div>
                  <div className='flex  flex-col items-center gap-6 sm:flex-row sm:items-start sm:gap-0'>
                    <div className='min-w-fit max-w-fit'>
                      <div className='grid grid-cols-5 gap-[0.125rem]'>
                        {questions?.map((question, i) => (
                          <Button
                            key={question?.uuid}
                            className={
                              'w-12  ' +
                              (pickedQuestion?.uuid === question?.uuid &&
                                ` ring-4 ring-opacity-60 brightness-75 ring-${
                                  Object.keys(answers)?.includes(question?.uuid)
                                    ? 'success'
                                    : 'secondary'
                                } `)
                            }
                            color={
                              Object.keys(answers)?.includes(question?.uuid)
                                ? 'success'
                                : 'secondary-solid'
                              // responses && identifyAnswer(question + 1, responses)
                            }
                            // active={pickedQuestion === question?.uuid}
                            onClick={() => {
                              setPickedQuestion(question);
                              Object.keys(answers)?.includes(question?.uuid)
                                ? setAnswer(answers[question?.uuid])
                                : setAnswer();
                            }}
                            text={i + 1}
                          />
                        ))}
                      </div>
                    </div>
                    <div className='grid w-full grid-cols-9 justify-center px-3 sm:pl-20'>
                      <ExaminationForm
                        question={pickedQuestion}
                        saveAnswer={saveAnswer}
                        answer={answer}
                        isInProgress={examState}
                        setQuestionByIndex={setQuestionByIndex}
                        questionIndex={questions?.findIndex(
                          (question) => question?.uuid === pickedQuestion?.uuid,
                        )}
                        questionLenght={questions?.length}
                        endExam={endExam}
                        exam={exam}
                        examState={examState}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='flex flex-col items-center'>
                <div className='w-10/12  rounded-md bg-yellow-200 p-4'>
                  <p className='text-2xl font-semibold'>
                    {t('mockup.test_not_started_header')}
                  </p>
                  <p> {t('mockup.test_not_started_text')}</p>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div>{t('mockup.not_exist_or_closed')}</div>
      )}
    </div>
  );
};
export default ExaminationStudentForm;
