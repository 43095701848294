import { Tab } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { classNames } from '../../utils/helpers/helpers';
import DistrictCompareTable from './DistrictCompareTable';
import { getRegions, getSchools } from '../../services/catalog/catalog';
import {
  getDistrictRegistrationComparisonStats,
  getSchoolsRegistrationComparisonStats,
} from '../../services/schoolStatistics/schoolStatistics';
import SchoolListCompareTable from './SchoolListCompareTable';
import moment from 'moment';

export default function StatisticsRegistrationComparison() {
  const [regions, setRegions] = useState([]);
  const [schools, setSchools] = useState([]);

  const [statsByDistricts, setStatsByDistrict] = useState([]);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [statsBySchools, setStatsBySchools] = useState([]);
  const [schoolsLoading, setSchoolsLoading] = useState(false);

  useEffect(() => {
    getRegions().then((res) => {
      const options = res.results.map((region) => ({
        label: region.name,
        value: region.uuid,
      }));
      setRegions(options);
    });

    getSchools({ page_size: 50 }).then((res) => {
      const options = res.results
        .filter(
          (sc) =>
            !(
              sc?.school_id === '043' ||
              sc?.school_id === '057' ||
              sc?.school_id === '038'
            ),
        )
        ?.map((school) => ({
          label: school.name,
          value: school.uuid,
          region: school.region,
        }));
      setSchools(options);
    });

    return () => {};
  }, []);

  const compareStats = (dataArr) => {
    let custom = dataArr;
    const totals = dataArr?.reduce(
      (acc, curr) => {
        acc.y_2023 += curr.y_2023;
        acc.y_2024 += curr.y_2024;
        acc.current_year_goal += curr?.current_year_goal;
        return acc;
      },
      { y_2023: 0, y_2024: 0, current_year_goal: 0 },
    );

    let totalsObject = {
      name: 'Total',
      y_2023: totals.y_2023,
      y_2024: totals.y_2024,
      current_year_goal: totals?.current_year_goal,
    };

    custom.unshift(totalsObject);

    custom = custom?.map((d) => {
      let isIncreased;

      switch (true) {
        case d.y_2023 > d.y_2024:
          isIncreased = 0;
          break;
        case d.y_2023 < d.y_2024:
          isIncreased = 1;
          break;
        default:
          isIncreased = 2;
      }

      return { ...d, isIncreased };
    });
    return custom;
  };

  useEffect(() => {
    if (schools.length) {
      setDistrictLoading(true);
      getDistrictRegistrationComparisonStats(
        schools[0].value,
        moment(new Date()).format('DD.MM'),
      )
        .then((res) => {
          const newStats = compareStats(res?.results);
          setStatsByDistrict(newStats);
        })
        .finally(() => {
          setDistrictLoading(false);
        });
    }

    return () => {};
  }, [schools]);

  useEffect(() => {
    setDistrictLoading(true);
    getSchoolsRegistrationComparisonStats(
      null,
      moment(new Date()).format('DD.MM'),
    )
      .then((res) => {
        const newStats = compareStats(res?.results);
        setStatsBySchools(newStats);
      })
      .finally(() => {
        setDistrictLoading(false);
      });

    return () => {};
  }, []);

  const getSchoolsStats = async (regionUuid, upToDay) => {
    setSchoolsLoading(true);
    await getSchoolsRegistrationComparisonStats(regionUuid, upToDay)
      .then((res) => {
        const newStats = compareStats(res?.results);
        setStatsBySchools(newStats);
      })
      .finally(() => {
        setSchoolsLoading(false);
      });
  };

  const getDistrictStats = async (schoolUuid, upToDay) => {
    setDistrictLoading(true);
    await getDistrictRegistrationComparisonStats(schoolUuid, upToDay)
      .then((res) => {
        const newStats = compareStats(res?.results);
        setStatsByDistrict(newStats);
      })
      .finally(() => {
        setDistrictLoading(false);
      });
  };

  return (
    <div>
      <Tab.Group manual>
        <Tab.List className='flex flex-row gap-2'>
          <Tab
            className={({ selected }) =>
              classNames(
                'rounded-md px-4 py-2',
                'bg-blue-100',
                selected
                  ? 'bg-blue-600 text-white'
                  : 'text-blue-600 hover:bg-blue-600 hover:text-white',
              )
            }
          >
            По школам
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                'rounded-md px-4 py-2',
                'bg-blue-100',
                selected
                  ? 'bg-blue-600 text-white'
                  : 'text-blue-600 hover:bg-blue-600 hover:text-white',
              )
            }
          >
            Регионы по школам
          </Tab>
        </Tab.List>
        <Tab.Panels className='mt-4 rounded-md'>
          <Tab.Panel>
            <SchoolListCompareTable
              data={statsBySchools}
              loading={schoolsLoading}
              regions={regions}
              getData={getSchoolsStats}
            />
          </Tab.Panel>
          <Tab.Panel>
            <DistrictCompareTable
              data={statsByDistricts}
              loading={districtLoading}
              schools={schools}
              regions={regions}
              getData={getDistrictStats}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
