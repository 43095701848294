import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../../components/forms/Select/Select';
import DatePicker from '../../../components/forms/DatePicker/DatePicker';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import { patchApplication } from '../../../services/application/application';
import { format } from 'date-fns';
import useSnackbar from '../../../utils/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';

export default function UpdateStudentPass({
  student,
  modalOpen,
  setModalOpen,
  refreshData,
}) {
  const [t] = useTranslation();
  const { addSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (student?.uuid) {
      setValue('parentPhoneNumber', student?.parent_phone_number);
      setValue('birthDate', new Date(student?.birth_date));
      setValue('language', student?.language);

      //   setValue('examCenter', student?.exam_center?.uuid);
      //   setValue('district', student?.district?.uuid);
      setValue('room', student?.t2_classroom);
      setValue('place', student?.t2_seat);
    }

    return () => {
      reset();
    };
  }, [student, setValue, reset]);

  const onSubmit = (data) => {
    setLoading(true);
    const body = {
      parent_phone_number: data.parentPhoneNumber,
      birth_date: format(data.birthDate, 'yyyy-MM-dd'),
      language: data.language,

      exam_center: data.examCenter,
      district: data.district,
      t2_classroom: data.room,
      t2_seat: data.place,
    };

    patchApplication(student?.uuid, body)
      .then(() => {
        refreshData();
        addSnackbar(t('Успешно'), 'success');
      })
      .catch(() => {
        addSnackbar(t('Ошибка'), 'error');
      })
      .finally(() => {
        setLoading(false);
        setModalOpen(false);
      });
  };

  return (
    <CustomDialog
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      closeModal={() => {
        setModalOpen(false);
      }}
      headerText={'Обновить данные'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='bg-white px-4 py-5 sm:p-6'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6 md:col-span-3'>
              <label
                htmlFor='whatsapp'
                className='block text-sm font-medium text-gray-700'
              >
                {t('signup.parent_phone')}
                <span className='text-error'>*</span>
              </label>
              <div className='mt-1 flex rounded-md shadow-sm'>
                <span className='inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500'>
                  +7
                </span>
                <input
                  type='text'
                  name='parentPhoneNumber'
                  id='parentPhoneNumber'
                  className='block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm'
                  // placeholder='www.example.com'
                  // errorMsg={
                  //   errors?.whatsapp?.type ? t('signup.parent_phone_error') : ''
                  // }
                  {...register('parentPhoneNumber', {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    pattern: {
                      value: /^7[0-9]{9}?$/,
                    },
                  })}
                />
              </div>
              {errors?.whatsapp?.type && (
                <span className='mt-1 text-xs font-semibold text-error'>
                  {t('signup.parent_phone_error')}
                </span>
              )}
            </div>

            <div className='col-span-6 md:col-span-3'>
              <Controller
                control={control}
                name='birthDate'
                render={({ field }) => (
                  <DatePicker
                    className='w-full'
                    label={t('signup.birth_date')}
                    required
                    errorMsg={errors?.birthDate?.message}
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    placeholderText={t('buttons.select')}
                    dateFormat='dd / MM / yyyy'
                    maxDate={new Date()}
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name='language'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label={t('signup.study_lang')}
                  required
                  errorMsg={
                    errors?.language?.type ? t('signup.select_study_lang') : ''
                  }
                  className='col-span-6'
                  value={field.value}
                  options={[
                    {
                      label: 'Қазақша',
                      value: 'K',
                    },
                    {
                      label: 'Русский',
                      value: 'R',
                    },
                  ]}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />

            {/* <Controller
              control={control}
              name='examCenter'
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  label={'Экзаменационный центр'}
                  required
                  errorMsg={errors?.district?.type ? 'Ошибка' : ''}
                  className='col-span-6'
                  value={field.value}
                  options={examCenters}
                  // placeholder={t('announcement.select_positions')}
                  onChange={(e) => {
                    // setSelectedDistrict(e);
                    field.onChange(e.value);
                  }}
                />
              )}
            /> */}

            <Input
              type='text'
              className='col-span-6 sm:col-span-3'
              label='Аудитория (2 тур)'
              required
              errorMsg={
                errors?.parentLastName?.type
                  ? t('signup.enter_parent_surname')
                  : ''
              }
              {...register('room', {
                required: true,
              })}
            />
            <Input
              type='text'
              className='col-span-6 sm:col-span-3'
              label='Место (2 тур)'
              required
              errorMsg={
                errors?.parentLastName?.type
                  ? t('signup.enter_parent_surname')
                  : ''
              }
              {...register('place', {
                required: true,
              })}
            />
          </div>
        </div>
        <div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
          <Button loading={loading} type='submit' text='Обновить' />
        </div>
      </form>
    </CustomDialog>
  );
}
